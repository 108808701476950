@import 'src/assets/scss/functions';

.alg-tooltip {
  &.no-text-wrap {
    max-width: none;

    .p-tooltip-text {
      text-wrap: nowrap;
    }
  }

  &.observation-mode {
    min-width: toRem(280);
  }

  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: var(--alg-primary-color);
  }

  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: var(--alg-primary-color);
  }

  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: var(--alg-primary-color);
  }

  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: var(--alg-primary-color);
  }

  .p-tooltip-text {
    padding: var(--alg-space-size-5);
    border-radius: toRem(10);
    color: #fff;
    font-size: toRem(12);
    font-weight: 500;
    line-height: toRem(16);
    letter-spacing: toRem(0.4);
    background-color: var(--alg-primary-color);
  }
}
