.block-ui {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  transition-property: background-color;
  z-index: 5000;
}
