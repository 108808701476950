@import 'src/assets/scss/functions';

@mixin input-base {
  width: 100%;
  height: toRem(40);
  display: flex;
  padding: 0 toRem(16);
  background-color: var(--alg-light-color);
  border: none;
  border-radius: var(--border-radius);
  font-size: toRem(16);
  color: var(--alg-secondary-color);

  &:focus {
    outline: toRem(2) solid var(--alg-primary-color);
    box-shadow: none;
  }

  &::placeholder {
    color: var(--alg-secondary-color);
    font-size: toRem(16);
    font-style: italic;
    opacity: .5;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
