@import '../../../variables';
@import 'src/geometry.scss';

.tab-rounded {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 9rem;
  height: 3rem;
  box-shadow: 0 .1rem var(--dark-transparency-color) inset;
  background-color: var(--dark-transparency-color);

  .tab-rounded-item {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    justify-content: center;
    flex-direction: row;
    color: var(--base-text-color);
    cursor: pointer;
    transition: .2s;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    text-decoration: none;

    &.active {
      color: white;
      box-shadow: none;
      border-radius: 9rem;
      background-color: var(--base-color);
    }
  }
}
