@import 'src/assets/scss/functions';

.slanted-grid-container {
  position: relative;
}

.slanted-grid-sticky-header-container {
  position: absolute;
}

.slanted-grid-sticky-header-bg {
  position: sticky;
  top: 0;
  left: 0;
  margin-right: calc(0rem - var(--alg-space-size-2));
  margin-bottom: toRem(-200);
  width: 100%;
  height: toRem(200);
  background-color: #fff;
  z-index: 3;
}

.slanted-grid {
  tr {
    border: none;

    > th {
      position: sticky;
      top: 0;
      border:none;
      height: 12.5rem;
      transform: skewX(-45deg);
      transform-origin: 0 100%;
      background-color: var(--alg-white-color);
      min-width: 3.3333rem;
      z-index: 4;

      &:nth-child(2) {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }

      .slanted-header {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(50%, 50%) skewX(45deg) rotate(-45deg);
        transform-origin: 0 50%;
        text-align: left;
        padding-left: var(--alg-space-size-5);
        overflow: visible;
        z-index: 2;
      }

      .slanted-header-content {
        padding-right: 10px;
        width: toRem(240);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-child(odd) {
        background-color: var(--alg-white-color);
      }

      &:not(:last-child) {
        border-bottom: .1rem solid var(--alg-border-color);
      }

      &.empty {
        min-width: 160px;
        transform: none;

        &.first {
          width: 159px;
        }
      }
    }

    > td {
      background-color: white;
      border: none;
      padding: 0;

      &.users {
        height: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 13.3333rem;
      }

      &:not(:first-child) {
        border-left: .1rem solid var(--alg-border-color);
      }

      &:last-child {
        border-right: .1rem solid var(--alg-border-color);
      }
    }
  }

  .p-datatable-wrapper {
    overflow: visible !important;
  }
}
