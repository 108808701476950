@import 'src/assets/scss/functions';

.alg-button {
  border: none;
  text-decoration: none;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
  }

  &.basic {
    &.p-button {
      &:not([icon]) {
        padding: 0 toRem(15);
        border-radius: toRem(20);
      }

      &[icon] {
        padding: 0 toRem(7) 0 toRem(15);
        border-radius: toRem(12);
      }

      flex-direction: row-reverse;
      color: var(--alg-secondary-color);
      font-size: toRem(12);
      border: toRem(1) solid var(--alg-light-color);
      background: none;
      height: toRem(24);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-primary-color);
      font-size: toRem(16);
    }

    &:hover {
      background: var(--alg-light-color);
    }
  }

  &.primary {
    &.p-button {
      padding: 0 toRem(18);
      flex-direction: row-reverse;
      color: var(--alg-white-color);
      font-size: toRem(16);
      text-decoration: none;
      border-radius: toRem(20);
      background: var(--alg-primary-color);
      height: toRem(40);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-white-color);
      font-size: toRem(16);
    }
  }

  &.success {
    &.p-button {
      padding: 0 toRem(18);
      flex-direction: row-reverse;
      color: var(--alg-white-color);
      font-size: toRem(16);
      text-decoration: none;
      border-radius: toRem(20);
      background: var(--alg-success-btn-color);
      height: toRem(40);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-white-color);
      font-size: toRem(16);
    }
  }

  &.secondary {
    &.p-button {
      padding: 0 toRem(18);
      flex-direction: row-reverse;
      color: var(--alg-secondary-color);
      font-size: toRem(16);
      text-decoration: none;
      border-radius: toRem(20);
      border: toRem(1) solid var(--alg-border-color);
      background: var(--alg-white-color);
      height: toRem(40);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-secondary-color);
      font-size: toRem(16);
    }
  }

  &.danger {
    &.p-button {
      padding: 0 toRem(18);
      flex-direction: row-reverse;
      color: var(--alg-white-color);
      font-size: toRem(16);
      text-decoration: none;
      border-radius: toRem(20);
      background: var(--danger-color);
      height: toRem(40);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-white-color);
      font-size: toRem(16);
    }
  }

  &.warning {
    &.p-button {
      padding: 0 toRem(18);
      flex-direction: row-reverse;
      color: var(--alg-accent-dark-color);
      font-size: toRem(16);
      text-decoration: none;
      border-radius: toRem(20);
      border: toRem(1) solid var(--alg-accent-dark-color);
      background: transparent;
      height: toRem(40);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-accent-dark-color);
      font-size: toRem(16);
    }

    &:hover {
      &.p-button {
        color: var(--alg-accent-dark-hover-color);
        border-color: var(--alg-accent-dark-hover-color);
      }
      .p-button-icon {
        color: var(--alg-accent-dark-hover-color);
      }
    }
  }

  &.small {
    &.p-button {
      height: toRem(32);
      font-size: toRem(12);
      font-weight: 500;
      border-radius: toRem(32) !important;
    }
  }

  &.mini {
    &.p-button {
      padding: toRem(8);
      height: toRem(20);
      font-size: toRem(12);
      font-weight: 500;
    }
  }

  &.grant-access-button {
    &.p-button {
      padding: 0 toRem(8);
      height: toRem(24);
      font-size: toRem(12);
      font-weight: 500;
      border-radius: toRem(24) !important;
    }
  }

  &.light-border {
    border: toRem(1) solid var(--alg-white-color);

    &:hover {
      border: toRem(1) solid var(--alg-white-color) !important;
    }
  }

  &.left-menu {
    &.p-button {
      font-size: toRem(16);
      flex-direction: row;
      border-color: rgba(0, 0, 0, 0.05);
      background: none !important;
      height: toRem(40);
      border-radius: toRem(20) !important;
      justify-content: center;
    }

    .p-button-icon {
      padding: 0;
      margin-right: toRem(7.5);
      font-size: toRem(24);
      color: var(--alg-primary-color);
    }

    &[icon] {
      padding: 0 toRem(7) !important;
    }

    &.left-menu-selected {
      &.p-button {
        border-color: var(--primary-color);
      }
    }
  }

  &.only-caption {
    &.p-button {
      padding: 0;
      color: var(--alg-secondary-color);
      background: none;
    }

    &.edit-permissions {
      font-size: toRem(12);
      color: var(--alg-accent-dark-color);
    }
  }
}
