@import 'src/assets/scss/functions';

.alg-h1 {
  font-size: 3rem;
  font-weight: 500;
}

.alg-h2 {
  font-size: 2.5rem;
}

.alg-h3 {
  font-size: 2rem;
}

.alg-h4 {
  font-size: 1.5rem;
}

.alg-h5 {
  font-size: 1.25rem;
}

.alg-text-base {
  color: var(--alg-secondary-color);
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 400;
}

.alg-text-secondary {
  color: var(--alg-secondary-color);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.alg-text-bold {
  font-weight: 700;
}

.alg-text-normal {
  font-weight: 400;
}

.alg-text-small {
  font-size: .75rem;
  font-weight: 400;
}

.alg-font-size-38 {
  font-size: toRem(38) !important;
}
