@import 'src/assets/scss/functions';

.alg-path-suggestion-overlay, .alg-menu-overlay {
  min-width: 30rem;
  max-width: 50rem;
  background-color: var(--alg-primary-color);
  border-radius: toRem(10);
  border: none;

  &:not(.p-overlaypanel-flipped) {
    &:before {
      border-bottom-color: var(--alg-primary-color);
    }

    &:after {
      border-bottom-color: var(--alg-primary-color);
    }
  }

  &.p-overlaypanel-flipped {
    &:before {
      border-top-color: var(--alg-primary-color);
    }

    &:after {
      border-top-color: var(--alg-primary-color);
    }
  }

  .p-overlaypanel-content {
    padding: 0 0 toRem(3) 0;
  }

  @media screen and (max-width: 1100px) {
    max-width: 40rem;
  }
}
