.alg-primary-color {
  color: var(--alg-primary-color);
}

.alg-primary-dark-color {
  color: var(--alg-primary-dark-color);
}

.alg-secondary-color {
  color: var(--alg-secondary-color) !important;
}

.alg-black-color {
  color: var(--alg-black-color);
}

.alg-white-color {
  color: var(--alg-white-color);
}

.alg-light-color {
  color: var(--alg-light-color);
}

