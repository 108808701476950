@import '../../../variables';

.alg-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  color: var(--base-text-color);
  > * + * {
    margin-left: 0.25rem;
  }
}
