@import "src/assets/scss/functions";

.alg-tree-nav {
  background: none;
  padding: 0 var(--alg-space-size-5);
  border: 0;

  .p-tree-toggler {
    display: none;
  }

  .p-treenode-children {
    padding: 0;

    .p-element {
      &:first-child {
        .tree-nav-dot {
          &:before {
            display: none;
          }
        }
      }

      &:last-child {
        .tree-nav-dot {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .p-treenode {
    padding: 0 !important;

    &:focus {
      .p-treenode-content {
        box-shadow: none !important;
      }
    }
  }

  .p-treenode-label {
    flex: 1;
  }

  .p-tree-empty-message {
    margin-top: var(--alg-space-size-5);
    text-align: center;
    color: var(--alg-secondary-color);
  }
}
