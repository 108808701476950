.alg-login-button {
  background-color: transparent;
  border: none;

  &:hover {
    background-color: rgba(white, 0.1);
  }

  @media screen and (max-width: 599.98px) {
    .p-button-icon-right {
      margin-left: 0;
    }

    .p-button-label {
      display: none;
    }
  }
}
