@import 'src/assets/scss/functions';

.alg-permissions-edit-dialog {
  &.p-dialog {
    max-height: 65vh;
    width: toRem(760);
    overflow: hidden;
  }

  .p-dialog-header {
    padding: var(--alg-space-size-6) var(--alg-space-size-5);
  }

  .p-dialog-content {
    overflow: scroll;
  }

  &.no-scroll {
    .p-dialog-content {
      overflow: hidden !important;
    }
  }

  .p-dialog-footer {
    display: flex;
    justify-content: center;
  }

  .p-dialog-title {
    width: 100%;
  }
}
