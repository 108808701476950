@import 'src/assets/scss/functions';

.alg-common-confirm-dialog {
  .p-confirm-dialog-message, .p-confirm-dialog-icon {
    color: var(--alg-secondary-color);
  }

  .p-confirm-dialog-accept, .p-confirm-dialog-reject {
    height: toRem(32);
    color: var(--alg-secondary-color);
    background: none;
    border-radius: toRem(32);
    border: none;
    font-size: toRem(14);

    &.p-confirm-dialog-reject {
      border: toRem(1) solid var(--alg-light-color);
    }

    &:focus, &:active {
      outline: none;
      box-shadow: none;
    }

    &.danger {
      background: var(--danger-color);
    }

    i {
      margin-right: toRem(4);
    }
  }

  .p-confirm-dialog-accept {
    background: var(--alg-primary-color);
    color: var(--alg-white-color);
  }
}
