@import "src/assets/scss/functions";

.alg-editable-table {
  .p-datatable-tbody > tr > td.p-editing-cell p-celleditor > * {
    width: 2.5rem;
  }

  .p-datatable-tbody > tr {
    display: flex;
    border-radius: toRem(12);
    margin-bottom: toRem(4);
    height: 3rem;
    transition: none;
    box-shadow: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0.04);

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .p-datatable-tbody > tr:last-child {
    border: none;
  }

  .p-datatable-tbody > tr > td {
    color: var(--alg-secondary-color);
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: toRem(16);

    &.p-datatable-reorderablerow-handle {
      padding-right: 0;
    }
  }

  .p-datatable-tbody > tr.p-highlight {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .p-datatable .p-datatable-footer {
    padding: 0 toRem(16);
    border: none;
  }
}
