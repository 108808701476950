@import "src/assets/scss/functions";

.alg-select-dropdown {
  &.lang {
    &.p-dropdown {
      width: auto;
      height: toRem(24);
      background-color: transparent;
    }

    .p-dropdown-label {
      margin-left: toRem(9) !important;
      font-size: toRem(12) !important;
    }

    .p-dropdown-trigger {
      padding: 0 toRem(5);
    }
  }

  &.dark-gray-border {
    border: toRem(1) solid #0000001a !important;
  }

  &.p-focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .p-dropdown-trigger {
    padding: 0 toRem(16);
    width: auto;
    color: var(--alg-primary-color);
  }

  &.p-dropdown {
    width: 100%;
    border: toRem(1) solid var(--alg-light-color);
    border-radius: toRem(40);
    height: toRem(40);
    background-color: var(--alg-light-color);

    &:hover {
      border-color: var(--alg-light-color);
    }

    &:active, &:focus {
      outline: none;
      box-shadow: none;
    }

    &:not(.p-disabled) {
      border: toRem(1) solid var(--alg-light-color);
    }

    .p-dropdown-label {
      margin-left: toRem(16);
      padding: 0;
      display: flex;
      align-items: center;
      color: var(--alg-secondary-color);
      font-size: toRem(16);
      text-transform: capitalize;
    }

  }
}

.alg-select-dropdown-panel {
  p-dropdownitem {
    display: block;

    &:not(last-child) {
      border-bottom: toRem(1) solid rgba(0, 0, 0, .1);
    }
  }

  &.p-dropdown-panel {
    background-color: var(--alg-primary-color);
    border-radius: toRem(10);
    border: none;
    overflow: hidden;
    box-shadow: 0 toRem(6) toRem(10) 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 toRem(6) toRem(10) 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 toRem(6) toRem(10) 0 rgba(0, 0, 0, 0.2);
  }

  .p-dropdown-items .p-dropdown-item {
    padding: .7rem;
    margin: toRem(6);
    flex-direction: row-reverse;
    border-radius: toRem(10);
    color: var(--alg-white-color);
    font-size: toRem(12);
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;

    &:active, &:focus {
      outline: none;
      box-shadow: none;
    }

    &.p-highlight {
      color: white;
      background-color: var(--alg-primary-dark-color);
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      color: white;
      background-color: var(--alg-primary-dark-hover-color);
    }

    &:not(.p-highlight):not(.p-disabled) {
      &.p-focus {
        background-color: var(--alg-primary-color);

        &:hover {
          background-color: var(--alg-primary-dark-hover-color);
        }
      }
    }
  }
}
