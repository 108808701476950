@import 'src/assets/scss/functions';

.alg-button-icon {
  border: none;
  cursor: pointer;

  .p-button-icon {
    font-size: toRem(18);
  }

  &.base-color {
    i {
      color: var(--base-color);
    }
  }

  &:active, &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  &.rounded {
    width: toRem(24);
    height: toRem(24);
    border-radius: 50%;

    .p-button-icon {
      font-size: toRem(16);
    }
  }

  &.primary-color {
    .p-button-icon {
      color: var(--alg-white-color);
    }

    background-color: var(--alg-primary-color);

    &.no-bg {
      .p-button-icon {
        color: var(--alg-primary-color);
      }
    }
  }

  &.secondary-color {
    .p-button-icon {
      color: var(--alg-secondary-color);
    }
  }

  &.secondary-bg-color {
    .p-button-icon {
      color: var(--alg-white-color);
    }

    &.p-button:enabled:hover {
      background-color: var(--alg-secondary-color);
    }

    background-color: var(--alg-secondary-color);
  }

  &.no-bg {
    width: auto;
    margin: 0;
    padding: 0;
    background: none;

    &:hover {
      background: none;
    }
  }

  &.user-menu {
    .p-button-icon {
      font-size: 2rem;
    }
  }

  &.left-menu {
    .p-button-icon {
      font-size: 1.5rem;
    }
  }

  &.login {
    .p-button-icon {
      font-size: toRem(24);
    }
  }

  &.back-button {
    .p-button-icon {
      color: var(--alg-primary-color);
    }

    background-color: #dbe1f3;

    &:hover {
      background-color: #dbe1f3;
    }
  }

  &.small {
    .p-button-icon {
      font-size: .8rem;
    }
  }

  &.send-button {
    width: toRem(40);
    height: toRem(40);

    .p-button-icon {
      font-size: toRem(18);
    }
  }

  &.tab-arrow {
    width: toRem(30);
    height: 100%;
    border-radius: 0;
    background-color: var(--alg-white-color);

    .p-button-icon {
      color: var(--alg-primary-color);
    }

    &.p-button:enabled:hover {
      background-color: var(--alg-white-color);
    }

    &.tab-prev {
      box-shadow: toRem(1) toRem(1) toRem(5) toRem(1) rgba(0,0,0,0.3);
    }

    &.tab-next {
      box-shadow: toRem(-1) toRem(1) toRem(5) toRem(1) rgba(0,0,0,0.3);
    }
  }

  &.stroke {
    border: toRem(1) solid rgba(0, 0, 0, 0.1);
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &.primary-color {
      .p-button-icon {
        color: var(--alg-primary-color);
      }

      &:hover {
        border: toRem(1) solid var(--alg-primary-color);
      }
    }

    &.regular-size {
      width: toRem(40);
      height: toRem(40);

      .p-button-icon {
        font-size: toRem(18);
      }
    }
  }

  &.nav-arrow {
    padding: 0 toRem(12);
    height: 100%;

    @media screen and (max-width: 599.98px) {
      padding: 0 toRem(2);
    }
  }
}
