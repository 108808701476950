@import 'src/assets/scss/functions';

.alg-data-list {
  list-style: none;
  padding-left: 0;
  color: var(--dark-text-color);
}

.alg-data-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: .1rem solid var(--alg-light-color);
  height: toRem(48);
}

.alg-data-list-item-caption {
  color: var(--alg-secondary-color);
}

.alg-data-list-item-value {
  color: var(--alg-primary-color);
}
