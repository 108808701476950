@font-face {
  font-family: "Phosphor-Duotone";
  src:
    url("./Phosphor-Duotone.woff2") format("woff2"),
    url("./Phosphor-Duotone.woff") format("woff"),
    url("./Phosphor-Duotone.ttf") format("truetype"),
    url("./Phosphor-Duotone.svg#Phosphor-Duotone") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ph-duotone {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Phosphor-Duotone" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ph-duotone.ph-acorn:before {
  content: "\eb9a";
  opacity: 0.2;
}
.ph-duotone.ph-acorn:after {
  content: "\eb9b";
  margin-left: -1em;
}
.ph-duotone.ph-address-book:before {
  content: "\e6f8";
  opacity: 0.2;
}
.ph-duotone.ph-address-book:after {
  content: "\e6f9";
  margin-left: -1em;
}
.ph-duotone.ph-address-book-tabs:before {
  content: "\ee4e";
  opacity: 0.2;
}
.ph-duotone.ph-address-book-tabs:after {
  content: "\ee4f";
  margin-left: -1em;
}
.ph-duotone.ph-air-traffic-control:before {
  content: "\ecd8";
  opacity: 0.2;
}
.ph-duotone.ph-air-traffic-control:after {
  content: "\ecd9";
  margin-left: -1em;
}
.ph-duotone.ph-airplane:before {
  content: "\e002";
  opacity: 0.2;
}
.ph-duotone.ph-airplane:after {
  content: "\e003";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-in-flight:before {
  content: "\e4fe";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-in-flight:after {
  content: "\e4ff";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-landing:before {
  content: "\e502";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-landing:after {
  content: "\e503";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-takeoff:before {
  content: "\e504";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-takeoff:after {
  content: "\e505";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-taxiing:before {
  content: "\e500";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-taxiing:after {
  content: "\e501";
  margin-left: -1em;
}
.ph-duotone.ph-airplane-tilt:before {
  content: "\e5d6";
  opacity: 0.2;
}
.ph-duotone.ph-airplane-tilt:after {
  content: "\e5d7";
  margin-left: -1em;
}
.ph-duotone.ph-airplay:before {
  content: "\e004";
  opacity: 0.2;
}
.ph-duotone.ph-airplay:after {
  content: "\e005";
  margin-left: -1em;
}
.ph-duotone.ph-alarm:before {
  content: "\e006";
  opacity: 0.2;
}
.ph-duotone.ph-alarm:after {
  content: "\e007";
  margin-left: -1em;
}
.ph-duotone.ph-alien:before {
  content: "\e8a6";
  opacity: 0.2;
}
.ph-duotone.ph-alien:after {
  content: "\e8a7";
  margin-left: -1em;
}
.ph-duotone.ph-align-bottom:before {
  content: "\e506";
  opacity: 0.2;
}
.ph-duotone.ph-align-bottom:after {
  content: "\e507";
  margin-left: -1em;
}
.ph-duotone.ph-align-bottom-simple:before {
  content: "\eb0c";
  opacity: 0.2;
}
.ph-duotone.ph-align-bottom-simple:after {
  content: "\eb0d";
  margin-left: -1em;
}
.ph-duotone.ph-align-center-horizontal:before {
  content: "\e50a";
  opacity: 0.2;
}
.ph-duotone.ph-align-center-horizontal:after {
  content: "\e50b";
  margin-left: -1em;
}
.ph-duotone.ph-align-center-horizontal-simple:before {
  content: "\eb0e";
  opacity: 0.2;
}
.ph-duotone.ph-align-center-horizontal-simple:after {
  content: "\eb0f";
  margin-left: -1em;
}
.ph-duotone.ph-align-center-vertical:before {
  content: "\e50c";
  opacity: 0.2;
}
.ph-duotone.ph-align-center-vertical:after {
  content: "\e50d";
  margin-left: -1em;
}
.ph-duotone.ph-align-center-vertical-simple:before {
  content: "\eb10";
  opacity: 0.2;
}
.ph-duotone.ph-align-center-vertical-simple:after {
  content: "\eb11";
  margin-left: -1em;
}
.ph-duotone.ph-align-left:before {
  content: "\e50e";
  opacity: 0.2;
}
.ph-duotone.ph-align-left:after {
  content: "\e50f";
  margin-left: -1em;
}
.ph-duotone.ph-align-left-simple:before {
  content: "\eaee";
  opacity: 0.2;
}
.ph-duotone.ph-align-left-simple:after {
  content: "\eaef";
  margin-left: -1em;
}
.ph-duotone.ph-align-right:before {
  content: "\e510";
  opacity: 0.2;
}
.ph-duotone.ph-align-right:after {
  content: "\e511";
  margin-left: -1em;
}
.ph-duotone.ph-align-right-simple:before {
  content: "\eb12";
  opacity: 0.2;
}
.ph-duotone.ph-align-right-simple:after {
  content: "\eb13";
  margin-left: -1em;
}
.ph-duotone.ph-align-top:before {
  content: "\e512";
  opacity: 0.2;
}
.ph-duotone.ph-align-top:after {
  content: "\e513";
  margin-left: -1em;
}
.ph-duotone.ph-align-top-simple:before {
  content: "\eb14";
  opacity: 0.2;
}
.ph-duotone.ph-align-top-simple:after {
  content: "\eb15";
  margin-left: -1em;
}
.ph-duotone.ph-amazon-logo:before {
  content: "\e96c";
  opacity: 0.2;
}
.ph-duotone.ph-amazon-logo:after {
  content: "\e96d";
  margin-left: -1em;
}
.ph-duotone.ph-ambulance:before {
  content: "\e572";
  opacity: 0.2;
}
.ph-duotone.ph-ambulance:after {
  content: "\e573";
  margin-left: -1em;
}
.ph-duotone.ph-anchor:before {
  content: "\e514";
  opacity: 0.2;
}
.ph-duotone.ph-anchor:after {
  content: "\e515";
  margin-left: -1em;
}
.ph-duotone.ph-anchor-simple:before {
  content: "\e5d8";
  opacity: 0.2;
}
.ph-duotone.ph-anchor-simple:after {
  content: "\e5d9";
  margin-left: -1em;
}
.ph-duotone.ph-android-logo:before {
  content: "\e008";
  opacity: 0.2;
}
.ph-duotone.ph-android-logo:after {
  content: "\e009";
  margin-left: -1em;
}
.ph-duotone.ph-angle:before {
  content: "\e7bc";
  opacity: 0.2;
}
.ph-duotone.ph-angle:after {
  content: "\e7bd";
  margin-left: -1em;
}
.ph-duotone.ph-angular-logo:before {
  content: "\eb80";
  opacity: 0.2;
}
.ph-duotone.ph-angular-logo:after {
  content: "\eb81";
  margin-left: -1em;
}
.ph-duotone.ph-aperture:before {
  content: "\e00a";
  opacity: 0.2;
}
.ph-duotone.ph-aperture:after {
  content: "\e00b";
  margin-left: -1em;
}
.ph-duotone.ph-app-store-logo:before {
  content: "\e974";
  opacity: 0.2;
}
.ph-duotone.ph-app-store-logo:after {
  content: "\e975";
  margin-left: -1em;
}
.ph-duotone.ph-app-window:before {
  content: "\e5da";
  opacity: 0.2;
}
.ph-duotone.ph-app-window:after {
  content: "\e5db";
  margin-left: -1em;
}
.ph-duotone.ph-apple-logo:before {
  content: "\e516";
  opacity: 0.2;
}
.ph-duotone.ph-apple-logo:after {
  content: "\e517";
  margin-left: -1em;
}
.ph-duotone.ph-apple-podcasts-logo:before {
  content: "\eb96";
  opacity: 0.2;
}
.ph-duotone.ph-apple-podcasts-logo:after {
  content: "\eb97";
  margin-left: -1em;
}
.ph-duotone.ph-approximate-equals:before {
  content: "\edaa";
  opacity: 0.2;
}
.ph-duotone.ph-approximate-equals:after {
  content: "\edab";
  margin-left: -1em;
}
.ph-duotone.ph-archive:before {
  content: "\e00c";
  opacity: 0.2;
}
.ph-duotone.ph-archive:after {
  content: "\e00d";
  margin-left: -1em;
}
.ph-duotone.ph-armchair:before {
  content: "\e012";
  opacity: 0.2;
}
.ph-duotone.ph-armchair:after {
  content: "\e013";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-arc-left:before {
  content: "\e014";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-arc-left:after {
  content: "\e015";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-arc-right:before {
  content: "\e016";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-arc-right:after {
  content: "\e017";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-double-up-left:before {
  content: "\e03a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-double-up-left:after {
  content: "\e03b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-double-up-right:before {
  content: "\e03c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-double-up-right:after {
  content: "\e03d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-down-left:before {
  content: "\e018";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-down-left:after {
  content: "\e019";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-down-right:before {
  content: "\e01a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-down-right:after {
  content: "\e01b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-left-down:before {
  content: "\e01c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-left-down:after {
  content: "\e01d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-left-up:before {
  content: "\e01e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-left-up:after {
  content: "\e01f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-right-down:before {
  content: "\e020";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-right-down:after {
  content: "\e021";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-right-up:before {
  content: "\e022";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-right-up:after {
  content: "\e023";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-up-left:before {
  content: "\e024";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-up-left:after {
  content: "\e025";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-bend-up-right:before {
  content: "\e026";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-bend-up-right:after {
  content: "\e027";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-down:before {
  content: "\e028";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-down:after {
  content: "\e029";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-down-left:before {
  content: "\e02a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-down-left:after {
  content: "\e02b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-down-right:before {
  content: "\e02c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-down-right:after {
  content: "\e02d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-left:before {
  content: "\e05a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-left:after {
  content: "\e05b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-right:before {
  content: "\e02e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-right:after {
  content: "\e02f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-up:before {
  content: "\e030";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-up:after {
  content: "\e031";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-up-left:before {
  content: "\e032";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-up-left:after {
  content: "\e033";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-circle-up-right:before {
  content: "\e034";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-circle-up-right:after {
  content: "\e035";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-clockwise:before {
  content: "\e036";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-clockwise:after {
  content: "\e037";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-counter-clockwise:before {
  content: "\e038";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-counter-clockwise:after {
  content: "\e039";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-down:before {
  content: "\e03e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-down:after {
  content: "\e03f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-down-left:before {
  content: "\e040";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-down-left:after {
  content: "\e041";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-down-right:before {
  content: "\e042";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-down-right:after {
  content: "\e043";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-down-left:before {
  content: "\e044";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-down-left:after {
  content: "\e045";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-down-right:before {
  content: "\e046";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-down-right:after {
  content: "\e047";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-left:before {
  content: "\e048";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-left:after {
  content: "\e049";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-left-down:before {
  content: "\e04a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-left-down:after {
  content: "\e04b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-left-up:before {
  content: "\e04c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-left-up:after {
  content: "\e04d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-right:before {
  content: "\e04e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-right:after {
  content: "\e04f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-right-down:before {
  content: "\e050";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-right-down:after {
  content: "\e051";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-right-up:before {
  content: "\e052";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-right-up:after {
  content: "\e053";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-up-left:before {
  content: "\e054";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-up-left:after {
  content: "\e055";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-elbow-up-right:before {
  content: "\e056";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-elbow-up-right:after {
  content: "\e057";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-down:before {
  content: "\e518";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-down:after {
  content: "\e519";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-left:before {
  content: "\e51a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-left:after {
  content: "\e51b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-line-down:before {
  content: "\e51c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-line-down:after {
  content: "\e51d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-line-left:before {
  content: "\e51e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-line-left:after {
  content: "\e51f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-line-right:before {
  content: "\e520";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-line-right:after {
  content: "\e521";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-line-up:before {
  content: "\e522";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-line-up:after {
  content: "\e523";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-lines-down:before {
  content: "\e524";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-lines-down:after {
  content: "\e525";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-lines-left:before {
  content: "\e526";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-lines-left:after {
  content: "\e527";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-lines-right:before {
  content: "\e528";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-lines-right:after {
  content: "\e529";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-lines-up:before {
  content: "\e52a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-lines-up:after {
  content: "\e52b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-right:before {
  content: "\e52c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-right:after {
  content: "\e52d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-fat-up:before {
  content: "\e52e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-fat-up:after {
  content: "\e52f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-left:before {
  content: "\e058";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-left:after {
  content: "\e059";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-down:before {
  content: "\e05c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-down:after {
  content: "\e05d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-down-left:before {
  content: "\e05e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-down-left:after {
  content: "\e05f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-down-right:before {
  content: "\e060";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-down-right:after {
  content: "\e061";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-left:before {
  content: "\e062";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-left:after {
  content: "\e063";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-right:before {
  content: "\e064";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-right:after {
  content: "\e065";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-up:before {
  content: "\e066";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-up:after {
  content: "\e067";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-up-left:before {
  content: "\e068";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-up-left:after {
  content: "\e069";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-line-up-right:before {
  content: "\e06a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-line-up-right:after {
  content: "\e06b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-right:before {
  content: "\e06c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-right:after {
  content: "\e06d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-down:before {
  content: "\e06e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-down:after {
  content: "\e06f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-down-left:before {
  content: "\e070";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-down-left:after {
  content: "\e071";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-down-right:before {
  content: "\e072";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-down-right:after {
  content: "\e073";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-in:before {
  content: "\e5dc";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-in:after {
  content: "\e5dd";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-left:before {
  content: "\e074";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-left:after {
  content: "\e075";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-out:before {
  content: "\e5de";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-out:after {
  content: "\e5df";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-right:before {
  content: "\e076";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-right:after {
  content: "\e077";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-up:before {
  content: "\e078";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-up:after {
  content: "\e079";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-up-left:before {
  content: "\e07a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-up-left:after {
  content: "\e07b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-square-up-right:before {
  content: "\e07c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-square-up-right:after {
  content: "\e07d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-down-left:before {
  content: "\e07e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-down-left:after {
  content: "\e07f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-down-right:before {
  content: "\e080";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-down-right:after {
  content: "\e081";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-left-down:before {
  content: "\e082";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-left-down:after {
  content: "\e083";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-left-up:before {
  content: "\e084";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-left-up:after {
  content: "\e085";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-right-down:before {
  content: "\e086";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-right-down:after {
  content: "\e087";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-right-up:before {
  content: "\e088";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-right-up:after {
  content: "\e089";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-up-left:before {
  content: "\e08a";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-up-left:after {
  content: "\e08b";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-u-up-right:before {
  content: "\e08c";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-u-up-right:after {
  content: "\e08d";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-up:before {
  content: "\e08e";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-up:after {
  content: "\e08f";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-up-left:before {
  content: "\e090";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-up-left:after {
  content: "\e091";
  margin-left: -1em;
}
.ph-duotone.ph-arrow-up-right:before {
  content: "\e092";
  opacity: 0.2;
}
.ph-duotone.ph-arrow-up-right:after {
  content: "\e093";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-clockwise:before {
  content: "\e094";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-clockwise:after {
  content: "\e095";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-counter-clockwise:before {
  content: "\e096";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-counter-clockwise:after {
  content: "\e097";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-down-up:before {
  content: "\e098";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-down-up:after {
  content: "\e099";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-horizontal:before {
  content: "\eb06";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-horizontal:after {
  content: "\eb07";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in:before {
  content: "\e09a";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in:after {
  content: "\e09b";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in-cardinal:before {
  content: "\e09c";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in-cardinal:after {
  content: "\e09d";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in-line-horizontal:before {
  content: "\e530";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in-line-horizontal:after {
  content: "\e531";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in-line-vertical:before {
  content: "\e532";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in-line-vertical:after {
  content: "\e533";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-in-simple:before {
  content: "\e09e";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-in-simple:after {
  content: "\e09f";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-left-right:before {
  content: "\e0a0";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-left-right:after {
  content: "\e0a1";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-merge:before {
  content: "\ed3e";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-merge:after {
  content: "\ed3f";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out:before {
  content: "\e0a2";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out:after {
  content: "\e0a3";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out-cardinal:before {
  content: "\e0a4";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out-cardinal:after {
  content: "\e0a5";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out-line-horizontal:before {
  content: "\e534";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out-line-horizontal:after {
  content: "\e535";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out-line-vertical:before {
  content: "\e536";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out-line-vertical:after {
  content: "\e537";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-out-simple:before {
  content: "\e0a6";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-out-simple:after {
  content: "\e0a7";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-split:before {
  content: "\ed3c";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-split:after {
  content: "\ed3d";
  margin-left: -1em;
}
.ph-duotone.ph-arrows-vertical:before {
  content: "\eb04";
  opacity: 0.2;
}
.ph-duotone.ph-arrows-vertical:after {
  content: "\eb05";
  margin-left: -1em;
}
.ph-duotone.ph-article:before {
  content: "\e0a8";
  opacity: 0.2;
}
.ph-duotone.ph-article:after {
  content: "\e0a9";
  margin-left: -1em;
}
.ph-duotone.ph-article-medium:before {
  content: "\e5e0";
  opacity: 0.2;
}
.ph-duotone.ph-article-medium:after {
  content: "\e5e1";
  margin-left: -1em;
}
.ph-duotone.ph-article-ny-times:before {
  content: "\e5e2";
  opacity: 0.2;
}
.ph-duotone.ph-article-ny-times:after {
  content: "\e5e3";
  margin-left: -1em;
}
.ph-duotone.ph-asclepius:before {
  content: "\ee34";
  opacity: 0.2;
}
.ph-duotone.ph-asclepius:after {
  content: "\ee35";
  margin-left: -1em;
}
.ph-duotone.ph-asterisk:before {
  content: "\e0aa";
  opacity: 0.2;
}
.ph-duotone.ph-asterisk:after {
  content: "\e0ab";
  margin-left: -1em;
}
.ph-duotone.ph-asterisk-simple:before {
  content: "\e832";
  opacity: 0.2;
}
.ph-duotone.ph-asterisk-simple:after {
  content: "\e833";
  margin-left: -1em;
}
.ph-duotone.ph-at:before {
  content: "\e0ac";
  opacity: 0.2;
}
.ph-duotone.ph-at:after {
  content: "\e0ad";
  margin-left: -1em;
}
.ph-duotone.ph-atom:before {
  content: "\e5e4";
  opacity: 0.2;
}
.ph-duotone.ph-atom:after {
  content: "\e5e5";
  margin-left: -1em;
}
.ph-duotone.ph-avocado:before {
  content: "\ee04";
  opacity: 0.2;
}
.ph-duotone.ph-avocado:after {
  content: "\ee05";
  margin-left: -1em;
}
.ph-duotone.ph-axe:before {
  content: "\e9fc";
  opacity: 0.2;
}
.ph-duotone.ph-axe:after {
  content: "\e9fd";
  margin-left: -1em;
}
.ph-duotone.ph-baby:before {
  content: "\e774";
  opacity: 0.2;
}
.ph-duotone.ph-baby:after {
  content: "\e775";
  margin-left: -1em;
}
.ph-duotone.ph-baby-carriage:before {
  content: "\e818";
  opacity: 0.2;
}
.ph-duotone.ph-baby-carriage:after {
  content: "\e819";
  margin-left: -1em;
}
.ph-duotone.ph-backpack:before {
  content: "\e922";
  opacity: 0.2;
}
.ph-duotone.ph-backpack:after {
  content: "\e923";
  margin-left: -1em;
}
.ph-duotone.ph-backspace:before {
  content: "\e0ae";
  opacity: 0.2;
}
.ph-duotone.ph-backspace:after {
  content: "\e0af";
  margin-left: -1em;
}
.ph-duotone.ph-bag:before {
  content: "\e0b0";
  opacity: 0.2;
}
.ph-duotone.ph-bag:after {
  content: "\e0b1";
  margin-left: -1em;
}
.ph-duotone.ph-bag-simple:before {
  content: "\e5e6";
  opacity: 0.2;
}
.ph-duotone.ph-bag-simple:after {
  content: "\e5e7";
  margin-left: -1em;
}
.ph-duotone.ph-balloon:before {
  content: "\e76c";
  opacity: 0.2;
}
.ph-duotone.ph-balloon:after {
  content: "\e76d";
  margin-left: -1em;
}
.ph-duotone.ph-bandaids:before {
  content: "\e0b2";
  opacity: 0.2;
}
.ph-duotone.ph-bandaids:after {
  content: "\e0b3";
  margin-left: -1em;
}
.ph-duotone.ph-bank:before {
  content: "\e0b4";
  opacity: 0.2;
}
.ph-duotone.ph-bank:after {
  content: "\e0b5";
  margin-left: -1em;
}
.ph-duotone.ph-barbell:before {
  content: "\e0b6";
  opacity: 0.2;
}
.ph-duotone.ph-barbell:after {
  content: "\e0b7";
  margin-left: -1em;
}
.ph-duotone.ph-barcode:before {
  content: "\e0b8";
  opacity: 0.2;
}
.ph-duotone.ph-barcode:after {
  content: "\e0b9";
  margin-left: -1em;
}
.ph-duotone.ph-barn:before {
  content: "\ec72";
  opacity: 0.2;
}
.ph-duotone.ph-barn:after {
  content: "\ec73";
  margin-left: -1em;
}
.ph-duotone.ph-barricade:before {
  content: "\e948";
  opacity: 0.2;
}
.ph-duotone.ph-barricade:after {
  content: "\e949";
  margin-left: -1em;
}
.ph-duotone.ph-baseball:before {
  content: "\e71a";
  opacity: 0.2;
}
.ph-duotone.ph-baseball:after {
  content: "\e71b";
  margin-left: -1em;
}
.ph-duotone.ph-baseball-cap:before {
  content: "\ea28";
  opacity: 0.2;
}
.ph-duotone.ph-baseball-cap:after {
  content: "\ea29";
  margin-left: -1em;
}
.ph-duotone.ph-baseball-helmet:before {
  content: "\ee4a";
  opacity: 0.2;
}
.ph-duotone.ph-baseball-helmet:after {
  content: "\ee4b";
  margin-left: -1em;
}
.ph-duotone.ph-basket:before {
  content: "\e964";
  opacity: 0.2;
}
.ph-duotone.ph-basket:after {
  content: "\e965";
  margin-left: -1em;
}
.ph-duotone.ph-basketball:before {
  content: "\e724";
  opacity: 0.2;
}
.ph-duotone.ph-basketball:after {
  content: "\e725";
  margin-left: -1em;
}
.ph-duotone.ph-bathtub:before {
  content: "\e81e";
  opacity: 0.2;
}
.ph-duotone.ph-bathtub:after {
  content: "\e81f";
  margin-left: -1em;
}
.ph-duotone.ph-battery-charging:before {
  content: "\e0ba";
  opacity: 0.2;
}
.ph-duotone.ph-battery-charging:after {
  content: "\e0bb";
  margin-left: -1em;
}
.ph-duotone.ph-battery-charging-vertical:before {
  content: "\e0bc";
  opacity: 0.2;
}
.ph-duotone.ph-battery-charging-vertical:after {
  content: "\e0bd";
  margin-left: -1em;
}
.ph-duotone.ph-battery-empty:before {
  content: "\e0be";
  opacity: 0.2;
}
.ph-duotone.ph-battery-empty:after {
  content: "\e0bf";
  margin-left: -1em;
}
.ph-duotone.ph-battery-full:before {
  content: "\e0c0";
  opacity: 0.2;
}
.ph-duotone.ph-battery-full:after {
  content: "\e0c1";
  margin-left: -1em;
}
.ph-duotone.ph-battery-high:before {
  content: "\e0c2";
  opacity: 0.2;
}
.ph-duotone.ph-battery-high:after {
  content: "\e0c3";
  margin-left: -1em;
}
.ph-duotone.ph-battery-low:before {
  content: "\e0c4";
  opacity: 0.2;
}
.ph-duotone.ph-battery-low:after {
  content: "\e0c5";
  margin-left: -1em;
}
.ph-duotone.ph-battery-medium:before {
  content: "\e0c6";
  opacity: 0.2;
}
.ph-duotone.ph-battery-medium:after {
  content: "\e0c7";
  margin-left: -1em;
}
.ph-duotone.ph-battery-plus:before {
  content: "\e808";
  opacity: 0.2;
}
.ph-duotone.ph-battery-plus:after {
  content: "\e809";
  margin-left: -1em;
}
.ph-duotone.ph-battery-plus-vertical:before {
  content: "\ec50";
  opacity: 0.2;
}
.ph-duotone.ph-battery-plus-vertical:after {
  content: "\ec51";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-empty:before {
  content: "\e7c6";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-empty:after {
  content: "\e7c7";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-full:before {
  content: "\e7c4";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-full:after {
  content: "\e7c5";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-high:before {
  content: "\e7c2";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-high:after {
  content: "\e7c3";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-low:before {
  content: "\e7be";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-low:after {
  content: "\e7bf";
  margin-left: -1em;
}
.ph-duotone.ph-battery-vertical-medium:before {
  content: "\e7c0";
  opacity: 0.2;
}
.ph-duotone.ph-battery-vertical-medium:after {
  content: "\e7c1";
  margin-left: -1em;
}
.ph-duotone.ph-battery-warning:before {
  content: "\e0c8";
  opacity: 0.2;
}
.ph-duotone.ph-battery-warning:after {
  content: "\e0c9";
  margin-left: -1em;
}
.ph-duotone.ph-battery-warning-vertical:before {
  content: "\e0ca";
  opacity: 0.2;
}
.ph-duotone.ph-battery-warning-vertical:after {
  content: "\e0cb";
  margin-left: -1em;
}
.ph-duotone.ph-beach-ball:before {
  content: "\ed24";
  opacity: 0.2;
}
.ph-duotone.ph-beach-ball:after {
  content: "\ed25";
  margin-left: -1em;
}
.ph-duotone.ph-beanie:before {
  content: "\ea2a";
  opacity: 0.2;
}
.ph-duotone.ph-beanie:after {
  content: "\ea2b";
  margin-left: -1em;
}
.ph-duotone.ph-bed:before {
  content: "\e0cc";
  opacity: 0.2;
}
.ph-duotone.ph-bed:after {
  content: "\e0cd";
  margin-left: -1em;
}
.ph-duotone.ph-beer-bottle:before {
  content: "\e7b0";
  opacity: 0.2;
}
.ph-duotone.ph-beer-bottle:after {
  content: "\e7b1";
  margin-left: -1em;
}
.ph-duotone.ph-beer-stein:before {
  content: "\eb62";
  opacity: 0.2;
}
.ph-duotone.ph-beer-stein:after {
  content: "\eb63";
  margin-left: -1em;
}
.ph-duotone.ph-behance-logo:before {
  content: "\e7f4";
  opacity: 0.2;
}
.ph-duotone.ph-behance-logo:after {
  content: "\e7f5";
  margin-left: -1em;
}
.ph-duotone.ph-bell:before {
  content: "\e0ce";
  opacity: 0.2;
}
.ph-duotone.ph-bell:after {
  content: "\e0cf";
  margin-left: -1em;
}
.ph-duotone.ph-bell-ringing:before {
  content: "\e5e8";
  opacity: 0.2;
}
.ph-duotone.ph-bell-ringing:after {
  content: "\e5e9";
  margin-left: -1em;
}
.ph-duotone.ph-bell-simple:before {
  content: "\e0d0";
  opacity: 0.2;
}
.ph-duotone.ph-bell-simple:after {
  content: "\e0d1";
  margin-left: -1em;
}
.ph-duotone.ph-bell-simple-ringing:before {
  content: "\e5ea";
  opacity: 0.2;
}
.ph-duotone.ph-bell-simple-ringing:after {
  content: "\e5eb";
  margin-left: -1em;
}
.ph-duotone.ph-bell-simple-slash:before {
  content: "\e0d2";
  opacity: 0.2;
}
.ph-duotone.ph-bell-simple-slash:after {
  content: "\e0d3";
  margin-left: -1em;
}
.ph-duotone.ph-bell-simple-z:before {
  content: "\e5ec";
  opacity: 0.2;
}
.ph-duotone.ph-bell-simple-z:after {
  content: "\e5ed";
  margin-left: -1em;
}
.ph-duotone.ph-bell-slash:before {
  content: "\e0d4";
  opacity: 0.2;
}
.ph-duotone.ph-bell-slash:after {
  content: "\e0d5";
  margin-left: -1em;
}
.ph-duotone.ph-bell-z:before {
  content: "\e5ee";
  opacity: 0.2;
}
.ph-duotone.ph-bell-z:after {
  content: "\e5ef";
  margin-left: -1em;
}
.ph-duotone.ph-belt:before {
  content: "\ea2c";
  opacity: 0.2;
}
.ph-duotone.ph-belt:after {
  content: "\ea2d";
  margin-left: -1em;
}
.ph-duotone.ph-bezier-curve:before {
  content: "\eb00";
  opacity: 0.2;
}
.ph-duotone.ph-bezier-curve:after {
  content: "\eb01";
  margin-left: -1em;
}
.ph-duotone.ph-bicycle:before {
  content: "\e0d6";
  opacity: 0.2;
}
.ph-duotone.ph-bicycle:after {
  content: "\e0d7";
  margin-left: -1em;
}
.ph-duotone.ph-binary:before {
  content: "\ee60";
  opacity: 0.2;
}
.ph-duotone.ph-binary:after {
  content: "\ee61";
  margin-left: -1em;
}
.ph-duotone.ph-binoculars:before {
  content: "\ea64";
  opacity: 0.2;
}
.ph-duotone.ph-binoculars:after {
  content: "\ea65";
  margin-left: -1em;
}
.ph-duotone.ph-biohazard:before {
  content: "\e9e0";
  opacity: 0.2;
}
.ph-duotone.ph-biohazard:after {
  content: "\e9e1";
  margin-left: -1em;
}
.ph-duotone.ph-bird:before {
  content: "\e72c";
  opacity: 0.2;
}
.ph-duotone.ph-bird:after {
  content: "\e72d";
  margin-left: -1em;
}
.ph-duotone.ph-blueprint:before {
  content: "\eda0";
  opacity: 0.2;
}
.ph-duotone.ph-blueprint:after {
  content: "\eda1";
  margin-left: -1em;
}
.ph-duotone.ph-bluetooth:before {
  content: "\e0da";
  opacity: 0.2;
}
.ph-duotone.ph-bluetooth:after {
  content: "\e0db";
  margin-left: -1em;
}
.ph-duotone.ph-bluetooth-connected:before {
  content: "\e0dc";
  opacity: 0.2;
}
.ph-duotone.ph-bluetooth-connected:after {
  content: "\e0dd";
  margin-left: -1em;
}
.ph-duotone.ph-bluetooth-slash:before {
  content: "\e0de";
  opacity: 0.2;
}
.ph-duotone.ph-bluetooth-slash:after {
  content: "\e0df";
  margin-left: -1em;
}
.ph-duotone.ph-bluetooth-x:before {
  content: "\e0e0";
  opacity: 0.2;
}
.ph-duotone.ph-bluetooth-x:after {
  content: "\e0e1";
  margin-left: -1em;
}
.ph-duotone.ph-boat:before {
  content: "\e786";
  opacity: 0.2;
}
.ph-duotone.ph-boat:after {
  content: "\e787";
  margin-left: -1em;
}
.ph-duotone.ph-bomb:before {
  content: "\ee0a";
  opacity: 0.2;
}
.ph-duotone.ph-bomb:after {
  content: "\ee0b";
  margin-left: -1em;
}
.ph-duotone.ph-bone:before {
  content: "\e7f2";
  opacity: 0.2;
}
.ph-duotone.ph-bone:after {
  content: "\e7f3";
  margin-left: -1em;
}
.ph-duotone.ph-book:before {
  content: "\e0e2";
  opacity: 0.2;
}
.ph-duotone.ph-book:after {
  content: "\e0e3";
  margin-left: -1em;
}
.ph-duotone.ph-book-bookmark:before {
  content: "\e0e4";
  opacity: 0.2;
}
.ph-duotone.ph-book-bookmark:after {
  content: "\e0e5";
  margin-left: -1em;
}
.ph-duotone.ph-book-open:before {
  content: "\e0e6";
  opacity: 0.2;
}
.ph-duotone.ph-book-open:after {
  content: "\e0e7";
  margin-left: -1em;
}
.ph-duotone.ph-book-open-text:before {
  content: "\e8f2";
  opacity: 0.2;
}
.ph-duotone.ph-book-open-text:after {
  content: "\e8f3";
  margin-left: -1em;
}
.ph-duotone.ph-book-open-user:before {
  content: "\ede0";
  opacity: 0.2;
}
.ph-duotone.ph-book-open-user:after {
  content: "\ede1";
  margin-left: -1em;
}
.ph-duotone.ph-bookmark:before {
  content: "\e0e8";
  opacity: 0.2;
}
.ph-duotone.ph-bookmark:after {
  content: "\e0e9";
  margin-left: -1em;
}
.ph-duotone.ph-bookmark-simple:before {
  content: "\e0ea";
  opacity: 0.2;
}
.ph-duotone.ph-bookmark-simple:after {
  content: "\e0eb";
  margin-left: -1em;
}
.ph-duotone.ph-bookmarks:before {
  content: "\e0ec";
  opacity: 0.2;
}
.ph-duotone.ph-bookmarks:after {
  content: "\e0ed";
  margin-left: -1em;
}
.ph-duotone.ph-bookmarks-simple:before {
  content: "\e5f0";
  opacity: 0.2;
}
.ph-duotone.ph-bookmarks-simple:after {
  content: "\e5f1";
  margin-left: -1em;
}
.ph-duotone.ph-books:before {
  content: "\e758";
  opacity: 0.2;
}
.ph-duotone.ph-books:after {
  content: "\e759";
  margin-left: -1em;
}
.ph-duotone.ph-boot:before {
  content: "\ecca";
  opacity: 0.2;
}
.ph-duotone.ph-boot:after {
  content: "\eccb";
  margin-left: -1em;
}
.ph-duotone.ph-boules:before {
  content: "\e722";
  opacity: 0.2;
}
.ph-duotone.ph-boules:after {
  content: "\e723";
  margin-left: -1em;
}
.ph-duotone.ph-bounding-box:before {
  content: "\e6ce";
  opacity: 0.2;
}
.ph-duotone.ph-bounding-box:after {
  content: "\e6cf";
  margin-left: -1em;
}
.ph-duotone.ph-bowl-food:before {
  content: "\eaa4";
  opacity: 0.2;
}
.ph-duotone.ph-bowl-food:after {
  content: "\eaa5";
  margin-left: -1em;
}
.ph-duotone.ph-bowl-steam:before {
  content: "\e8e4";
  opacity: 0.2;
}
.ph-duotone.ph-bowl-steam:after {
  content: "\e8e5";
  margin-left: -1em;
}
.ph-duotone.ph-bowling-ball:before {
  content: "\ea34";
  opacity: 0.2;
}
.ph-duotone.ph-bowling-ball:after {
  content: "\ea35";
  margin-left: -1em;
}
.ph-duotone.ph-box-arrow-down:before {
  content: "\e00e";
  opacity: 0.2;
}
.ph-duotone.ph-box-arrow-down:after {
  content: "\e00f";
  margin-left: -1em;
}
.ph-duotone.ph-box-arrow-up:before {
  content: "\ee54";
  opacity: 0.2;
}
.ph-duotone.ph-box-arrow-up:after {
  content: "\ee55";
  margin-left: -1em;
}
.ph-duotone.ph-boxing-glove:before {
  content: "\ea36";
  opacity: 0.2;
}
.ph-duotone.ph-boxing-glove:after {
  content: "\ea37";
  margin-left: -1em;
}
.ph-duotone.ph-brackets-angle:before {
  content: "\e862";
  opacity: 0.2;
}
.ph-duotone.ph-brackets-angle:after {
  content: "\e863";
  margin-left: -1em;
}
.ph-duotone.ph-brackets-curly:before {
  content: "\e860";
  opacity: 0.2;
}
.ph-duotone.ph-brackets-curly:after {
  content: "\e861";
  margin-left: -1em;
}
.ph-duotone.ph-brackets-round:before {
  content: "\e864";
  opacity: 0.2;
}
.ph-duotone.ph-brackets-round:after {
  content: "\e865";
  margin-left: -1em;
}
.ph-duotone.ph-brackets-square:before {
  content: "\e85e";
  opacity: 0.2;
}
.ph-duotone.ph-brackets-square:after {
  content: "\e85f";
  margin-left: -1em;
}
.ph-duotone.ph-brain:before {
  content: "\e74e";
  opacity: 0.2;
}
.ph-duotone.ph-brain:after {
  content: "\e74f";
  margin-left: -1em;
}
.ph-duotone.ph-brandy:before {
  content: "\e6b4";
  opacity: 0.2;
}
.ph-duotone.ph-brandy:after {
  content: "\e6b5";
  margin-left: -1em;
}
.ph-duotone.ph-bread:before {
  content: "\e81c";
  opacity: 0.2;
}
.ph-duotone.ph-bread:after {
  content: "\e81d";
  margin-left: -1em;
}
.ph-duotone.ph-bridge:before {
  content: "\ea68";
  opacity: 0.2;
}
.ph-duotone.ph-bridge:after {
  content: "\ea69";
  margin-left: -1em;
}
.ph-duotone.ph-briefcase:before {
  content: "\e0ee";
  opacity: 0.2;
}
.ph-duotone.ph-briefcase:after {
  content: "\e0ef";
  margin-left: -1em;
}
.ph-duotone.ph-briefcase-metal:before {
  content: "\e5f2";
  opacity: 0.2;
}
.ph-duotone.ph-briefcase-metal:after {
  content: "\e5f3";
  margin-left: -1em;
}
.ph-duotone.ph-broadcast:before {
  content: "\e0f2";
  opacity: 0.2;
}
.ph-duotone.ph-broadcast:after {
  content: "\e0f3";
  margin-left: -1em;
}
.ph-duotone.ph-broom:before {
  content: "\ec54";
  opacity: 0.2;
}
.ph-duotone.ph-broom:after {
  content: "\ec55";
  margin-left: -1em;
}
.ph-duotone.ph-browser:before {
  content: "\e0f4";
  opacity: 0.2;
}
.ph-duotone.ph-browser:after {
  content: "\e0f5";
  margin-left: -1em;
}
.ph-duotone.ph-browsers:before {
  content: "\e0f6";
  opacity: 0.2;
}
.ph-duotone.ph-browsers:after {
  content: "\e0f7";
  margin-left: -1em;
}
.ph-duotone.ph-bug:before {
  content: "\e5f4";
  opacity: 0.2;
}
.ph-duotone.ph-bug:after {
  content: "\e5f5";
  margin-left: -1em;
}
.ph-duotone.ph-bug-beetle:before {
  content: "\e5f6";
  opacity: 0.2;
}
.ph-duotone.ph-bug-beetle:after {
  content: "\e5f7";
  margin-left: -1em;
}
.ph-duotone.ph-bug-droid:before {
  content: "\e5f8";
  opacity: 0.2;
}
.ph-duotone.ph-bug-droid:after {
  content: "\e5f9";
  margin-left: -1em;
}
.ph-duotone.ph-building:before {
  content: "\e100";
  opacity: 0.2;
}
.ph-duotone.ph-building:after {
  content: "\e101";
  margin-left: -1em;
}
.ph-duotone.ph-building-apartment:before {
  content: "\e0fe";
  opacity: 0.2;
}
.ph-duotone.ph-building-apartment:after {
  content: "\e103";
  margin-left: -1em;
}
.ph-duotone.ph-building-office:before {
  content: "\e0ff";
  opacity: 0.2;
}
.ph-duotone.ph-building-office:after {
  content: "\e104";
  margin-left: -1em;
}
.ph-duotone.ph-buildings:before {
  content: "\e102";
  opacity: 0.2;
}
.ph-duotone.ph-buildings:after {
  content: "\e105";
  margin-left: -1em;
}
.ph-duotone.ph-bulldozer:before {
  content: "\ec6c";
  opacity: 0.2;
}
.ph-duotone.ph-bulldozer:after {
  content: "\ec6d";
  margin-left: -1em;
}
.ph-duotone.ph-bus:before {
  content: "\e106";
  opacity: 0.2;
}
.ph-duotone.ph-bus:after {
  content: "\e107";
  margin-left: -1em;
}
.ph-duotone.ph-butterfly:before {
  content: "\ea6e";
  opacity: 0.2;
}
.ph-duotone.ph-butterfly:after {
  content: "\ea6f";
  margin-left: -1em;
}
.ph-duotone.ph-cable-car:before {
  content: "\e49c";
  opacity: 0.2;
}
.ph-duotone.ph-cable-car:after {
  content: "\e49d";
  margin-left: -1em;
}
.ph-duotone.ph-cactus:before {
  content: "\e918";
  opacity: 0.2;
}
.ph-duotone.ph-cactus:after {
  content: "\e919";
  margin-left: -1em;
}
.ph-duotone.ph-cake:before {
  content: "\e780";
  opacity: 0.2;
}
.ph-duotone.ph-cake:after {
  content: "\e781";
  margin-left: -1em;
}
.ph-duotone.ph-calculator:before {
  content: "\e538";
  opacity: 0.2;
}
.ph-duotone.ph-calculator:after {
  content: "\e539";
  margin-left: -1em;
}
.ph-duotone.ph-calendar:before {
  content: "\e108";
  opacity: 0.2;
}
.ph-duotone.ph-calendar:after {
  content: "\e109";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-blank:before {
  content: "\e10a";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-blank:after {
  content: "\e10b";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-check:before {
  content: "\e712";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-check:after {
  content: "\e713";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-dot:before {
  content: "\e7b2";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-dot:after {
  content: "\e7b3";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-dots:before {
  content: "\e7b4";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-dots:after {
  content: "\e7b5";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-heart:before {
  content: "\e8b0";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-heart:after {
  content: "\e8b1";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-minus:before {
  content: "\ea14";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-minus:after {
  content: "\ea15";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-plus:before {
  content: "\e714";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-plus:after {
  content: "\e715";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-slash:before {
  content: "\ea12";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-slash:after {
  content: "\ea13";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-star:before {
  content: "\e8b2";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-star:after {
  content: "\e8b3";
  margin-left: -1em;
}
.ph-duotone.ph-calendar-x:before {
  content: "\e10c";
  opacity: 0.2;
}
.ph-duotone.ph-calendar-x:after {
  content: "\e10d";
  margin-left: -1em;
}
.ph-duotone.ph-call-bell:before {
  content: "\e7de";
  opacity: 0.2;
}
.ph-duotone.ph-call-bell:after {
  content: "\e7df";
  margin-left: -1em;
}
.ph-duotone.ph-camera:before {
  content: "\e10e";
  opacity: 0.2;
}
.ph-duotone.ph-camera:after {
  content: "\e10f";
  margin-left: -1em;
}
.ph-duotone.ph-camera-plus:before {
  content: "\ec58";
  opacity: 0.2;
}
.ph-duotone.ph-camera-plus:after {
  content: "\ec59";
  margin-left: -1em;
}
.ph-duotone.ph-camera-rotate:before {
  content: "\e7a4";
  opacity: 0.2;
}
.ph-duotone.ph-camera-rotate:after {
  content: "\e7a5";
  margin-left: -1em;
}
.ph-duotone.ph-camera-slash:before {
  content: "\e110";
  opacity: 0.2;
}
.ph-duotone.ph-camera-slash:after {
  content: "\e111";
  margin-left: -1em;
}
.ph-duotone.ph-campfire:before {
  content: "\e9d8";
  opacity: 0.2;
}
.ph-duotone.ph-campfire:after {
  content: "\e9d9";
  margin-left: -1em;
}
.ph-duotone.ph-car:before {
  content: "\e112";
  opacity: 0.2;
}
.ph-duotone.ph-car:after {
  content: "\e113";
  margin-left: -1em;
}
.ph-duotone.ph-car-battery:before {
  content: "\ee30";
  opacity: 0.2;
}
.ph-duotone.ph-car-battery:after {
  content: "\ee31";
  margin-left: -1em;
}
.ph-duotone.ph-car-profile:before {
  content: "\e8cc";
  opacity: 0.2;
}
.ph-duotone.ph-car-profile:after {
  content: "\e8cd";
  margin-left: -1em;
}
.ph-duotone.ph-car-simple:before {
  content: "\e114";
  opacity: 0.2;
}
.ph-duotone.ph-car-simple:after {
  content: "\e115";
  margin-left: -1em;
}
.ph-duotone.ph-cardholder:before {
  content: "\e5fa";
  opacity: 0.2;
}
.ph-duotone.ph-cardholder:after {
  content: "\e5fb";
  margin-left: -1em;
}
.ph-duotone.ph-cards:before {
  content: "\e0f8";
  opacity: 0.2;
}
.ph-duotone.ph-cards:after {
  content: "\e0f9";
  margin-left: -1em;
}
.ph-duotone.ph-cards-three:before {
  content: "\ee50";
  opacity: 0.2;
}
.ph-duotone.ph-cards-three:after {
  content: "\ee51";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-double-down:before {
  content: "\e116";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-double-down:after {
  content: "\e117";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-double-left:before {
  content: "\e118";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-double-left:after {
  content: "\e119";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-double-right:before {
  content: "\e11a";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-double-right:after {
  content: "\e11b";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-double-up:before {
  content: "\e11c";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-double-up:after {
  content: "\e11d";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-down:before {
  content: "\e11e";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-down:after {
  content: "\e11f";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-left:before {
  content: "\e120";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-left:after {
  content: "\e121";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-right:before {
  content: "\e122";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-right:after {
  content: "\e123";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-up:before {
  content: "\e124";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-up:after {
  content: "\e125";
  margin-left: -1em;
}
.ph-duotone.ph-caret-circle-up-down:before {
  content: "\e13e";
  opacity: 0.2;
}
.ph-duotone.ph-caret-circle-up-down:after {
  content: "\e13f";
  margin-left: -1em;
}
.ph-duotone.ph-caret-double-down:before {
  content: "\e126";
  opacity: 0.2;
}
.ph-duotone.ph-caret-double-down:after {
  content: "\e127";
  margin-left: -1em;
}
.ph-duotone.ph-caret-double-left:before {
  content: "\e128";
  opacity: 0.2;
}
.ph-duotone.ph-caret-double-left:after {
  content: "\e129";
  margin-left: -1em;
}
.ph-duotone.ph-caret-double-right:before {
  content: "\e12a";
  opacity: 0.2;
}
.ph-duotone.ph-caret-double-right:after {
  content: "\e12b";
  margin-left: -1em;
}
.ph-duotone.ph-caret-double-up:before {
  content: "\e12c";
  opacity: 0.2;
}
.ph-duotone.ph-caret-double-up:after {
  content: "\e12d";
  margin-left: -1em;
}
.ph-duotone.ph-caret-down:before {
  content: "\e136";
  opacity: 0.2;
}
.ph-duotone.ph-caret-down:after {
  content: "\e137";
  margin-left: -1em;
}
.ph-duotone.ph-caret-left:before {
  content: "\e138";
  opacity: 0.2;
}
.ph-duotone.ph-caret-left:after {
  content: "\e139";
  margin-left: -1em;
}
.ph-duotone.ph-caret-line-down:before {
  content: "\e134";
  opacity: 0.2;
}
.ph-duotone.ph-caret-line-down:after {
  content: "\e135";
  margin-left: -1em;
}
.ph-duotone.ph-caret-line-left:before {
  content: "\e132";
  opacity: 0.2;
}
.ph-duotone.ph-caret-line-left:after {
  content: "\e133";
  margin-left: -1em;
}
.ph-duotone.ph-caret-line-right:before {
  content: "\e130";
  opacity: 0.2;
}
.ph-duotone.ph-caret-line-right:after {
  content: "\e131";
  margin-left: -1em;
}
.ph-duotone.ph-caret-line-up:before {
  content: "\e12e";
  opacity: 0.2;
}
.ph-duotone.ph-caret-line-up:after {
  content: "\e12f";
  margin-left: -1em;
}
.ph-duotone.ph-caret-right:before {
  content: "\e13a";
  opacity: 0.2;
}
.ph-duotone.ph-caret-right:after {
  content: "\e13b";
  margin-left: -1em;
}
.ph-duotone.ph-caret-up:before {
  content: "\e13c";
  opacity: 0.2;
}
.ph-duotone.ph-caret-up:after {
  content: "\e13d";
  margin-left: -1em;
}
.ph-duotone.ph-caret-up-down:before {
  content: "\e140";
  opacity: 0.2;
}
.ph-duotone.ph-caret-up-down:after {
  content: "\e141";
  margin-left: -1em;
}
.ph-duotone.ph-carrot:before {
  content: "\ed38";
  opacity: 0.2;
}
.ph-duotone.ph-carrot:after {
  content: "\ed39";
  margin-left: -1em;
}
.ph-duotone.ph-cash-register:before {
  content: "\ed80";
  opacity: 0.2;
}
.ph-duotone.ph-cash-register:after {
  content: "\ed81";
  margin-left: -1em;
}
.ph-duotone.ph-cassette-tape:before {
  content: "\ed2e";
  opacity: 0.2;
}
.ph-duotone.ph-cassette-tape:after {
  content: "\ed2f";
  margin-left: -1em;
}
.ph-duotone.ph-castle-turret:before {
  content: "\e9d0";
  opacity: 0.2;
}
.ph-duotone.ph-castle-turret:after {
  content: "\e9d1";
  margin-left: -1em;
}
.ph-duotone.ph-cat:before {
  content: "\e748";
  opacity: 0.2;
}
.ph-duotone.ph-cat:after {
  content: "\e749";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-full:before {
  content: "\e142";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-full:after {
  content: "\e143";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-high:before {
  content: "\e144";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-high:after {
  content: "\e145";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-low:before {
  content: "\e146";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-low:after {
  content: "\e147";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-medium:before {
  content: "\e148";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-medium:after {
  content: "\e149";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-none:before {
  content: "\e14a";
  color: #444;
}
.ph-duotone.ph-cell-signal-slash:before {
  content: "\e14c";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-slash:after {
  content: "\e14d";
  margin-left: -1em;
}
.ph-duotone.ph-cell-signal-x:before {
  content: "\e14e";
  opacity: 0.2;
}
.ph-duotone.ph-cell-signal-x:after {
  content: "\e14f";
  margin-left: -1em;
}
.ph-duotone.ph-cell-tower:before {
  content: "\ebaa";
  opacity: 0.2;
}
.ph-duotone.ph-cell-tower:after {
  content: "\ebab";
  margin-left: -1em;
}
.ph-duotone.ph-certificate:before {
  content: "\e766";
  opacity: 0.2;
}
.ph-duotone.ph-certificate:after {
  content: "\e767";
  margin-left: -1em;
}
.ph-duotone.ph-chair:before {
  content: "\e950";
  opacity: 0.2;
}
.ph-duotone.ph-chair:after {
  content: "\e951";
  margin-left: -1em;
}
.ph-duotone.ph-chalkboard:before {
  content: "\e5fc";
  opacity: 0.2;
}
.ph-duotone.ph-chalkboard:after {
  content: "\e5fd";
  margin-left: -1em;
}
.ph-duotone.ph-chalkboard-simple:before {
  content: "\e5fe";
  opacity: 0.2;
}
.ph-duotone.ph-chalkboard-simple:after {
  content: "\e5ff";
  margin-left: -1em;
}
.ph-duotone.ph-chalkboard-teacher:before {
  content: "\e600";
  opacity: 0.2;
}
.ph-duotone.ph-chalkboard-teacher:after {
  content: "\e601";
  margin-left: -1em;
}
.ph-duotone.ph-champagne:before {
  content: "\eaca";
  opacity: 0.2;
}
.ph-duotone.ph-champagne:after {
  content: "\eacb";
  margin-left: -1em;
}
.ph-duotone.ph-charging-station:before {
  content: "\e8d0";
  opacity: 0.2;
}
.ph-duotone.ph-charging-station:after {
  content: "\e8d1";
  margin-left: -1em;
}
.ph-duotone.ph-chart-bar:before {
  content: "\e150";
  opacity: 0.2;
}
.ph-duotone.ph-chart-bar:after {
  content: "\e151";
  margin-left: -1em;
}
.ph-duotone.ph-chart-bar-horizontal:before {
  content: "\e152";
  opacity: 0.2;
}
.ph-duotone.ph-chart-bar-horizontal:after {
  content: "\e153";
  margin-left: -1em;
}
.ph-duotone.ph-chart-donut:before {
  content: "\eaa6";
  opacity: 0.2;
}
.ph-duotone.ph-chart-donut:after {
  content: "\eaa7";
  margin-left: -1em;
}
.ph-duotone.ph-chart-line:before {
  content: "\e154";
  opacity: 0.2;
}
.ph-duotone.ph-chart-line:after {
  content: "\e155";
  margin-left: -1em;
}
.ph-duotone.ph-chart-line-down:before {
  content: "\e8b6";
  opacity: 0.2;
}
.ph-duotone.ph-chart-line-down:after {
  content: "\e8b7";
  margin-left: -1em;
}
.ph-duotone.ph-chart-line-up:before {
  content: "\e156";
  opacity: 0.2;
}
.ph-duotone.ph-chart-line-up:after {
  content: "\e157";
  margin-left: -1em;
}
.ph-duotone.ph-chart-pie:before {
  content: "\e158";
  opacity: 0.2;
}
.ph-duotone.ph-chart-pie:after {
  content: "\e159";
  margin-left: -1em;
}
.ph-duotone.ph-chart-pie-slice:before {
  content: "\e15a";
  opacity: 0.2;
}
.ph-duotone.ph-chart-pie-slice:after {
  content: "\e15b";
  margin-left: -1em;
}
.ph-duotone.ph-chart-polar:before {
  content: "\eaa8";
  opacity: 0.2;
}
.ph-duotone.ph-chart-polar:after {
  content: "\eaa9";
  margin-left: -1em;
}
.ph-duotone.ph-chart-scatter:before {
  content: "\eaac";
  opacity: 0.2;
}
.ph-duotone.ph-chart-scatter:after {
  content: "\eaad";
  margin-left: -1em;
}
.ph-duotone.ph-chat:before {
  content: "\e15c";
  opacity: 0.2;
}
.ph-duotone.ph-chat:after {
  content: "\e15d";
  margin-left: -1em;
}
.ph-duotone.ph-chat-centered:before {
  content: "\e160";
  opacity: 0.2;
}
.ph-duotone.ph-chat-centered:after {
  content: "\e161";
  margin-left: -1em;
}
.ph-duotone.ph-chat-centered-dots:before {
  content: "\e164";
  opacity: 0.2;
}
.ph-duotone.ph-chat-centered-dots:after {
  content: "\e165";
  margin-left: -1em;
}
.ph-duotone.ph-chat-centered-slash:before {
  content: "\e162";
  opacity: 0.2;
}
.ph-duotone.ph-chat-centered-slash:after {
  content: "\e163";
  margin-left: -1em;
}
.ph-duotone.ph-chat-centered-text:before {
  content: "\e166";
  opacity: 0.2;
}
.ph-duotone.ph-chat-centered-text:after {
  content: "\e167";
  margin-left: -1em;
}
.ph-duotone.ph-chat-circle:before {
  content: "\e168";
  opacity: 0.2;
}
.ph-duotone.ph-chat-circle:after {
  content: "\e169";
  margin-left: -1em;
}
.ph-duotone.ph-chat-circle-dots:before {
  content: "\e16c";
  opacity: 0.2;
}
.ph-duotone.ph-chat-circle-dots:after {
  content: "\e16d";
  margin-left: -1em;
}
.ph-duotone.ph-chat-circle-slash:before {
  content: "\e16a";
  opacity: 0.2;
}
.ph-duotone.ph-chat-circle-slash:after {
  content: "\e16b";
  margin-left: -1em;
}
.ph-duotone.ph-chat-circle-text:before {
  content: "\e16e";
  opacity: 0.2;
}
.ph-duotone.ph-chat-circle-text:after {
  content: "\e16f";
  margin-left: -1em;
}
.ph-duotone.ph-chat-dots:before {
  content: "\e170";
  opacity: 0.2;
}
.ph-duotone.ph-chat-dots:after {
  content: "\e171";
  margin-left: -1em;
}
.ph-duotone.ph-chat-slash:before {
  content: "\e15e";
  opacity: 0.2;
}
.ph-duotone.ph-chat-slash:after {
  content: "\e15f";
  margin-left: -1em;
}
.ph-duotone.ph-chat-teardrop:before {
  content: "\e172";
  opacity: 0.2;
}
.ph-duotone.ph-chat-teardrop:after {
  content: "\e173";
  margin-left: -1em;
}
.ph-duotone.ph-chat-teardrop-dots:before {
  content: "\e176";
  opacity: 0.2;
}
.ph-duotone.ph-chat-teardrop-dots:after {
  content: "\e177";
  margin-left: -1em;
}
.ph-duotone.ph-chat-teardrop-slash:before {
  content: "\e174";
  opacity: 0.2;
}
.ph-duotone.ph-chat-teardrop-slash:after {
  content: "\e175";
  margin-left: -1em;
}
.ph-duotone.ph-chat-teardrop-text:before {
  content: "\e178";
  opacity: 0.2;
}
.ph-duotone.ph-chat-teardrop-text:after {
  content: "\e179";
  margin-left: -1em;
}
.ph-duotone.ph-chat-text:before {
  content: "\e17a";
  opacity: 0.2;
}
.ph-duotone.ph-chat-text:after {
  content: "\e17b";
  margin-left: -1em;
}
.ph-duotone.ph-chats:before {
  content: "\e17c";
  opacity: 0.2;
}
.ph-duotone.ph-chats:after {
  content: "\e17d";
  margin-left: -1em;
}
.ph-duotone.ph-chats-circle:before {
  content: "\e17e";
  opacity: 0.2;
}
.ph-duotone.ph-chats-circle:after {
  content: "\e17f";
  margin-left: -1em;
}
.ph-duotone.ph-chats-teardrop:before {
  content: "\e180";
  opacity: 0.2;
}
.ph-duotone.ph-chats-teardrop:after {
  content: "\e181";
  margin-left: -1em;
}
.ph-duotone.ph-check:before {
  content: "\e182";
  opacity: 0.2;
}
.ph-duotone.ph-check:after {
  content: "\e183";
  margin-left: -1em;
}
.ph-duotone.ph-check-circle:before {
  content: "\e184";
  opacity: 0.2;
}
.ph-duotone.ph-check-circle:after {
  content: "\e185";
  margin-left: -1em;
}
.ph-duotone.ph-check-fat:before {
  content: "\eba6";
  opacity: 0.2;
}
.ph-duotone.ph-check-fat:after {
  content: "\eba7";
  margin-left: -1em;
}
.ph-duotone.ph-check-square:before {
  content: "\e186";
  opacity: 0.2;
}
.ph-duotone.ph-check-square:after {
  content: "\e187";
  margin-left: -1em;
}
.ph-duotone.ph-check-square-offset:before {
  content: "\e188";
  opacity: 0.2;
}
.ph-duotone.ph-check-square-offset:after {
  content: "\e189";
  margin-left: -1em;
}
.ph-duotone.ph-checkerboard:before {
  content: "\e8c4";
  opacity: 0.2;
}
.ph-duotone.ph-checkerboard:after {
  content: "\e8c5";
  margin-left: -1em;
}
.ph-duotone.ph-checks:before {
  content: "\e53a";
  opacity: 0.2;
}
.ph-duotone.ph-checks:after {
  content: "\e53b";
  margin-left: -1em;
}
.ph-duotone.ph-cheers:before {
  content: "\ea4a";
  opacity: 0.2;
}
.ph-duotone.ph-cheers:after {
  content: "\ea4b";
  margin-left: -1em;
}
.ph-duotone.ph-cheese:before {
  content: "\e9fe";
  opacity: 0.2;
}
.ph-duotone.ph-cheese:after {
  content: "\e9ff";
  margin-left: -1em;
}
.ph-duotone.ph-chef-hat:before {
  content: "\ed8e";
  opacity: 0.2;
}
.ph-duotone.ph-chef-hat:after {
  content: "\ed8f";
  margin-left: -1em;
}
.ph-duotone.ph-cherries:before {
  content: "\e830";
  opacity: 0.2;
}
.ph-duotone.ph-cherries:after {
  content: "\e831";
  margin-left: -1em;
}
.ph-duotone.ph-church:before {
  content: "\ecea";
  opacity: 0.2;
}
.ph-duotone.ph-church:after {
  content: "\eceb";
  margin-left: -1em;
}
.ph-duotone.ph-cigarette:before {
  content: "\ed90";
  opacity: 0.2;
}
.ph-duotone.ph-cigarette:after {
  content: "\ed91";
  margin-left: -1em;
}
.ph-duotone.ph-cigarette-slash:before {
  content: "\ed92";
  opacity: 0.2;
}
.ph-duotone.ph-cigarette-slash:after {
  content: "\ed93";
  margin-left: -1em;
}
.ph-duotone.ph-circle:before {
  content: "\e18a";
  opacity: 0.2;
}
.ph-duotone.ph-circle:after {
  content: "\e18b";
  margin-left: -1em;
}
.ph-duotone.ph-circle-dashed:before {
  content: "\e602";
  opacity: 0.2;
}
.ph-duotone.ph-circle-dashed:after {
  content: "\e603";
  margin-left: -1em;
}
.ph-duotone.ph-circle-half:before {
  content: "\e18c";
  opacity: 0.2;
}
.ph-duotone.ph-circle-half:after {
  content: "\e18d";
  margin-left: -1em;
}
.ph-duotone.ph-circle-half-tilt:before {
  content: "\e18e";
  opacity: 0.2;
}
.ph-duotone.ph-circle-half-tilt:after {
  content: "\e18f";
  margin-left: -1em;
}
.ph-duotone.ph-circle-notch:before {
  content: "\eb44";
  opacity: 0.2;
}
.ph-duotone.ph-circle-notch:after {
  content: "\eb45";
  margin-left: -1em;
}
.ph-duotone.ph-circles-four:before {
  content: "\e190";
  opacity: 0.2;
}
.ph-duotone.ph-circles-four:after {
  content: "\e191";
  margin-left: -1em;
}
.ph-duotone.ph-circles-three:before {
  content: "\e192";
  opacity: 0.2;
}
.ph-duotone.ph-circles-three:after {
  content: "\e193";
  margin-left: -1em;
}
.ph-duotone.ph-circles-three-plus:before {
  content: "\e194";
  opacity: 0.2;
}
.ph-duotone.ph-circles-three-plus:after {
  content: "\e195";
  margin-left: -1em;
}
.ph-duotone.ph-circuitry:before {
  content: "\e9c2";
  opacity: 0.2;
}
.ph-duotone.ph-circuitry:after {
  content: "\e9c3";
  margin-left: -1em;
}
.ph-duotone.ph-city:before {
  content: "\ea6a";
  opacity: 0.2;
}
.ph-duotone.ph-city:after {
  content: "\ea6b";
  margin-left: -1em;
}
.ph-duotone.ph-clipboard:before {
  content: "\e196";
  opacity: 0.2;
}
.ph-duotone.ph-clipboard:after {
  content: "\e197";
  margin-left: -1em;
}
.ph-duotone.ph-clipboard-text:before {
  content: "\e198";
  opacity: 0.2;
}
.ph-duotone.ph-clipboard-text:after {
  content: "\e199";
  margin-left: -1em;
}
.ph-duotone.ph-clock:before {
  content: "\e19a";
  opacity: 0.2;
}
.ph-duotone.ph-clock:after {
  content: "\e19b";
  margin-left: -1em;
}
.ph-duotone.ph-clock-afternoon:before {
  content: "\e19c";
  opacity: 0.2;
}
.ph-duotone.ph-clock-afternoon:after {
  content: "\e19d";
  margin-left: -1em;
}
.ph-duotone.ph-clock-clockwise:before {
  content: "\e19e";
  opacity: 0.2;
}
.ph-duotone.ph-clock-clockwise:after {
  content: "\e19f";
  margin-left: -1em;
}
.ph-duotone.ph-clock-countdown:before {
  content: "\ed2c";
  opacity: 0.2;
}
.ph-duotone.ph-clock-countdown:after {
  content: "\ed2d";
  margin-left: -1em;
}
.ph-duotone.ph-clock-counter-clockwise:before {
  content: "\e1a0";
  opacity: 0.2;
}
.ph-duotone.ph-clock-counter-clockwise:after {
  content: "\e1a1";
  margin-left: -1em;
}
.ph-duotone.ph-clock-user:before {
  content: "\edec";
  opacity: 0.2;
}
.ph-duotone.ph-clock-user:after {
  content: "\eded";
  margin-left: -1em;
}
.ph-duotone.ph-closed-captioning:before {
  content: "\e1a4";
  opacity: 0.2;
}
.ph-duotone.ph-closed-captioning:after {
  content: "\e1a5";
  margin-left: -1em;
}
.ph-duotone.ph-cloud:before {
  content: "\e1aa";
  opacity: 0.2;
}
.ph-duotone.ph-cloud:after {
  content: "\e1ab";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-arrow-down:before {
  content: "\e1ac";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-arrow-down:after {
  content: "\e1ad";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-arrow-up:before {
  content: "\e1ae";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-arrow-up:after {
  content: "\e1af";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-check:before {
  content: "\e1b0";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-check:after {
  content: "\e1b1";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-fog:before {
  content: "\e53c";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-fog:after {
  content: "\e53d";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-lightning:before {
  content: "\e1b2";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-lightning:after {
  content: "\e1b3";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-moon:before {
  content: "\e53e";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-moon:after {
  content: "\e53f";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-rain:before {
  content: "\e1b4";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-rain:after {
  content: "\e1b5";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-slash:before {
  content: "\e1b6";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-slash:after {
  content: "\e1b7";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-snow:before {
  content: "\e1b8";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-snow:after {
  content: "\e1b9";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-sun:before {
  content: "\e540";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-sun:after {
  content: "\e541";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-warning:before {
  content: "\ea98";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-warning:after {
  content: "\ea99";
  margin-left: -1em;
}
.ph-duotone.ph-cloud-x:before {
  content: "\ea96";
  opacity: 0.2;
}
.ph-duotone.ph-cloud-x:after {
  content: "\ea97";
  margin-left: -1em;
}
.ph-duotone.ph-clover:before {
  content: "\edc8";
  opacity: 0.2;
}
.ph-duotone.ph-clover:after {
  content: "\edc9";
  margin-left: -1em;
}
.ph-duotone.ph-club:before {
  content: "\e1ba";
  opacity: 0.2;
}
.ph-duotone.ph-club:after {
  content: "\e1bb";
  margin-left: -1em;
}
.ph-duotone.ph-coat-hanger:before {
  content: "\e7fe";
  opacity: 0.2;
}
.ph-duotone.ph-coat-hanger:after {
  content: "\e7ff";
  margin-left: -1em;
}
.ph-duotone.ph-coda-logo:before {
  content: "\e7ce";
  opacity: 0.2;
}
.ph-duotone.ph-coda-logo:after {
  content: "\e7cf";
  margin-left: -1em;
}
.ph-duotone.ph-code:before {
  content: "\e1bc";
  opacity: 0.2;
}
.ph-duotone.ph-code:after {
  content: "\e1bd";
  margin-left: -1em;
}
.ph-duotone.ph-code-block:before {
  content: "\eafe";
  opacity: 0.2;
}
.ph-duotone.ph-code-block:after {
  content: "\eaff";
  margin-left: -1em;
}
.ph-duotone.ph-code-simple:before {
  content: "\e1be";
  opacity: 0.2;
}
.ph-duotone.ph-code-simple:after {
  content: "\e1bf";
  margin-left: -1em;
}
.ph-duotone.ph-codepen-logo:before {
  content: "\e978";
  opacity: 0.2;
}
.ph-duotone.ph-codepen-logo:after {
  content: "\e979";
  margin-left: -1em;
}
.ph-duotone.ph-codesandbox-logo:before {
  content: "\ea06";
  opacity: 0.2;
}
.ph-duotone.ph-codesandbox-logo:after {
  content: "\ea07";
  margin-left: -1em;
}
.ph-duotone.ph-coffee:before {
  content: "\e1c2";
  opacity: 0.2;
}
.ph-duotone.ph-coffee:after {
  content: "\e1c3";
  margin-left: -1em;
}
.ph-duotone.ph-coffee-bean:before {
  content: "\e1c0";
  opacity: 0.2;
}
.ph-duotone.ph-coffee-bean:after {
  content: "\e1c1";
  margin-left: -1em;
}
.ph-duotone.ph-coin:before {
  content: "\e60e";
  opacity: 0.2;
}
.ph-duotone.ph-coin:after {
  content: "\e60f";
  margin-left: -1em;
}
.ph-duotone.ph-coin-vertical:before {
  content: "\eb48";
  opacity: 0.2;
}
.ph-duotone.ph-coin-vertical:after {
  content: "\eb49";
  margin-left: -1em;
}
.ph-duotone.ph-coins:before {
  content: "\e78e";
  opacity: 0.2;
}
.ph-duotone.ph-coins:after {
  content: "\e78f";
  margin-left: -1em;
}
.ph-duotone.ph-columns:before {
  content: "\e546";
  opacity: 0.2;
}
.ph-duotone.ph-columns:after {
  content: "\e547";
  margin-left: -1em;
}
.ph-duotone.ph-columns-plus-left:before {
  content: "\e544";
  opacity: 0.2;
}
.ph-duotone.ph-columns-plus-left:after {
  content: "\e545";
  margin-left: -1em;
}
.ph-duotone.ph-columns-plus-right:before {
  content: "\e542";
  opacity: 0.2;
}
.ph-duotone.ph-columns-plus-right:after {
  content: "\e543";
  margin-left: -1em;
}
.ph-duotone.ph-command:before {
  content: "\e1c4";
  opacity: 0.2;
}
.ph-duotone.ph-command:after {
  content: "\e1c5";
  margin-left: -1em;
}
.ph-duotone.ph-compass:before {
  content: "\e1c8";
  opacity: 0.2;
}
.ph-duotone.ph-compass:after {
  content: "\e1c9";
  margin-left: -1em;
}
.ph-duotone.ph-compass-rose:before {
  content: "\e1c6";
  opacity: 0.2;
}
.ph-duotone.ph-compass-rose:after {
  content: "\e1c7";
  margin-left: -1em;
}
.ph-duotone.ph-compass-tool:before {
  content: "\ea0e";
  opacity: 0.2;
}
.ph-duotone.ph-compass-tool:after {
  content: "\ea0f";
  margin-left: -1em;
}
.ph-duotone.ph-computer-tower:before {
  content: "\e548";
  opacity: 0.2;
}
.ph-duotone.ph-computer-tower:after {
  content: "\e549";
  margin-left: -1em;
}
.ph-duotone.ph-confetti:before {
  content: "\e81a";
  opacity: 0.2;
}
.ph-duotone.ph-confetti:after {
  content: "\e81b";
  margin-left: -1em;
}
.ph-duotone.ph-contactless-payment:before {
  content: "\ed42";
  opacity: 0.2;
}
.ph-duotone.ph-contactless-payment:after {
  content: "\ed43";
  margin-left: -1em;
}
.ph-duotone.ph-control:before {
  content: "\eca6";
  opacity: 0.2;
}
.ph-duotone.ph-control:after {
  content: "\eca7";
  margin-left: -1em;
}
.ph-duotone.ph-cookie:before {
  content: "\e6ca";
  opacity: 0.2;
}
.ph-duotone.ph-cookie:after {
  content: "\e6cb";
  margin-left: -1em;
}
.ph-duotone.ph-cooking-pot:before {
  content: "\e764";
  opacity: 0.2;
}
.ph-duotone.ph-cooking-pot:after {
  content: "\e765";
  margin-left: -1em;
}
.ph-duotone.ph-copy:before {
  content: "\e1ca";
  opacity: 0.2;
}
.ph-duotone.ph-copy:after {
  content: "\e1cb";
  margin-left: -1em;
}
.ph-duotone.ph-copy-simple:before {
  content: "\e1cc";
  opacity: 0.2;
}
.ph-duotone.ph-copy-simple:after {
  content: "\e1cd";
  margin-left: -1em;
}
.ph-duotone.ph-copyleft:before {
  content: "\e86a";
  opacity: 0.2;
}
.ph-duotone.ph-copyleft:after {
  content: "\e86b";
  margin-left: -1em;
}
.ph-duotone.ph-copyright:before {
  content: "\e54a";
  opacity: 0.2;
}
.ph-duotone.ph-copyright:after {
  content: "\e54b";
  margin-left: -1em;
}
.ph-duotone.ph-corners-in:before {
  content: "\e1ce";
  opacity: 0.2;
}
.ph-duotone.ph-corners-in:after {
  content: "\e1cf";
  margin-left: -1em;
}
.ph-duotone.ph-corners-out:before {
  content: "\e1d0";
  opacity: 0.2;
}
.ph-duotone.ph-corners-out:after {
  content: "\e1d1";
  margin-left: -1em;
}
.ph-duotone.ph-couch:before {
  content: "\e7f6";
  opacity: 0.2;
}
.ph-duotone.ph-couch:after {
  content: "\e7f7";
  margin-left: -1em;
}
.ph-duotone.ph-court-basketball:before {
  content: "\ee36";
  opacity: 0.2;
}
.ph-duotone.ph-court-basketball:after {
  content: "\ee37";
  margin-left: -1em;
}
.ph-duotone.ph-cow:before {
  content: "\eabe";
  opacity: 0.2;
}
.ph-duotone.ph-cow:after {
  content: "\eabf";
  margin-left: -1em;
}
.ph-duotone.ph-cowboy-hat:before {
  content: "\ed12";
  opacity: 0.2;
}
.ph-duotone.ph-cowboy-hat:after {
  content: "\ed13";
  margin-left: -1em;
}
.ph-duotone.ph-cpu:before {
  content: "\e610";
  opacity: 0.2;
}
.ph-duotone.ph-cpu:after {
  content: "\e611";
  margin-left: -1em;
}
.ph-duotone.ph-crane:before {
  content: "\ed48";
  opacity: 0.2;
}
.ph-duotone.ph-crane:after {
  content: "\ed4b";
  margin-left: -1em;
}
.ph-duotone.ph-crane-tower:before {
  content: "\ed49";
  opacity: 0.2;
}
.ph-duotone.ph-crane-tower:after {
  content: "\ed4d";
  margin-left: -1em;
}
.ph-duotone.ph-credit-card:before {
  content: "\e1d2";
  opacity: 0.2;
}
.ph-duotone.ph-credit-card:after {
  content: "\e1d3";
  margin-left: -1em;
}
.ph-duotone.ph-cricket:before {
  content: "\ee12";
  opacity: 0.2;
}
.ph-duotone.ph-cricket:after {
  content: "\ee13";
  margin-left: -1em;
}
.ph-duotone.ph-crop:before {
  content: "\e1d4";
  opacity: 0.2;
}
.ph-duotone.ph-crop:after {
  content: "\e1d5";
  margin-left: -1em;
}
.ph-duotone.ph-cross:before {
  content: "\e8a0";
  opacity: 0.2;
}
.ph-duotone.ph-cross:after {
  content: "\e8a1";
  margin-left: -1em;
}
.ph-duotone.ph-crosshair:before {
  content: "\e1d6";
  opacity: 0.2;
}
.ph-duotone.ph-crosshair:after {
  content: "\e1d7";
  margin-left: -1em;
}
.ph-duotone.ph-crosshair-simple:before {
  content: "\e1d8";
  opacity: 0.2;
}
.ph-duotone.ph-crosshair-simple:after {
  content: "\e1d9";
  margin-left: -1em;
}
.ph-duotone.ph-crown:before {
  content: "\e614";
  opacity: 0.2;
}
.ph-duotone.ph-crown:after {
  content: "\e615";
  margin-left: -1em;
}
.ph-duotone.ph-crown-cross:before {
  content: "\ee5e";
  opacity: 0.2;
}
.ph-duotone.ph-crown-cross:after {
  content: "\ee5f";
  margin-left: -1em;
}
.ph-duotone.ph-crown-simple:before {
  content: "\e616";
  opacity: 0.2;
}
.ph-duotone.ph-crown-simple:after {
  content: "\e617";
  margin-left: -1em;
}
.ph-duotone.ph-cube:before {
  content: "\e1da";
  opacity: 0.2;
}
.ph-duotone.ph-cube:after {
  content: "\e1db";
  margin-left: -1em;
}
.ph-duotone.ph-cube-focus:before {
  content: "\ed0a";
  opacity: 0.2;
}
.ph-duotone.ph-cube-focus:after {
  content: "\ed0b";
  margin-left: -1em;
}
.ph-duotone.ph-cube-transparent:before {
  content: "\ec7c";
  opacity: 0.2;
}
.ph-duotone.ph-cube-transparent:after {
  content: "\ec7d";
  margin-left: -1em;
}
.ph-duotone.ph-currency-btc:before {
  content: "\e618";
  opacity: 0.2;
}
.ph-duotone.ph-currency-btc:after {
  content: "\e619";
  margin-left: -1em;
}
.ph-duotone.ph-currency-circle-dollar:before {
  content: "\e54c";
  opacity: 0.2;
}
.ph-duotone.ph-currency-circle-dollar:after {
  content: "\e54d";
  margin-left: -1em;
}
.ph-duotone.ph-currency-cny:before {
  content: "\e54e";
  opacity: 0.2;
}
.ph-duotone.ph-currency-cny:after {
  content: "\e54f";
  margin-left: -1em;
}
.ph-duotone.ph-currency-dollar:before {
  content: "\e550";
  opacity: 0.2;
}
.ph-duotone.ph-currency-dollar:after {
  content: "\e551";
  margin-left: -1em;
}
.ph-duotone.ph-currency-dollar-simple:before {
  content: "\e552";
  opacity: 0.2;
}
.ph-duotone.ph-currency-dollar-simple:after {
  content: "\e553";
  margin-left: -1em;
}
.ph-duotone.ph-currency-eth:before {
  content: "\eada";
  opacity: 0.2;
}
.ph-duotone.ph-currency-eth:after {
  content: "\eadb";
  margin-left: -1em;
}
.ph-duotone.ph-currency-eur:before {
  content: "\e554";
  opacity: 0.2;
}
.ph-duotone.ph-currency-eur:after {
  content: "\e555";
  margin-left: -1em;
}
.ph-duotone.ph-currency-gbp:before {
  content: "\e556";
  opacity: 0.2;
}
.ph-duotone.ph-currency-gbp:after {
  content: "\e557";
  margin-left: -1em;
}
.ph-duotone.ph-currency-inr:before {
  content: "\e558";
  opacity: 0.2;
}
.ph-duotone.ph-currency-inr:after {
  content: "\e559";
  margin-left: -1em;
}
.ph-duotone.ph-currency-jpy:before {
  content: "\e55a";
  opacity: 0.2;
}
.ph-duotone.ph-currency-jpy:after {
  content: "\e55b";
  margin-left: -1em;
}
.ph-duotone.ph-currency-krw:before {
  content: "\e55c";
  opacity: 0.2;
}
.ph-duotone.ph-currency-krw:after {
  content: "\e55d";
  margin-left: -1em;
}
.ph-duotone.ph-currency-kzt:before {
  content: "\ec4c";
  opacity: 0.2;
}
.ph-duotone.ph-currency-kzt:after {
  content: "\ec4d";
  margin-left: -1em;
}
.ph-duotone.ph-currency-ngn:before {
  content: "\eb52";
  opacity: 0.2;
}
.ph-duotone.ph-currency-ngn:after {
  content: "\eb53";
  margin-left: -1em;
}
.ph-duotone.ph-currency-rub:before {
  content: "\e55e";
  opacity: 0.2;
}
.ph-duotone.ph-currency-rub:after {
  content: "\e55f";
  margin-left: -1em;
}
.ph-duotone.ph-cursor:before {
  content: "\e1dc";
  opacity: 0.2;
}
.ph-duotone.ph-cursor:after {
  content: "\e1dd";
  margin-left: -1em;
}
.ph-duotone.ph-cursor-click:before {
  content: "\e7c8";
  opacity: 0.2;
}
.ph-duotone.ph-cursor-click:after {
  content: "\e7c9";
  margin-left: -1em;
}
.ph-duotone.ph-cursor-text:before {
  content: "\e7d8";
  opacity: 0.2;
}
.ph-duotone.ph-cursor-text:after {
  content: "\e7d9";
  margin-left: -1em;
}
.ph-duotone.ph-cylinder:before {
  content: "\e8fc";
  opacity: 0.2;
}
.ph-duotone.ph-cylinder:after {
  content: "\e8fd";
  margin-left: -1em;
}
.ph-duotone.ph-database:before {
  content: "\e1de";
  opacity: 0.2;
}
.ph-duotone.ph-database:after {
  content: "\e1df";
  margin-left: -1em;
}
.ph-duotone.ph-desk:before {
  content: "\ed16";
  opacity: 0.2;
}
.ph-duotone.ph-desk:after {
  content: "\ed17";
  margin-left: -1em;
}
.ph-duotone.ph-desktop:before {
  content: "\e560";
  opacity: 0.2;
}
.ph-duotone.ph-desktop:after {
  content: "\e561";
  margin-left: -1em;
}
.ph-duotone.ph-desktop-tower:before {
  content: "\e562";
  opacity: 0.2;
}
.ph-duotone.ph-desktop-tower:after {
  content: "\e563";
  margin-left: -1em;
}
.ph-duotone.ph-detective:before {
  content: "\e83e";
  opacity: 0.2;
}
.ph-duotone.ph-detective:after {
  content: "\e83f";
  margin-left: -1em;
}
.ph-duotone.ph-dev-to-logo:before {
  content: "\ed0e";
  opacity: 0.2;
}
.ph-duotone.ph-dev-to-logo:after {
  content: "\ed0f";
  margin-left: -1em;
}
.ph-duotone.ph-device-mobile:before {
  content: "\e1e0";
  opacity: 0.2;
}
.ph-duotone.ph-device-mobile:after {
  content: "\e1e1";
  margin-left: -1em;
}
.ph-duotone.ph-device-mobile-camera:before {
  content: "\e1e2";
  opacity: 0.2;
}
.ph-duotone.ph-device-mobile-camera:after {
  content: "\e1e3";
  margin-left: -1em;
}
.ph-duotone.ph-device-mobile-slash:before {
  content: "\ee46";
  opacity: 0.2;
}
.ph-duotone.ph-device-mobile-slash:after {
  content: "\ee47";
  margin-left: -1em;
}
.ph-duotone.ph-device-mobile-speaker:before {
  content: "\e1e4";
  opacity: 0.2;
}
.ph-duotone.ph-device-mobile-speaker:after {
  content: "\e1e5";
  margin-left: -1em;
}
.ph-duotone.ph-device-rotate:before {
  content: "\edf2";
  opacity: 0.2;
}
.ph-duotone.ph-device-rotate:after {
  content: "\edf3";
  margin-left: -1em;
}
.ph-duotone.ph-device-tablet:before {
  content: "\e1e6";
  opacity: 0.2;
}
.ph-duotone.ph-device-tablet:after {
  content: "\e1e7";
  margin-left: -1em;
}
.ph-duotone.ph-device-tablet-camera:before {
  content: "\e1e8";
  opacity: 0.2;
}
.ph-duotone.ph-device-tablet-camera:after {
  content: "\e1e9";
  margin-left: -1em;
}
.ph-duotone.ph-device-tablet-speaker:before {
  content: "\e1ea";
  opacity: 0.2;
}
.ph-duotone.ph-device-tablet-speaker:after {
  content: "\e1eb";
  margin-left: -1em;
}
.ph-duotone.ph-devices:before {
  content: "\eba4";
  opacity: 0.2;
}
.ph-duotone.ph-devices:after {
  content: "\eba5";
  margin-left: -1em;
}
.ph-duotone.ph-diamond:before {
  content: "\e1ec";
  opacity: 0.2;
}
.ph-duotone.ph-diamond:after {
  content: "\e1ed";
  margin-left: -1em;
}
.ph-duotone.ph-diamonds-four:before {
  content: "\e8f4";
  opacity: 0.2;
}
.ph-duotone.ph-diamonds-four:after {
  content: "\e8f5";
  margin-left: -1em;
}
.ph-duotone.ph-dice-five:before {
  content: "\e1ee";
  opacity: 0.2;
}
.ph-duotone.ph-dice-five:after {
  content: "\e1ef";
  margin-left: -1em;
}
.ph-duotone.ph-dice-four:before {
  content: "\e1f0";
  opacity: 0.2;
}
.ph-duotone.ph-dice-four:after {
  content: "\e1f1";
  margin-left: -1em;
}
.ph-duotone.ph-dice-one:before {
  content: "\e1f2";
  opacity: 0.2;
}
.ph-duotone.ph-dice-one:after {
  content: "\e1f3";
  margin-left: -1em;
}
.ph-duotone.ph-dice-six:before {
  content: "\e1f4";
  opacity: 0.2;
}
.ph-duotone.ph-dice-six:after {
  content: "\e1f5";
  margin-left: -1em;
}
.ph-duotone.ph-dice-three:before {
  content: "\e1f6";
  opacity: 0.2;
}
.ph-duotone.ph-dice-three:after {
  content: "\e1f7";
  margin-left: -1em;
}
.ph-duotone.ph-dice-two:before {
  content: "\e1f8";
  opacity: 0.2;
}
.ph-duotone.ph-dice-two:after {
  content: "\e1f9";
  margin-left: -1em;
}
.ph-duotone.ph-disc:before {
  content: "\e564";
  opacity: 0.2;
}
.ph-duotone.ph-disc:after {
  content: "\e565";
  margin-left: -1em;
}
.ph-duotone.ph-disco-ball:before {
  content: "\ed98";
  opacity: 0.2;
}
.ph-duotone.ph-disco-ball:after {
  content: "\ed99";
  margin-left: -1em;
}
.ph-duotone.ph-discord-logo:before {
  content: "\e61a";
  opacity: 0.2;
}
.ph-duotone.ph-discord-logo:after {
  content: "\e61b";
  margin-left: -1em;
}
.ph-duotone.ph-divide:before {
  content: "\e1fa";
  opacity: 0.2;
}
.ph-duotone.ph-divide:after {
  content: "\e1fb";
  margin-left: -1em;
}
.ph-duotone.ph-dna:before {
  content: "\e924";
  opacity: 0.2;
}
.ph-duotone.ph-dna:after {
  content: "\e925";
  margin-left: -1em;
}
.ph-duotone.ph-dog:before {
  content: "\e74a";
  opacity: 0.2;
}
.ph-duotone.ph-dog:after {
  content: "\e74b";
  margin-left: -1em;
}
.ph-duotone.ph-door:before {
  content: "\e61c";
  opacity: 0.2;
}
.ph-duotone.ph-door:after {
  content: "\e61d";
  margin-left: -1em;
}
.ph-duotone.ph-door-open:before {
  content: "\e7e6";
  opacity: 0.2;
}
.ph-duotone.ph-door-open:after {
  content: "\e7e7";
  margin-left: -1em;
}
.ph-duotone.ph-dot:before {
  content: "\ecde";
  opacity: 0.2;
}
.ph-duotone.ph-dot:after {
  content: "\ecdf";
  margin-left: -1em;
}
.ph-duotone.ph-dot-outline:before {
  content: "\ece0";
  opacity: 0.2;
}
.ph-duotone.ph-dot-outline:after {
  content: "\ece1";
  margin-left: -1em;
}
.ph-duotone.ph-dots-nine:before {
  content: "\e1fc";
  opacity: 0.2;
}
.ph-duotone.ph-dots-nine:after {
  content: "\e1fd";
  margin-left: -1em;
}
.ph-duotone.ph-dots-six:before {
  content: "\e794";
  opacity: 0.2;
}
.ph-duotone.ph-dots-six:after {
  content: "\e795";
  margin-left: -1em;
}
.ph-duotone.ph-dots-six-vertical:before {
  content: "\eae2";
  opacity: 0.2;
}
.ph-duotone.ph-dots-six-vertical:after {
  content: "\eae3";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three:before {
  content: "\e1fe";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three:after {
  content: "\e1ff";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-circle:before {
  content: "\e200";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-circle:after {
  content: "\e201";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-circle-vertical:before {
  content: "\e202";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-circle-vertical:after {
  content: "\e203";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-outline:before {
  content: "\e204";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-outline:after {
  content: "\e205";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-outline-vertical:before {
  content: "\e206";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-outline-vertical:after {
  content: "\e207";
  margin-left: -1em;
}
.ph-duotone.ph-dots-three-vertical:before {
  content: "\e208";
  opacity: 0.2;
}
.ph-duotone.ph-dots-three-vertical:after {
  content: "\e209";
  margin-left: -1em;
}
.ph-duotone.ph-download:before {
  content: "\e20a";
  opacity: 0.2;
}
.ph-duotone.ph-download:after {
  content: "\e20b";
  margin-left: -1em;
}
.ph-duotone.ph-download-simple:before {
  content: "\e20c";
  opacity: 0.2;
}
.ph-duotone.ph-download-simple:after {
  content: "\e20d";
  margin-left: -1em;
}
.ph-duotone.ph-dress:before {
  content: "\ea7e";
  opacity: 0.2;
}
.ph-duotone.ph-dress:after {
  content: "\ea7f";
  margin-left: -1em;
}
.ph-duotone.ph-dresser:before {
  content: "\e94e";
  opacity: 0.2;
}
.ph-duotone.ph-dresser:after {
  content: "\e94f";
  margin-left: -1em;
}
.ph-duotone.ph-dribbble-logo:before {
  content: "\e20e";
  opacity: 0.2;
}
.ph-duotone.ph-dribbble-logo:after {
  content: "\e20f";
  margin-left: -1em;
}
.ph-duotone.ph-drone:before {
  content: "\ed74";
  opacity: 0.2;
}
.ph-duotone.ph-drone:after {
  content: "\ed75";
  margin-left: -1em;
}
.ph-duotone.ph-drop:before {
  content: "\e210";
  opacity: 0.2;
}
.ph-duotone.ph-drop:after {
  content: "\e211";
  margin-left: -1em;
}
.ph-duotone.ph-drop-half:before {
  content: "\e566";
  opacity: 0.2;
}
.ph-duotone.ph-drop-half:after {
  content: "\e567";
  margin-left: -1em;
}
.ph-duotone.ph-drop-half-bottom:before {
  content: "\eb40";
  opacity: 0.2;
}
.ph-duotone.ph-drop-half-bottom:after {
  content: "\eb41";
  margin-left: -1em;
}
.ph-duotone.ph-drop-simple:before {
  content: "\ee32";
  opacity: 0.2;
}
.ph-duotone.ph-drop-simple:after {
  content: "\ee33";
  margin-left: -1em;
}
.ph-duotone.ph-drop-slash:before {
  content: "\e954";
  opacity: 0.2;
}
.ph-duotone.ph-drop-slash:after {
  content: "\e955";
  margin-left: -1em;
}
.ph-duotone.ph-dropbox-logo:before {
  content: "\e7d0";
  opacity: 0.2;
}
.ph-duotone.ph-dropbox-logo:after {
  content: "\e7d1";
  margin-left: -1em;
}
.ph-duotone.ph-ear:before {
  content: "\e70c";
  opacity: 0.2;
}
.ph-duotone.ph-ear:after {
  content: "\e70d";
  margin-left: -1em;
}
.ph-duotone.ph-ear-slash:before {
  content: "\e70e";
  opacity: 0.2;
}
.ph-duotone.ph-ear-slash:after {
  content: "\e70f";
  margin-left: -1em;
}
.ph-duotone.ph-egg:before {
  content: "\e812";
  opacity: 0.2;
}
.ph-duotone.ph-egg:after {
  content: "\e813";
  margin-left: -1em;
}
.ph-duotone.ph-egg-crack:before {
  content: "\eb64";
  opacity: 0.2;
}
.ph-duotone.ph-egg-crack:after {
  content: "\eb65";
  margin-left: -1em;
}
.ph-duotone.ph-eject:before {
  content: "\e212";
  opacity: 0.2;
}
.ph-duotone.ph-eject:after {
  content: "\e213";
  margin-left: -1em;
}
.ph-duotone.ph-eject-simple:before {
  content: "\e6ae";
  opacity: 0.2;
}
.ph-duotone.ph-eject-simple:after {
  content: "\e6af";
  margin-left: -1em;
}
.ph-duotone.ph-elevator:before {
  content: "\ecc0";
  opacity: 0.2;
}
.ph-duotone.ph-elevator:after {
  content: "\ecc1";
  margin-left: -1em;
}
.ph-duotone.ph-empty:before {
  content: "\edbc";
  opacity: 0.2;
}
.ph-duotone.ph-empty:after {
  content: "\edbd";
  margin-left: -1em;
}
.ph-duotone.ph-engine:before {
  content: "\ea80";
  opacity: 0.2;
}
.ph-duotone.ph-engine:after {
  content: "\ea81";
  margin-left: -1em;
}
.ph-duotone.ph-envelope:before {
  content: "\e214";
  opacity: 0.2;
}
.ph-duotone.ph-envelope:after {
  content: "\e215";
  margin-left: -1em;
}
.ph-duotone.ph-envelope-open:before {
  content: "\e216";
  opacity: 0.2;
}
.ph-duotone.ph-envelope-open:after {
  content: "\e217";
  margin-left: -1em;
}
.ph-duotone.ph-envelope-simple:before {
  content: "\e218";
  opacity: 0.2;
}
.ph-duotone.ph-envelope-simple:after {
  content: "\e219";
  margin-left: -1em;
}
.ph-duotone.ph-envelope-simple-open:before {
  content: "\e21a";
  opacity: 0.2;
}
.ph-duotone.ph-envelope-simple-open:after {
  content: "\e21b";
  margin-left: -1em;
}
.ph-duotone.ph-equalizer:before {
  content: "\ebbc";
  opacity: 0.2;
}
.ph-duotone.ph-equalizer:after {
  content: "\ebbd";
  margin-left: -1em;
}
.ph-duotone.ph-equals:before {
  content: "\e21c";
  opacity: 0.2;
}
.ph-duotone.ph-equals:after {
  content: "\e21d";
  margin-left: -1em;
}
.ph-duotone.ph-eraser:before {
  content: "\e21e";
  opacity: 0.2;
}
.ph-duotone.ph-eraser:after {
  content: "\e21f";
  margin-left: -1em;
}
.ph-duotone.ph-escalator-down:before {
  content: "\ecba";
  opacity: 0.2;
}
.ph-duotone.ph-escalator-down:after {
  content: "\ecbb";
  margin-left: -1em;
}
.ph-duotone.ph-escalator-up:before {
  content: "\ecbc";
  opacity: 0.2;
}
.ph-duotone.ph-escalator-up:after {
  content: "\ecbd";
  margin-left: -1em;
}
.ph-duotone.ph-exam:before {
  content: "\e742";
  opacity: 0.2;
}
.ph-duotone.ph-exam:after {
  content: "\e743";
  margin-left: -1em;
}
.ph-duotone.ph-exclamation-mark:before {
  content: "\ee44";
  opacity: 0.2;
}
.ph-duotone.ph-exclamation-mark:after {
  content: "\ee45";
  margin-left: -1em;
}
.ph-duotone.ph-exclude:before {
  content: "\e882";
  opacity: 0.2;
}
.ph-duotone.ph-exclude:after {
  content: "\e883";
  margin-left: -1em;
}
.ph-duotone.ph-exclude-square:before {
  content: "\e880";
  opacity: 0.2;
}
.ph-duotone.ph-exclude-square:after {
  content: "\e881";
  margin-left: -1em;
}
.ph-duotone.ph-export:before {
  content: "\eaf0";
  opacity: 0.2;
}
.ph-duotone.ph-export:after {
  content: "\eaf1";
  margin-left: -1em;
}
.ph-duotone.ph-eye:before {
  content: "\e220";
  opacity: 0.2;
}
.ph-duotone.ph-eye:after {
  content: "\e221";
  margin-left: -1em;
}
.ph-duotone.ph-eye-closed:before {
  content: "\e222";
  opacity: 0.2;
}
.ph-duotone.ph-eye-closed:after {
  content: "\e223";
  margin-left: -1em;
}
.ph-duotone.ph-eye-slash:before {
  content: "\e224";
  opacity: 0.2;
}
.ph-duotone.ph-eye-slash:after {
  content: "\e225";
  margin-left: -1em;
}
.ph-duotone.ph-eyedropper:before {
  content: "\e568";
  opacity: 0.2;
}
.ph-duotone.ph-eyedropper:after {
  content: "\e569";
  margin-left: -1em;
}
.ph-duotone.ph-eyedropper-sample:before {
  content: "\eac4";
  opacity: 0.2;
}
.ph-duotone.ph-eyedropper-sample:after {
  content: "\eac5";
  margin-left: -1em;
}
.ph-duotone.ph-eyeglasses:before {
  content: "\e7ba";
  opacity: 0.2;
}
.ph-duotone.ph-eyeglasses:after {
  content: "\e7bb";
  margin-left: -1em;
}
.ph-duotone.ph-eyes:before {
  content: "\ee5c";
  opacity: 0.2;
}
.ph-duotone.ph-eyes:after {
  content: "\ee5d";
  margin-left: -1em;
}
.ph-duotone.ph-face-mask:before {
  content: "\e56a";
  opacity: 0.2;
}
.ph-duotone.ph-face-mask:after {
  content: "\e56b";
  margin-left: -1em;
}
.ph-duotone.ph-facebook-logo:before {
  content: "\e226";
  opacity: 0.2;
}
.ph-duotone.ph-facebook-logo:after {
  content: "\e227";
  margin-left: -1em;
}
.ph-duotone.ph-factory:before {
  content: "\e760";
  opacity: 0.2;
}
.ph-duotone.ph-factory:after {
  content: "\e761";
  margin-left: -1em;
}
.ph-duotone.ph-faders:before {
  content: "\e228";
  opacity: 0.2;
}
.ph-duotone.ph-faders:after {
  content: "\e229";
  margin-left: -1em;
}
.ph-duotone.ph-faders-horizontal:before {
  content: "\e22a";
  opacity: 0.2;
}
.ph-duotone.ph-faders-horizontal:after {
  content: "\e22b";
  margin-left: -1em;
}
.ph-duotone.ph-fallout-shelter:before {
  content: "\e9de";
  opacity: 0.2;
}
.ph-duotone.ph-fallout-shelter:after {
  content: "\e9df";
  margin-left: -1em;
}
.ph-duotone.ph-fan:before {
  content: "\e9f2";
  opacity: 0.2;
}
.ph-duotone.ph-fan:after {
  content: "\e9f3";
  margin-left: -1em;
}
.ph-duotone.ph-farm:before {
  content: "\ec70";
  opacity: 0.2;
}
.ph-duotone.ph-farm:after {
  content: "\ec71";
  margin-left: -1em;
}
.ph-duotone.ph-fast-forward:before {
  content: "\e6a6";
  opacity: 0.2;
}
.ph-duotone.ph-fast-forward:after {
  content: "\e6a7";
  margin-left: -1em;
}
.ph-duotone.ph-fast-forward-circle:before {
  content: "\e22c";
  opacity: 0.2;
}
.ph-duotone.ph-fast-forward-circle:after {
  content: "\e22d";
  margin-left: -1em;
}
.ph-duotone.ph-feather:before {
  content: "\e9c0";
  opacity: 0.2;
}
.ph-duotone.ph-feather:after {
  content: "\e9c1";
  margin-left: -1em;
}
.ph-duotone.ph-fediverse-logo:before {
  content: "\ed66";
  opacity: 0.2;
}
.ph-duotone.ph-fediverse-logo:after {
  content: "\ed67";
  margin-left: -1em;
}
.ph-duotone.ph-figma-logo:before {
  content: "\e22e";
  opacity: 0.2;
}
.ph-duotone.ph-figma-logo:after {
  content: "\e22f";
  margin-left: -1em;
}
.ph-duotone.ph-file:before {
  content: "\e230";
  opacity: 0.2;
}
.ph-duotone.ph-file:after {
  content: "\e231";
  margin-left: -1em;
}
.ph-duotone.ph-file-archive:before {
  content: "\eb2a";
  opacity: 0.2;
}
.ph-duotone.ph-file-archive:after {
  content: "\eb2b";
  margin-left: -1em;
}
.ph-duotone.ph-file-arrow-down:before {
  content: "\e232";
  opacity: 0.2;
}
.ph-duotone.ph-file-arrow-down:after {
  content: "\e233";
  margin-left: -1em;
}
.ph-duotone.ph-file-arrow-up:before {
  content: "\e61e";
  opacity: 0.2;
}
.ph-duotone.ph-file-arrow-up:after {
  content: "\e61f";
  margin-left: -1em;
}
.ph-duotone.ph-file-audio:before {
  content: "\ea20";
  opacity: 0.2;
}
.ph-duotone.ph-file-audio:after {
  content: "\ea21";
  margin-left: -1em;
}
.ph-duotone.ph-file-c:before {
  content: "\eb32";
  opacity: 0.2;
}
.ph-duotone.ph-file-c:after {
  content: "\eb36";
  margin-left: -1em;
}
.ph-duotone.ph-file-c-sharp:before {
  content: "\eb30";
  opacity: 0.2;
}
.ph-duotone.ph-file-c-sharp:after {
  content: "\eb31";
  margin-left: -1em;
}
.ph-duotone.ph-file-cloud:before {
  content: "\e95e";
  opacity: 0.2;
}
.ph-duotone.ph-file-cloud:after {
  content: "\e95f";
  margin-left: -1em;
}
.ph-duotone.ph-file-code:before {
  content: "\e914";
  opacity: 0.2;
}
.ph-duotone.ph-file-code:after {
  content: "\e915";
  margin-left: -1em;
}
.ph-duotone.ph-file-cpp:before {
  content: "\eb2e";
  opacity: 0.2;
}
.ph-duotone.ph-file-cpp:after {
  content: "\eb2f";
  margin-left: -1em;
}
.ph-duotone.ph-file-css:before {
  content: "\eb34";
  opacity: 0.2;
}
.ph-duotone.ph-file-css:after {
  content: "\eb37";
  margin-left: -1em;
}
.ph-duotone.ph-file-csv:before {
  content: "\eb1c";
  opacity: 0.2;
}
.ph-duotone.ph-file-csv:after {
  content: "\eb1d";
  margin-left: -1em;
}
.ph-duotone.ph-file-dashed:before {
  content: "\e704";
  opacity: 0.2;
}
.ph-duotone.ph-file-dashed:after {
  content: "\e705";
  margin-left: -1em;
}
.ph-duotone.ph-file-doc:before {
  content: "\eb1e";
  opacity: 0.2;
}
.ph-duotone.ph-file-doc:after {
  content: "\eb1f";
  margin-left: -1em;
}
.ph-duotone.ph-file-html:before {
  content: "\eb38";
  opacity: 0.2;
}
.ph-duotone.ph-file-html:after {
  content: "\eb39";
  margin-left: -1em;
}
.ph-duotone.ph-file-image:before {
  content: "\ea24";
  opacity: 0.2;
}
.ph-duotone.ph-file-image:after {
  content: "\ea25";
  margin-left: -1em;
}
.ph-duotone.ph-file-ini:before {
  content: "\eb33";
  opacity: 0.2;
}
.ph-duotone.ph-file-ini:after {
  content: "\eb3b";
  margin-left: -1em;
}
.ph-duotone.ph-file-jpg:before {
  content: "\eb1a";
  opacity: 0.2;
}
.ph-duotone.ph-file-jpg:after {
  content: "\eb1b";
  margin-left: -1em;
}
.ph-duotone.ph-file-js:before {
  content: "\eb24";
  opacity: 0.2;
}
.ph-duotone.ph-file-js:after {
  content: "\eb25";
  margin-left: -1em;
}
.ph-duotone.ph-file-jsx:before {
  content: "\eb3a";
  opacity: 0.2;
}
.ph-duotone.ph-file-jsx:after {
  content: "\eb3d";
  margin-left: -1em;
}
.ph-duotone.ph-file-lock:before {
  content: "\e95c";
  opacity: 0.2;
}
.ph-duotone.ph-file-lock:after {
  content: "\e95d";
  margin-left: -1em;
}
.ph-duotone.ph-file-magnifying-glass:before {
  content: "\e238";
  opacity: 0.2;
}
.ph-duotone.ph-file-magnifying-glass:after {
  content: "\e239";
  margin-left: -1em;
}
.ph-duotone.ph-file-md:before {
  content: "\ed50";
  opacity: 0.2;
}
.ph-duotone.ph-file-md:after {
  content: "\ed51";
  margin-left: -1em;
}
.ph-duotone.ph-file-minus:before {
  content: "\e234";
  opacity: 0.2;
}
.ph-duotone.ph-file-minus:after {
  content: "\e235";
  margin-left: -1em;
}
.ph-duotone.ph-file-pdf:before {
  content: "\e702";
  opacity: 0.2;
}
.ph-duotone.ph-file-pdf:after {
  content: "\e703";
  margin-left: -1em;
}
.ph-duotone.ph-file-plus:before {
  content: "\e236";
  opacity: 0.2;
}
.ph-duotone.ph-file-plus:after {
  content: "\e237";
  margin-left: -1em;
}
.ph-duotone.ph-file-png:before {
  content: "\eb18";
  opacity: 0.2;
}
.ph-duotone.ph-file-png:after {
  content: "\eb19";
  margin-left: -1em;
}
.ph-duotone.ph-file-ppt:before {
  content: "\eb20";
  opacity: 0.2;
}
.ph-duotone.ph-file-ppt:after {
  content: "\eb21";
  margin-left: -1em;
}
.ph-duotone.ph-file-py:before {
  content: "\eb2c";
  opacity: 0.2;
}
.ph-duotone.ph-file-py:after {
  content: "\eb2d";
  margin-left: -1em;
}
.ph-duotone.ph-file-rs:before {
  content: "\eb28";
  opacity: 0.2;
}
.ph-duotone.ph-file-rs:after {
  content: "\eb29";
  margin-left: -1em;
}
.ph-duotone.ph-file-sql:before {
  content: "\ed4e";
  opacity: 0.2;
}
.ph-duotone.ph-file-sql:after {
  content: "\ed4f";
  margin-left: -1em;
}
.ph-duotone.ph-file-svg:before {
  content: "\ed08";
  opacity: 0.2;
}
.ph-duotone.ph-file-svg:after {
  content: "\ed09";
  margin-left: -1em;
}
.ph-duotone.ph-file-text:before {
  content: "\e23a";
  opacity: 0.2;
}
.ph-duotone.ph-file-text:after {
  content: "\e23b";
  margin-left: -1em;
}
.ph-duotone.ph-file-ts:before {
  content: "\eb26";
  opacity: 0.2;
}
.ph-duotone.ph-file-ts:after {
  content: "\eb27";
  margin-left: -1em;
}
.ph-duotone.ph-file-tsx:before {
  content: "\eb3c";
  opacity: 0.2;
}
.ph-duotone.ph-file-tsx:after {
  content: "\eb3f";
  margin-left: -1em;
}
.ph-duotone.ph-file-txt:before {
  content: "\eb35";
  opacity: 0.2;
}
.ph-duotone.ph-file-txt:after {
  content: "\eb43";
  margin-left: -1em;
}
.ph-duotone.ph-file-video:before {
  content: "\ea22";
  opacity: 0.2;
}
.ph-duotone.ph-file-video:after {
  content: "\ea23";
  margin-left: -1em;
}
.ph-duotone.ph-file-vue:before {
  content: "\eb3e";
  opacity: 0.2;
}
.ph-duotone.ph-file-vue:after {
  content: "\eb47";
  margin-left: -1em;
}
.ph-duotone.ph-file-x:before {
  content: "\e23c";
  opacity: 0.2;
}
.ph-duotone.ph-file-x:after {
  content: "\e23d";
  margin-left: -1em;
}
.ph-duotone.ph-file-xls:before {
  content: "\eb22";
  opacity: 0.2;
}
.ph-duotone.ph-file-xls:after {
  content: "\eb23";
  margin-left: -1em;
}
.ph-duotone.ph-file-zip:before {
  content: "\e958";
  opacity: 0.2;
}
.ph-duotone.ph-file-zip:after {
  content: "\e959";
  margin-left: -1em;
}
.ph-duotone.ph-files:before {
  content: "\e710";
  opacity: 0.2;
}
.ph-duotone.ph-files:after {
  content: "\e711";
  margin-left: -1em;
}
.ph-duotone.ph-film-reel:before {
  content: "\e8c0";
  opacity: 0.2;
}
.ph-duotone.ph-film-reel:after {
  content: "\e8c1";
  margin-left: -1em;
}
.ph-duotone.ph-film-script:before {
  content: "\eb50";
  opacity: 0.2;
}
.ph-duotone.ph-film-script:after {
  content: "\eb51";
  margin-left: -1em;
}
.ph-duotone.ph-film-slate:before {
  content: "\e8c2";
  opacity: 0.2;
}
.ph-duotone.ph-film-slate:after {
  content: "\e8c3";
  margin-left: -1em;
}
.ph-duotone.ph-film-strip:before {
  content: "\e792";
  opacity: 0.2;
}
.ph-duotone.ph-film-strip:after {
  content: "\e793";
  margin-left: -1em;
}
.ph-duotone.ph-fingerprint:before {
  content: "\e23e";
  opacity: 0.2;
}
.ph-duotone.ph-fingerprint:after {
  content: "\e23f";
  margin-left: -1em;
}
.ph-duotone.ph-fingerprint-simple:before {
  content: "\e240";
  opacity: 0.2;
}
.ph-duotone.ph-fingerprint-simple:after {
  content: "\e241";
  margin-left: -1em;
}
.ph-duotone.ph-finn-the-human:before {
  content: "\e56c";
  opacity: 0.2;
}
.ph-duotone.ph-finn-the-human:after {
  content: "\e56d";
  margin-left: -1em;
}
.ph-duotone.ph-fire:before {
  content: "\e242";
  opacity: 0.2;
}
.ph-duotone.ph-fire:after {
  content: "\e243";
  margin-left: -1em;
}
.ph-duotone.ph-fire-extinguisher:before {
  content: "\e9e8";
  opacity: 0.2;
}
.ph-duotone.ph-fire-extinguisher:after {
  content: "\e9e9";
  margin-left: -1em;
}
.ph-duotone.ph-fire-simple:before {
  content: "\e620";
  opacity: 0.2;
}
.ph-duotone.ph-fire-simple:after {
  content: "\e621";
  margin-left: -1em;
}
.ph-duotone.ph-fire-truck:before {
  content: "\e574";
  opacity: 0.2;
}
.ph-duotone.ph-fire-truck:after {
  content: "\e575";
  margin-left: -1em;
}
.ph-duotone.ph-first-aid:before {
  content: "\e56e";
  opacity: 0.2;
}
.ph-duotone.ph-first-aid:after {
  content: "\e56f";
  margin-left: -1em;
}
.ph-duotone.ph-first-aid-kit:before {
  content: "\e570";
  opacity: 0.2;
}
.ph-duotone.ph-first-aid-kit:after {
  content: "\e571";
  margin-left: -1em;
}
.ph-duotone.ph-fish:before {
  content: "\e728";
  opacity: 0.2;
}
.ph-duotone.ph-fish:after {
  content: "\e729";
  margin-left: -1em;
}
.ph-duotone.ph-fish-simple:before {
  content: "\e72a";
  opacity: 0.2;
}
.ph-duotone.ph-fish-simple:after {
  content: "\e72b";
  margin-left: -1em;
}
.ph-duotone.ph-flag:before {
  content: "\e244";
  opacity: 0.2;
}
.ph-duotone.ph-flag:after {
  content: "\e245";
  margin-left: -1em;
}
.ph-duotone.ph-flag-banner:before {
  content: "\e622";
  opacity: 0.2;
}
.ph-duotone.ph-flag-banner:after {
  content: "\e623";
  margin-left: -1em;
}
.ph-duotone.ph-flag-banner-fold:before {
  content: "\ecf2";
  opacity: 0.2;
}
.ph-duotone.ph-flag-banner-fold:after {
  content: "\ecf3";
  margin-left: -1em;
}
.ph-duotone.ph-flag-checkered:before {
  content: "\ea38";
  opacity: 0.2;
}
.ph-duotone.ph-flag-checkered:after {
  content: "\ea39";
  margin-left: -1em;
}
.ph-duotone.ph-flag-pennant:before {
  content: "\ecf0";
  opacity: 0.2;
}
.ph-duotone.ph-flag-pennant:after {
  content: "\ecf1";
  margin-left: -1em;
}
.ph-duotone.ph-flame:before {
  content: "\e624";
  opacity: 0.2;
}
.ph-duotone.ph-flame:after {
  content: "\e625";
  margin-left: -1em;
}
.ph-duotone.ph-flashlight:before {
  content: "\e246";
  opacity: 0.2;
}
.ph-duotone.ph-flashlight:after {
  content: "\e247";
  margin-left: -1em;
}
.ph-duotone.ph-flask:before {
  content: "\e79e";
  opacity: 0.2;
}
.ph-duotone.ph-flask:after {
  content: "\e79f";
  margin-left: -1em;
}
.ph-duotone.ph-flip-horizontal:before {
  content: "\ed6a";
  opacity: 0.2;
}
.ph-duotone.ph-flip-horizontal:after {
  content: "\ed6b";
  margin-left: -1em;
}
.ph-duotone.ph-flip-vertical:before {
  content: "\ed6c";
  opacity: 0.2;
}
.ph-duotone.ph-flip-vertical:after {
  content: "\ed6d";
  margin-left: -1em;
}
.ph-duotone.ph-floppy-disk:before {
  content: "\e248";
  opacity: 0.2;
}
.ph-duotone.ph-floppy-disk:after {
  content: "\e249";
  margin-left: -1em;
}
.ph-duotone.ph-floppy-disk-back:before {
  content: "\eaf4";
  opacity: 0.2;
}
.ph-duotone.ph-floppy-disk-back:after {
  content: "\eaf5";
  margin-left: -1em;
}
.ph-duotone.ph-flow-arrow:before {
  content: "\e6ec";
  opacity: 0.2;
}
.ph-duotone.ph-flow-arrow:after {
  content: "\e6ed";
  margin-left: -1em;
}
.ph-duotone.ph-flower:before {
  content: "\e75e";
  opacity: 0.2;
}
.ph-duotone.ph-flower:after {
  content: "\e75f";
  margin-left: -1em;
}
.ph-duotone.ph-flower-lotus:before {
  content: "\e6cc";
  opacity: 0.2;
}
.ph-duotone.ph-flower-lotus:after {
  content: "\e6cd";
  margin-left: -1em;
}
.ph-duotone.ph-flower-tulip:before {
  content: "\eacc";
  opacity: 0.2;
}
.ph-duotone.ph-flower-tulip:after {
  content: "\eacd";
  margin-left: -1em;
}
.ph-duotone.ph-flying-saucer:before {
  content: "\eb4a";
  opacity: 0.2;
}
.ph-duotone.ph-flying-saucer:after {
  content: "\eb4b";
  margin-left: -1em;
}
.ph-duotone.ph-folder:before {
  content: "\e24a";
  opacity: 0.2;
}
.ph-duotone.ph-folder:after {
  content: "\e24b";
  margin-left: -1em;
}
.ph-duotone.ph-folder-dashed:before {
  content: "\e8f8";
  opacity: 0.2;
}
.ph-duotone.ph-folder-dashed:after {
  content: "\e8f9";
  margin-left: -1em;
}
.ph-duotone.ph-folder-lock:before {
  content: "\ea3c";
  opacity: 0.2;
}
.ph-duotone.ph-folder-lock:after {
  content: "\ea3d";
  margin-left: -1em;
}
.ph-duotone.ph-folder-minus:before {
  content: "\e254";
  opacity: 0.2;
}
.ph-duotone.ph-folder-minus:after {
  content: "\e255";
  margin-left: -1em;
}
.ph-duotone.ph-folder-open:before {
  content: "\e256";
  opacity: 0.2;
}
.ph-duotone.ph-folder-open:after {
  content: "\e257";
  margin-left: -1em;
}
.ph-duotone.ph-folder-plus:before {
  content: "\e258";
  opacity: 0.2;
}
.ph-duotone.ph-folder-plus:after {
  content: "\e259";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple:before {
  content: "\e25a";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple:after {
  content: "\e25b";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-dashed:before {
  content: "\ec2a";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-dashed:after {
  content: "\ec2b";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-lock:before {
  content: "\eb5e";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-lock:after {
  content: "\eb5f";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-minus:before {
  content: "\e25c";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-minus:after {
  content: "\e25d";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-plus:before {
  content: "\e25e";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-plus:after {
  content: "\e25f";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-star:before {
  content: "\ec2e";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-star:after {
  content: "\ec2f";
  margin-left: -1em;
}
.ph-duotone.ph-folder-simple-user:before {
  content: "\eb60";
  opacity: 0.2;
}
.ph-duotone.ph-folder-simple-user:after {
  content: "\eb61";
  margin-left: -1em;
}
.ph-duotone.ph-folder-star:before {
  content: "\ea86";
  opacity: 0.2;
}
.ph-duotone.ph-folder-star:after {
  content: "\ea87";
  margin-left: -1em;
}
.ph-duotone.ph-folder-user:before {
  content: "\eb46";
  opacity: 0.2;
}
.ph-duotone.ph-folder-user:after {
  content: "\eb4c";
  margin-left: -1em;
}
.ph-duotone.ph-folders:before {
  content: "\e260";
  opacity: 0.2;
}
.ph-duotone.ph-folders:after {
  content: "\e261";
  margin-left: -1em;
}
.ph-duotone.ph-football:before {
  content: "\e718";
  opacity: 0.2;
}
.ph-duotone.ph-football:after {
  content: "\e719";
  margin-left: -1em;
}
.ph-duotone.ph-football-helmet:before {
  content: "\ee4c";
  opacity: 0.2;
}
.ph-duotone.ph-football-helmet:after {
  content: "\ee4d";
  margin-left: -1em;
}
.ph-duotone.ph-footprints:before {
  content: "\ea88";
  opacity: 0.2;
}
.ph-duotone.ph-footprints:after {
  content: "\ea89";
  margin-left: -1em;
}
.ph-duotone.ph-fork-knife:before {
  content: "\e262";
  opacity: 0.2;
}
.ph-duotone.ph-fork-knife:after {
  content: "\e263";
  margin-left: -1em;
}
.ph-duotone.ph-four-k:before {
  content: "\ea5c";
  opacity: 0.2;
}
.ph-duotone.ph-four-k:after {
  content: "\ea5d";
  margin-left: -1em;
}
.ph-duotone.ph-frame-corners:before {
  content: "\e626";
  opacity: 0.2;
}
.ph-duotone.ph-frame-corners:after {
  content: "\e627";
  margin-left: -1em;
}
.ph-duotone.ph-framer-logo:before {
  content: "\e264";
  opacity: 0.2;
}
.ph-duotone.ph-framer-logo:after {
  content: "\e265";
  margin-left: -1em;
}
.ph-duotone.ph-function:before {
  content: "\ebe4";
  opacity: 0.2;
}
.ph-duotone.ph-function:after {
  content: "\ebe5";
  margin-left: -1em;
}
.ph-duotone.ph-funnel:before {
  content: "\e266";
  opacity: 0.2;
}
.ph-duotone.ph-funnel:after {
  content: "\e267";
  margin-left: -1em;
}
.ph-duotone.ph-funnel-simple:before {
  content: "\e268";
  opacity: 0.2;
}
.ph-duotone.ph-funnel-simple:after {
  content: "\e269";
  margin-left: -1em;
}
.ph-duotone.ph-funnel-simple-x:before {
  content: "\e26a";
  opacity: 0.2;
}
.ph-duotone.ph-funnel-simple-x:after {
  content: "\e26b";
  margin-left: -1em;
}
.ph-duotone.ph-funnel-x:before {
  content: "\e26c";
  opacity: 0.2;
}
.ph-duotone.ph-funnel-x:after {
  content: "\e26d";
  margin-left: -1em;
}
.ph-duotone.ph-game-controller:before {
  content: "\e26e";
  opacity: 0.2;
}
.ph-duotone.ph-game-controller:after {
  content: "\e26f";
  margin-left: -1em;
}
.ph-duotone.ph-garage:before {
  content: "\ecd6";
  opacity: 0.2;
}
.ph-duotone.ph-garage:after {
  content: "\ecd7";
  margin-left: -1em;
}
.ph-duotone.ph-gas-can:before {
  content: "\e8ce";
  opacity: 0.2;
}
.ph-duotone.ph-gas-can:after {
  content: "\e8cf";
  margin-left: -1em;
}
.ph-duotone.ph-gas-pump:before {
  content: "\e768";
  opacity: 0.2;
}
.ph-duotone.ph-gas-pump:after {
  content: "\e769";
  margin-left: -1em;
}
.ph-duotone.ph-gauge:before {
  content: "\e628";
  opacity: 0.2;
}
.ph-duotone.ph-gauge:after {
  content: "\e629";
  margin-left: -1em;
}
.ph-duotone.ph-gavel:before {
  content: "\ea32";
  opacity: 0.2;
}
.ph-duotone.ph-gavel:after {
  content: "\ea33";
  margin-left: -1em;
}
.ph-duotone.ph-gear:before {
  content: "\e270";
  opacity: 0.2;
}
.ph-duotone.ph-gear:after {
  content: "\e271";
  margin-left: -1em;
}
.ph-duotone.ph-gear-fine:before {
  content: "\e87c";
  opacity: 0.2;
}
.ph-duotone.ph-gear-fine:after {
  content: "\e87d";
  margin-left: -1em;
}
.ph-duotone.ph-gear-six:before {
  content: "\e272";
  opacity: 0.2;
}
.ph-duotone.ph-gear-six:after {
  content: "\e273";
  margin-left: -1em;
}
.ph-duotone.ph-gender-female:before {
  content: "\e6e0";
  opacity: 0.2;
}
.ph-duotone.ph-gender-female:after {
  content: "\e6e1";
  margin-left: -1em;
}
.ph-duotone.ph-gender-intersex:before {
  content: "\e6e6";
  opacity: 0.2;
}
.ph-duotone.ph-gender-intersex:after {
  content: "\e6e7";
  margin-left: -1em;
}
.ph-duotone.ph-gender-male:before {
  content: "\e6e2";
  opacity: 0.2;
}
.ph-duotone.ph-gender-male:after {
  content: "\e6e3";
  margin-left: -1em;
}
.ph-duotone.ph-gender-neuter:before {
  content: "\e6ea";
  opacity: 0.2;
}
.ph-duotone.ph-gender-neuter:after {
  content: "\e6eb";
  margin-left: -1em;
}
.ph-duotone.ph-gender-nonbinary:before {
  content: "\e6e4";
  opacity: 0.2;
}
.ph-duotone.ph-gender-nonbinary:after {
  content: "\e6e5";
  margin-left: -1em;
}
.ph-duotone.ph-gender-transgender:before {
  content: "\e6e8";
  opacity: 0.2;
}
.ph-duotone.ph-gender-transgender:after {
  content: "\e6e9";
  margin-left: -1em;
}
.ph-duotone.ph-ghost:before {
  content: "\e62a";
  opacity: 0.2;
}
.ph-duotone.ph-ghost:after {
  content: "\e62b";
  margin-left: -1em;
}
.ph-duotone.ph-gif:before {
  content: "\e274";
  opacity: 0.2;
}
.ph-duotone.ph-gif:after {
  content: "\e275";
  margin-left: -1em;
}
.ph-duotone.ph-gift:before {
  content: "\e276";
  opacity: 0.2;
}
.ph-duotone.ph-gift:after {
  content: "\e277";
  margin-left: -1em;
}
.ph-duotone.ph-git-branch:before {
  content: "\e278";
  opacity: 0.2;
}
.ph-duotone.ph-git-branch:after {
  content: "\e279";
  margin-left: -1em;
}
.ph-duotone.ph-git-commit:before {
  content: "\e27a";
  opacity: 0.2;
}
.ph-duotone.ph-git-commit:after {
  content: "\e27b";
  margin-left: -1em;
}
.ph-duotone.ph-git-diff:before {
  content: "\e27c";
  opacity: 0.2;
}
.ph-duotone.ph-git-diff:after {
  content: "\e27d";
  margin-left: -1em;
}
.ph-duotone.ph-git-fork:before {
  content: "\e27e";
  opacity: 0.2;
}
.ph-duotone.ph-git-fork:after {
  content: "\e27f";
  margin-left: -1em;
}
.ph-duotone.ph-git-merge:before {
  content: "\e280";
  opacity: 0.2;
}
.ph-duotone.ph-git-merge:after {
  content: "\e281";
  margin-left: -1em;
}
.ph-duotone.ph-git-pull-request:before {
  content: "\e282";
  opacity: 0.2;
}
.ph-duotone.ph-git-pull-request:after {
  content: "\e283";
  margin-left: -1em;
}
.ph-duotone.ph-github-logo:before {
  content: "\e576";
  opacity: 0.2;
}
.ph-duotone.ph-github-logo:after {
  content: "\e577";
  margin-left: -1em;
}
.ph-duotone.ph-gitlab-logo:before {
  content: "\e694";
  opacity: 0.2;
}
.ph-duotone.ph-gitlab-logo:after {
  content: "\e695";
  margin-left: -1em;
}
.ph-duotone.ph-gitlab-logo-simple:before {
  content: "\e696";
  opacity: 0.2;
}
.ph-duotone.ph-gitlab-logo-simple:after {
  content: "\e697";
  margin-left: -1em;
}
.ph-duotone.ph-globe:before {
  content: "\e288";
  opacity: 0.2;
}
.ph-duotone.ph-globe:after {
  content: "\e289";
  margin-left: -1em;
}
.ph-duotone.ph-globe-hemisphere-east:before {
  content: "\e28a";
  opacity: 0.2;
}
.ph-duotone.ph-globe-hemisphere-east:after {
  content: "\e28b";
  margin-left: -1em;
}
.ph-duotone.ph-globe-hemisphere-west:before {
  content: "\e28c";
  opacity: 0.2;
}
.ph-duotone.ph-globe-hemisphere-west:after {
  content: "\e28d";
  margin-left: -1em;
}
.ph-duotone.ph-globe-simple:before {
  content: "\e28e";
  opacity: 0.2;
}
.ph-duotone.ph-globe-simple:after {
  content: "\e28f";
  margin-left: -1em;
}
.ph-duotone.ph-globe-simple-x:before {
  content: "\e284";
  opacity: 0.2;
}
.ph-duotone.ph-globe-simple-x:after {
  content: "\e285";
  margin-left: -1em;
}
.ph-duotone.ph-globe-stand:before {
  content: "\e290";
  opacity: 0.2;
}
.ph-duotone.ph-globe-stand:after {
  content: "\e291";
  margin-left: -1em;
}
.ph-duotone.ph-globe-x:before {
  content: "\e286";
  opacity: 0.2;
}
.ph-duotone.ph-globe-x:after {
  content: "\e287";
  margin-left: -1em;
}
.ph-duotone.ph-goggles:before {
  content: "\ecb4";
  opacity: 0.2;
}
.ph-duotone.ph-goggles:after {
  content: "\ecb5";
  margin-left: -1em;
}
.ph-duotone.ph-golf:before {
  content: "\ea3e";
  opacity: 0.2;
}
.ph-duotone.ph-golf:after {
  content: "\ea3f";
  margin-left: -1em;
}
.ph-duotone.ph-goodreads-logo:before {
  content: "\ed10";
  opacity: 0.2;
}
.ph-duotone.ph-goodreads-logo:after {
  content: "\ed11";
  margin-left: -1em;
}
.ph-duotone.ph-google-cardboard-logo:before {
  content: "\e7b6";
  opacity: 0.2;
}
.ph-duotone.ph-google-cardboard-logo:after {
  content: "\e7b7";
  margin-left: -1em;
}
.ph-duotone.ph-google-chrome-logo:before {
  content: "\e976";
  opacity: 0.2;
}
.ph-duotone.ph-google-chrome-logo:after {
  content: "\e977";
  margin-left: -1em;
}
.ph-duotone.ph-google-drive-logo:before {
  content: "\e8f6";
  opacity: 0.2;
}
.ph-duotone.ph-google-drive-logo:after {
  content: "\e8f7";
  margin-left: -1em;
}
.ph-duotone.ph-google-logo:before {
  content: "\e292";
  opacity: 0.2;
}
.ph-duotone.ph-google-logo:after {
  content: "\e293";
  margin-left: -1em;
}
.ph-duotone.ph-google-photos-logo:before {
  content: "\eb92";
  opacity: 0.2;
}
.ph-duotone.ph-google-photos-logo:after {
  content: "\eb93";
  margin-left: -1em;
}
.ph-duotone.ph-google-play-logo:before {
  content: "\e294";
  opacity: 0.2;
}
.ph-duotone.ph-google-play-logo:after {
  content: "\e295";
  margin-left: -1em;
}
.ph-duotone.ph-google-podcasts-logo:before {
  content: "\eb94";
  opacity: 0.2;
}
.ph-duotone.ph-google-podcasts-logo:after {
  content: "\eb95";
  margin-left: -1em;
}
.ph-duotone.ph-gps:before {
  content: "\edd8";
  opacity: 0.2;
}
.ph-duotone.ph-gps:after {
  content: "\edd9";
  margin-left: -1em;
}
.ph-duotone.ph-gps-fix:before {
  content: "\edd6";
  opacity: 0.2;
}
.ph-duotone.ph-gps-fix:after {
  content: "\edd7";
  margin-left: -1em;
}
.ph-duotone.ph-gps-slash:before {
  content: "\edd4";
  opacity: 0.2;
}
.ph-duotone.ph-gps-slash:after {
  content: "\edd5";
  margin-left: -1em;
}
.ph-duotone.ph-gradient:before {
  content: "\eb42";
  opacity: 0.2;
}
.ph-duotone.ph-gradient:after {
  content: "\eb4d";
  margin-left: -1em;
}
.ph-duotone.ph-graduation-cap:before {
  content: "\e62c";
  opacity: 0.2;
}
.ph-duotone.ph-graduation-cap:after {
  content: "\e62d";
  margin-left: -1em;
}
.ph-duotone.ph-grains:before {
  content: "\ec68";
  opacity: 0.2;
}
.ph-duotone.ph-grains:after {
  content: "\ec69";
  margin-left: -1em;
}
.ph-duotone.ph-grains-slash:before {
  content: "\ec6a";
  opacity: 0.2;
}
.ph-duotone.ph-grains-slash:after {
  content: "\ec6b";
  margin-left: -1em;
}
.ph-duotone.ph-graph:before {
  content: "\eb58";
  opacity: 0.2;
}
.ph-duotone.ph-graph:after {
  content: "\eb59";
  margin-left: -1em;
}
.ph-duotone.ph-graphics-card:before {
  content: "\e612";
  opacity: 0.2;
}
.ph-duotone.ph-graphics-card:after {
  content: "\e613";
  margin-left: -1em;
}
.ph-duotone.ph-greater-than:before {
  content: "\edc4";
  opacity: 0.2;
}
.ph-duotone.ph-greater-than:after {
  content: "\edc5";
  margin-left: -1em;
}
.ph-duotone.ph-greater-than-or-equal:before {
  content: "\eda2";
  opacity: 0.2;
}
.ph-duotone.ph-greater-than-or-equal:after {
  content: "\eda3";
  margin-left: -1em;
}
.ph-duotone.ph-grid-four:before {
  content: "\e296";
  opacity: 0.2;
}
.ph-duotone.ph-grid-four:after {
  content: "\e297";
  margin-left: -1em;
}
.ph-duotone.ph-grid-nine:before {
  content: "\ec8c";
  opacity: 0.2;
}
.ph-duotone.ph-grid-nine:after {
  content: "\ec8d";
  margin-left: -1em;
}
.ph-duotone.ph-guitar:before {
  content: "\ea8a";
  opacity: 0.2;
}
.ph-duotone.ph-guitar:after {
  content: "\ea8b";
  margin-left: -1em;
}
.ph-duotone.ph-hair-dryer:before {
  content: "\ea66";
  opacity: 0.2;
}
.ph-duotone.ph-hair-dryer:after {
  content: "\ea67";
  margin-left: -1em;
}
.ph-duotone.ph-hamburger:before {
  content: "\e790";
  opacity: 0.2;
}
.ph-duotone.ph-hamburger:after {
  content: "\e791";
  margin-left: -1em;
}
.ph-duotone.ph-hammer:before {
  content: "\e80e";
  opacity: 0.2;
}
.ph-duotone.ph-hammer:after {
  content: "\e80f";
  margin-left: -1em;
}
.ph-duotone.ph-hand:before {
  content: "\e298";
  opacity: 0.2;
}
.ph-duotone.ph-hand:after {
  content: "\e299";
  margin-left: -1em;
}
.ph-duotone.ph-hand-arrow-down:before {
  content: "\ea4e";
  opacity: 0.2;
}
.ph-duotone.ph-hand-arrow-down:after {
  content: "\ea4f";
  margin-left: -1em;
}
.ph-duotone.ph-hand-arrow-up:before {
  content: "\ee5a";
  opacity: 0.2;
}
.ph-duotone.ph-hand-arrow-up:after {
  content: "\ee5b";
  margin-left: -1em;
}
.ph-duotone.ph-hand-coins:before {
  content: "\ea8c";
  opacity: 0.2;
}
.ph-duotone.ph-hand-coins:after {
  content: "\ea8d";
  margin-left: -1em;
}
.ph-duotone.ph-hand-deposit:before {
  content: "\ee82";
  opacity: 0.2;
}
.ph-duotone.ph-hand-deposit:after {
  content: "\ee83";
  margin-left: -1em;
}
.ph-duotone.ph-hand-eye:before {
  content: "\ea4c";
  opacity: 0.2;
}
.ph-duotone.ph-hand-eye:after {
  content: "\ea4d";
  margin-left: -1em;
}
.ph-duotone.ph-hand-fist:before {
  content: "\e57a";
  opacity: 0.2;
}
.ph-duotone.ph-hand-fist:after {
  content: "\e57b";
  margin-left: -1em;
}
.ph-duotone.ph-hand-grabbing:before {
  content: "\e57c";
  opacity: 0.2;
}
.ph-duotone.ph-hand-grabbing:after {
  content: "\e57d";
  margin-left: -1em;
}
.ph-duotone.ph-hand-heart:before {
  content: "\e810";
  opacity: 0.2;
}
.ph-duotone.ph-hand-heart:after {
  content: "\e811";
  margin-left: -1em;
}
.ph-duotone.ph-hand-palm:before {
  content: "\e57e";
  opacity: 0.2;
}
.ph-duotone.ph-hand-palm:after {
  content: "\e57f";
  margin-left: -1em;
}
.ph-duotone.ph-hand-peace:before {
  content: "\e7cc";
  opacity: 0.2;
}
.ph-duotone.ph-hand-peace:after {
  content: "\e7cd";
  margin-left: -1em;
}
.ph-duotone.ph-hand-pointing:before {
  content: "\e29a";
  opacity: 0.2;
}
.ph-duotone.ph-hand-pointing:after {
  content: "\e29b";
  margin-left: -1em;
}
.ph-duotone.ph-hand-soap:before {
  content: "\e630";
  opacity: 0.2;
}
.ph-duotone.ph-hand-soap:after {
  content: "\e631";
  margin-left: -1em;
}
.ph-duotone.ph-hand-swipe-left:before {
  content: "\ec94";
  opacity: 0.2;
}
.ph-duotone.ph-hand-swipe-left:after {
  content: "\ec95";
  margin-left: -1em;
}
.ph-duotone.ph-hand-swipe-right:before {
  content: "\ec92";
  opacity: 0.2;
}
.ph-duotone.ph-hand-swipe-right:after {
  content: "\ec93";
  margin-left: -1em;
}
.ph-duotone.ph-hand-tap:before {
  content: "\ec90";
  opacity: 0.2;
}
.ph-duotone.ph-hand-tap:after {
  content: "\ec91";
  margin-left: -1em;
}
.ph-duotone.ph-hand-waving:before {
  content: "\e580";
  opacity: 0.2;
}
.ph-duotone.ph-hand-waving:after {
  content: "\e581";
  margin-left: -1em;
}
.ph-duotone.ph-hand-withdraw:before {
  content: "\ee80";
  opacity: 0.2;
}
.ph-duotone.ph-hand-withdraw:after {
  content: "\ee81";
  margin-left: -1em;
}
.ph-duotone.ph-handbag:before {
  content: "\e29c";
  opacity: 0.2;
}
.ph-duotone.ph-handbag:after {
  content: "\e29d";
  margin-left: -1em;
}
.ph-duotone.ph-handbag-simple:before {
  content: "\e62e";
  opacity: 0.2;
}
.ph-duotone.ph-handbag-simple:after {
  content: "\e62f";
  margin-left: -1em;
}
.ph-duotone.ph-hands-clapping:before {
  content: "\e6a0";
  opacity: 0.2;
}
.ph-duotone.ph-hands-clapping:after {
  content: "\e6a1";
  margin-left: -1em;
}
.ph-duotone.ph-hands-praying:before {
  content: "\ecc8";
  opacity: 0.2;
}
.ph-duotone.ph-hands-praying:after {
  content: "\ecc9";
  margin-left: -1em;
}
.ph-duotone.ph-handshake:before {
  content: "\e582";
  opacity: 0.2;
}
.ph-duotone.ph-handshake:after {
  content: "\e583";
  margin-left: -1em;
}
.ph-duotone.ph-hard-drive:before {
  content: "\e29e";
  opacity: 0.2;
}
.ph-duotone.ph-hard-drive:after {
  content: "\e29f";
  margin-left: -1em;
}
.ph-duotone.ph-hard-drives:before {
  content: "\e2a0";
  opacity: 0.2;
}
.ph-duotone.ph-hard-drives:after {
  content: "\e2a1";
  margin-left: -1em;
}
.ph-duotone.ph-hard-hat:before {
  content: "\ed46";
  opacity: 0.2;
}
.ph-duotone.ph-hard-hat:after {
  content: "\ed47";
  margin-left: -1em;
}
.ph-duotone.ph-hash:before {
  content: "\e2a2";
  opacity: 0.2;
}
.ph-duotone.ph-hash:after {
  content: "\e2a3";
  margin-left: -1em;
}
.ph-duotone.ph-hash-straight:before {
  content: "\e2a4";
  opacity: 0.2;
}
.ph-duotone.ph-hash-straight:after {
  content: "\e2a5";
  margin-left: -1em;
}
.ph-duotone.ph-head-circuit:before {
  content: "\e7d4";
  opacity: 0.2;
}
.ph-duotone.ph-head-circuit:after {
  content: "\e7d5";
  margin-left: -1em;
}
.ph-duotone.ph-headlights:before {
  content: "\e6fe";
  opacity: 0.2;
}
.ph-duotone.ph-headlights:after {
  content: "\e6ff";
  margin-left: -1em;
}
.ph-duotone.ph-headphones:before {
  content: "\e2a6";
  opacity: 0.2;
}
.ph-duotone.ph-headphones:after {
  content: "\e2a7";
  margin-left: -1em;
}
.ph-duotone.ph-headset:before {
  content: "\e584";
  opacity: 0.2;
}
.ph-duotone.ph-headset:after {
  content: "\e585";
  margin-left: -1em;
}
.ph-duotone.ph-heart:before {
  content: "\e2a8";
  opacity: 0.2;
}
.ph-duotone.ph-heart:after {
  content: "\e2a9";
  margin-left: -1em;
}
.ph-duotone.ph-heart-break:before {
  content: "\ebe8";
  opacity: 0.2;
}
.ph-duotone.ph-heart-break:after {
  content: "\ebe9";
  margin-left: -1em;
}
.ph-duotone.ph-heart-half:before {
  content: "\ec48";
  opacity: 0.2;
}
.ph-duotone.ph-heart-half:after {
  content: "\ec49";
  margin-left: -1em;
}
.ph-duotone.ph-heart-straight:before {
  content: "\e2aa";
  opacity: 0.2;
}
.ph-duotone.ph-heart-straight:after {
  content: "\e2ab";
  margin-left: -1em;
}
.ph-duotone.ph-heart-straight-break:before {
  content: "\eb98";
  opacity: 0.2;
}
.ph-duotone.ph-heart-straight-break:after {
  content: "\eb99";
  margin-left: -1em;
}
.ph-duotone.ph-heartbeat:before {
  content: "\e2ac";
  opacity: 0.2;
}
.ph-duotone.ph-heartbeat:after {
  content: "\e2ad";
  margin-left: -1em;
}
.ph-duotone.ph-hexagon:before {
  content: "\e2ae";
  opacity: 0.2;
}
.ph-duotone.ph-hexagon:after {
  content: "\e2af";
  margin-left: -1em;
}
.ph-duotone.ph-high-definition:before {
  content: "\ea8e";
  opacity: 0.2;
}
.ph-duotone.ph-high-definition:after {
  content: "\ea8f";
  margin-left: -1em;
}
.ph-duotone.ph-high-heel:before {
  content: "\e8e8";
  opacity: 0.2;
}
.ph-duotone.ph-high-heel:after {
  content: "\e8e9";
  margin-left: -1em;
}
.ph-duotone.ph-highlighter:before {
  content: "\ec76";
  opacity: 0.2;
}
.ph-duotone.ph-highlighter:after {
  content: "\ec77";
  margin-left: -1em;
}
.ph-duotone.ph-highlighter-circle:before {
  content: "\e632";
  opacity: 0.2;
}
.ph-duotone.ph-highlighter-circle:after {
  content: "\e633";
  margin-left: -1em;
}
.ph-duotone.ph-hockey:before {
  content: "\ec86";
  opacity: 0.2;
}
.ph-duotone.ph-hockey:after {
  content: "\ec87";
  margin-left: -1em;
}
.ph-duotone.ph-hoodie:before {
  content: "\ecd0";
  opacity: 0.2;
}
.ph-duotone.ph-hoodie:after {
  content: "\ecd1";
  margin-left: -1em;
}
.ph-duotone.ph-horse:before {
  content: "\e2b0";
  opacity: 0.2;
}
.ph-duotone.ph-horse:after {
  content: "\e2b1";
  margin-left: -1em;
}
.ph-duotone.ph-hospital:before {
  content: "\e844";
  opacity: 0.2;
}
.ph-duotone.ph-hospital:after {
  content: "\e845";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass:before {
  content: "\e2b2";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass:after {
  content: "\e2b3";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-high:before {
  content: "\e2b4";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-high:after {
  content: "\e2b5";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-low:before {
  content: "\e2b6";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-low:after {
  content: "\e2b7";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-medium:before {
  content: "\e2b8";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-medium:after {
  content: "\e2b9";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-simple:before {
  content: "\e2ba";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-simple:after {
  content: "\e2bb";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-simple-high:before {
  content: "\e2bc";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-simple-high:after {
  content: "\e2bd";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-simple-low:before {
  content: "\e2be";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-simple-low:after {
  content: "\e2bf";
  margin-left: -1em;
}
.ph-duotone.ph-hourglass-simple-medium:before {
  content: "\e2c0";
  opacity: 0.2;
}
.ph-duotone.ph-hourglass-simple-medium:after {
  content: "\e2c1";
  margin-left: -1em;
}
.ph-duotone.ph-house:before {
  content: "\e2c2";
  opacity: 0.2;
}
.ph-duotone.ph-house:after {
  content: "\e2c3";
  margin-left: -1em;
}
.ph-duotone.ph-house-line:before {
  content: "\e2c4";
  opacity: 0.2;
}
.ph-duotone.ph-house-line:after {
  content: "\e2c5";
  margin-left: -1em;
}
.ph-duotone.ph-house-simple:before {
  content: "\e2c6";
  opacity: 0.2;
}
.ph-duotone.ph-house-simple:after {
  content: "\e2c7";
  margin-left: -1em;
}
.ph-duotone.ph-hurricane:before {
  content: "\e88e";
  opacity: 0.2;
}
.ph-duotone.ph-hurricane:after {
  content: "\e88f";
  margin-left: -1em;
}
.ph-duotone.ph-ice-cream:before {
  content: "\e804";
  opacity: 0.2;
}
.ph-duotone.ph-ice-cream:after {
  content: "\e805";
  margin-left: -1em;
}
.ph-duotone.ph-identification-badge:before {
  content: "\e6f6";
  opacity: 0.2;
}
.ph-duotone.ph-identification-badge:after {
  content: "\e6f7";
  margin-left: -1em;
}
.ph-duotone.ph-identification-card:before {
  content: "\e2c8";
  opacity: 0.2;
}
.ph-duotone.ph-identification-card:after {
  content: "\e2c9";
  margin-left: -1em;
}
.ph-duotone.ph-image:before {
  content: "\e2ca";
  opacity: 0.2;
}
.ph-duotone.ph-image:after {
  content: "\e2cb";
  margin-left: -1em;
}
.ph-duotone.ph-image-broken:before {
  content: "\e7a8";
  opacity: 0.2;
}
.ph-duotone.ph-image-broken:after {
  content: "\e7a9";
  margin-left: -1em;
}
.ph-duotone.ph-image-square:before {
  content: "\e2cc";
  opacity: 0.2;
}
.ph-duotone.ph-image-square:after {
  content: "\e2cd";
  margin-left: -1em;
}
.ph-duotone.ph-images:before {
  content: "\e836";
  opacity: 0.2;
}
.ph-duotone.ph-images:after {
  content: "\e837";
  margin-left: -1em;
}
.ph-duotone.ph-images-square:before {
  content: "\e834";
  opacity: 0.2;
}
.ph-duotone.ph-images-square:after {
  content: "\e835";
  margin-left: -1em;
}
.ph-duotone.ph-infinity:before {
  content: "\e634";
  opacity: 0.2;
}
.ph-duotone.ph-infinity:after {
  content: "\e635";
  margin-left: -1em;
}
.ph-duotone.ph-info:before {
  content: "\e2ce";
  opacity: 0.2;
}
.ph-duotone.ph-info:after {
  content: "\e2cf";
  margin-left: -1em;
}
.ph-duotone.ph-instagram-logo:before {
  content: "\e2d0";
  opacity: 0.2;
}
.ph-duotone.ph-instagram-logo:after {
  content: "\e2d1";
  margin-left: -1em;
}
.ph-duotone.ph-intersect:before {
  content: "\e2d2";
  opacity: 0.2;
}
.ph-duotone.ph-intersect:after {
  content: "\e2d3";
  margin-left: -1em;
}
.ph-duotone.ph-intersect-square:before {
  content: "\e87a";
  opacity: 0.2;
}
.ph-duotone.ph-intersect-square:after {
  content: "\e87b";
  margin-left: -1em;
}
.ph-duotone.ph-intersect-three:before {
  content: "\ecc4";
  opacity: 0.2;
}
.ph-duotone.ph-intersect-three:after {
  content: "\ecc5";
  margin-left: -1em;
}
.ph-duotone.ph-intersection:before {
  content: "\edba";
  opacity: 0.2;
}
.ph-duotone.ph-intersection:after {
  content: "\edbb";
  margin-left: -1em;
}
.ph-duotone.ph-invoice:before {
  content: "\ee42";
  opacity: 0.2;
}
.ph-duotone.ph-invoice:after {
  content: "\ee43";
  margin-left: -1em;
}
.ph-duotone.ph-island:before {
  content: "\ee06";
  opacity: 0.2;
}
.ph-duotone.ph-island:after {
  content: "\ee07";
  margin-left: -1em;
}
.ph-duotone.ph-jar:before {
  content: "\e7e0";
  opacity: 0.2;
}
.ph-duotone.ph-jar:after {
  content: "\e7e3";
  margin-left: -1em;
}
.ph-duotone.ph-jar-label:before {
  content: "\e7e1";
  opacity: 0.2;
}
.ph-duotone.ph-jar-label:after {
  content: "\e7e5";
  margin-left: -1em;
}
.ph-duotone.ph-jeep:before {
  content: "\e2d4";
  opacity: 0.2;
}
.ph-duotone.ph-jeep:after {
  content: "\e2d5";
  margin-left: -1em;
}
.ph-duotone.ph-joystick:before {
  content: "\ea5e";
  opacity: 0.2;
}
.ph-duotone.ph-joystick:after {
  content: "\ea5f";
  margin-left: -1em;
}
.ph-duotone.ph-kanban:before {
  content: "\eb54";
  opacity: 0.2;
}
.ph-duotone.ph-kanban:after {
  content: "\eb55";
  margin-left: -1em;
}
.ph-duotone.ph-key:before {
  content: "\e2d6";
  opacity: 0.2;
}
.ph-duotone.ph-key:after {
  content: "\e2d7";
  margin-left: -1em;
}
.ph-duotone.ph-key-return:before {
  content: "\e782";
  opacity: 0.2;
}
.ph-duotone.ph-key-return:after {
  content: "\e783";
  margin-left: -1em;
}
.ph-duotone.ph-keyboard:before {
  content: "\e2d8";
  opacity: 0.2;
}
.ph-duotone.ph-keyboard:after {
  content: "\e2d9";
  margin-left: -1em;
}
.ph-duotone.ph-keyhole:before {
  content: "\ea78";
  opacity: 0.2;
}
.ph-duotone.ph-keyhole:after {
  content: "\ea79";
  margin-left: -1em;
}
.ph-duotone.ph-knife:before {
  content: "\e636";
  opacity: 0.2;
}
.ph-duotone.ph-knife:after {
  content: "\e637";
  margin-left: -1em;
}
.ph-duotone.ph-ladder:before {
  content: "\e9e4";
  opacity: 0.2;
}
.ph-duotone.ph-ladder:after {
  content: "\e9e5";
  margin-left: -1em;
}
.ph-duotone.ph-ladder-simple:before {
  content: "\ec26";
  opacity: 0.2;
}
.ph-duotone.ph-ladder-simple:after {
  content: "\ec27";
  margin-left: -1em;
}
.ph-duotone.ph-lamp:before {
  content: "\e638";
  opacity: 0.2;
}
.ph-duotone.ph-lamp:after {
  content: "\e639";
  margin-left: -1em;
}
.ph-duotone.ph-lamp-pendant:before {
  content: "\ee2e";
  opacity: 0.2;
}
.ph-duotone.ph-lamp-pendant:after {
  content: "\ee2f";
  margin-left: -1em;
}
.ph-duotone.ph-laptop:before {
  content: "\e586";
  opacity: 0.2;
}
.ph-duotone.ph-laptop:after {
  content: "\e587";
  margin-left: -1em;
}
.ph-duotone.ph-lasso:before {
  content: "\edc6";
  opacity: 0.2;
}
.ph-duotone.ph-lasso:after {
  content: "\edc7";
  margin-left: -1em;
}
.ph-duotone.ph-lastfm-logo:before {
  content: "\e842";
  opacity: 0.2;
}
.ph-duotone.ph-lastfm-logo:after {
  content: "\e843";
  margin-left: -1em;
}
.ph-duotone.ph-layout:before {
  content: "\e6d6";
  opacity: 0.2;
}
.ph-duotone.ph-layout:after {
  content: "\e6d7";
  margin-left: -1em;
}
.ph-duotone.ph-leaf:before {
  content: "\e2da";
  opacity: 0.2;
}
.ph-duotone.ph-leaf:after {
  content: "\e2db";
  margin-left: -1em;
}
.ph-duotone.ph-lectern:before {
  content: "\e95a";
  opacity: 0.2;
}
.ph-duotone.ph-lectern:after {
  content: "\e95b";
  margin-left: -1em;
}
.ph-duotone.ph-lego:before {
  content: "\e8c6";
  opacity: 0.2;
}
.ph-duotone.ph-lego:after {
  content: "\e8c8";
  margin-left: -1em;
}
.ph-duotone.ph-lego-smiley:before {
  content: "\e8c7";
  opacity: 0.2;
}
.ph-duotone.ph-lego-smiley:after {
  content: "\e8c9";
  margin-left: -1em;
}
.ph-duotone.ph-less-than:before {
  content: "\edac";
  opacity: 0.2;
}
.ph-duotone.ph-less-than:after {
  content: "\edad";
  margin-left: -1em;
}
.ph-duotone.ph-less-than-or-equal:before {
  content: "\eda4";
  opacity: 0.2;
}
.ph-duotone.ph-less-than-or-equal:after {
  content: "\eda5";
  margin-left: -1em;
}
.ph-duotone.ph-letter-circle-h:before {
  content: "\ebf8";
  opacity: 0.2;
}
.ph-duotone.ph-letter-circle-h:after {
  content: "\ebf9";
  margin-left: -1em;
}
.ph-duotone.ph-letter-circle-p:before {
  content: "\ec08";
  opacity: 0.2;
}
.ph-duotone.ph-letter-circle-p:after {
  content: "\ec09";
  margin-left: -1em;
}
.ph-duotone.ph-letter-circle-v:before {
  content: "\ec14";
  opacity: 0.2;
}
.ph-duotone.ph-letter-circle-v:after {
  content: "\ec15";
  margin-left: -1em;
}
.ph-duotone.ph-lifebuoy:before {
  content: "\e63a";
  opacity: 0.2;
}
.ph-duotone.ph-lifebuoy:after {
  content: "\e63b";
  margin-left: -1em;
}
.ph-duotone.ph-lightbulb:before {
  content: "\e2dc";
  opacity: 0.2;
}
.ph-duotone.ph-lightbulb:after {
  content: "\e2dd";
  margin-left: -1em;
}
.ph-duotone.ph-lightbulb-filament:before {
  content: "\e63c";
  opacity: 0.2;
}
.ph-duotone.ph-lightbulb-filament:after {
  content: "\e63d";
  margin-left: -1em;
}
.ph-duotone.ph-lighthouse:before {
  content: "\e9f6";
  opacity: 0.2;
}
.ph-duotone.ph-lighthouse:after {
  content: "\e9f7";
  margin-left: -1em;
}
.ph-duotone.ph-lightning:before {
  content: "\e2de";
  opacity: 0.2;
}
.ph-duotone.ph-lightning:after {
  content: "\e2df";
  margin-left: -1em;
}
.ph-duotone.ph-lightning-a:before {
  content: "\ea84";
  opacity: 0.2;
}
.ph-duotone.ph-lightning-a:after {
  content: "\ea85";
  margin-left: -1em;
}
.ph-duotone.ph-lightning-slash:before {
  content: "\e2e0";
  opacity: 0.2;
}
.ph-duotone.ph-lightning-slash:after {
  content: "\e2e1";
  margin-left: -1em;
}
.ph-duotone.ph-line-segment:before {
  content: "\e6d2";
  opacity: 0.2;
}
.ph-duotone.ph-line-segment:after {
  content: "\e6d3";
  margin-left: -1em;
}
.ph-duotone.ph-line-segments:before {
  content: "\e6d4";
  opacity: 0.2;
}
.ph-duotone.ph-line-segments:after {
  content: "\e6d5";
  margin-left: -1em;
}
.ph-duotone.ph-line-vertical:before {
  content: "\ed70";
  opacity: 0.2;
}
.ph-duotone.ph-line-vertical:after {
  content: "\ed71";
  margin-left: -1em;
}
.ph-duotone.ph-link:before {
  content: "\e2e2";
  opacity: 0.2;
}
.ph-duotone.ph-link:after {
  content: "\e2e3";
  margin-left: -1em;
}
.ph-duotone.ph-link-break:before {
  content: "\e2e4";
  opacity: 0.2;
}
.ph-duotone.ph-link-break:after {
  content: "\e2e5";
  margin-left: -1em;
}
.ph-duotone.ph-link-simple:before {
  content: "\e2e6";
  opacity: 0.2;
}
.ph-duotone.ph-link-simple:after {
  content: "\e2e7";
  margin-left: -1em;
}
.ph-duotone.ph-link-simple-break:before {
  content: "\e2e8";
  opacity: 0.2;
}
.ph-duotone.ph-link-simple-break:after {
  content: "\e2e9";
  margin-left: -1em;
}
.ph-duotone.ph-link-simple-horizontal:before {
  content: "\e2ea";
  opacity: 0.2;
}
.ph-duotone.ph-link-simple-horizontal:after {
  content: "\e2eb";
  margin-left: -1em;
}
.ph-duotone.ph-link-simple-horizontal-break:before {
  content: "\e2ec";
  opacity: 0.2;
}
.ph-duotone.ph-link-simple-horizontal-break:after {
  content: "\e2ed";
  margin-left: -1em;
}
.ph-duotone.ph-linkedin-logo:before {
  content: "\e2ee";
  opacity: 0.2;
}
.ph-duotone.ph-linkedin-logo:after {
  content: "\e2ef";
  margin-left: -1em;
}
.ph-duotone.ph-linktree-logo:before {
  content: "\edee";
  opacity: 0.2;
}
.ph-duotone.ph-linktree-logo:after {
  content: "\edef";
  margin-left: -1em;
}
.ph-duotone.ph-linux-logo:before {
  content: "\eb02";
  opacity: 0.2;
}
.ph-duotone.ph-linux-logo:after {
  content: "\eb03";
  margin-left: -1em;
}
.ph-duotone.ph-list:before {
  content: "\e2f0";
  opacity: 0.2;
}
.ph-duotone.ph-list:after {
  content: "\e2f1";
  margin-left: -1em;
}
.ph-duotone.ph-list-bullets:before {
  content: "\e2f2";
  opacity: 0.2;
}
.ph-duotone.ph-list-bullets:after {
  content: "\e2f3";
  margin-left: -1em;
}
.ph-duotone.ph-list-checks:before {
  content: "\eadc";
  opacity: 0.2;
}
.ph-duotone.ph-list-checks:after {
  content: "\eadd";
  margin-left: -1em;
}
.ph-duotone.ph-list-dashes:before {
  content: "\e2f4";
  opacity: 0.2;
}
.ph-duotone.ph-list-dashes:after {
  content: "\e2f5";
  margin-left: -1em;
}
.ph-duotone.ph-list-heart:before {
  content: "\ebde";
  opacity: 0.2;
}
.ph-duotone.ph-list-heart:after {
  content: "\ebdf";
  margin-left: -1em;
}
.ph-duotone.ph-list-magnifying-glass:before {
  content: "\ebe0";
  opacity: 0.2;
}
.ph-duotone.ph-list-magnifying-glass:after {
  content: "\ebe1";
  margin-left: -1em;
}
.ph-duotone.ph-list-numbers:before {
  content: "\e2f6";
  opacity: 0.2;
}
.ph-duotone.ph-list-numbers:after {
  content: "\e2f7";
  margin-left: -1em;
}
.ph-duotone.ph-list-plus:before {
  content: "\e2f8";
  opacity: 0.2;
}
.ph-duotone.ph-list-plus:after {
  content: "\e2f9";
  margin-left: -1em;
}
.ph-duotone.ph-list-star:before {
  content: "\ebdc";
  opacity: 0.2;
}
.ph-duotone.ph-list-star:after {
  content: "\ebdd";
  margin-left: -1em;
}
.ph-duotone.ph-lock:before {
  content: "\e2fa";
  opacity: 0.2;
}
.ph-duotone.ph-lock:after {
  content: "\e2fb";
  margin-left: -1em;
}
.ph-duotone.ph-lock-key:before {
  content: "\e2fe";
  opacity: 0.2;
}
.ph-duotone.ph-lock-key:after {
  content: "\e2ff";
  margin-left: -1em;
}
.ph-duotone.ph-lock-key-open:before {
  content: "\e300";
  opacity: 0.2;
}
.ph-duotone.ph-lock-key-open:after {
  content: "\e301";
  margin-left: -1em;
}
.ph-duotone.ph-lock-laminated:before {
  content: "\e302";
  opacity: 0.2;
}
.ph-duotone.ph-lock-laminated:after {
  content: "\e303";
  margin-left: -1em;
}
.ph-duotone.ph-lock-laminated-open:before {
  content: "\e304";
  opacity: 0.2;
}
.ph-duotone.ph-lock-laminated-open:after {
  content: "\e305";
  margin-left: -1em;
}
.ph-duotone.ph-lock-open:before {
  content: "\e306";
  opacity: 0.2;
}
.ph-duotone.ph-lock-open:after {
  content: "\e307";
  margin-left: -1em;
}
.ph-duotone.ph-lock-simple:before {
  content: "\e308";
  opacity: 0.2;
}
.ph-duotone.ph-lock-simple:after {
  content: "\e309";
  margin-left: -1em;
}
.ph-duotone.ph-lock-simple-open:before {
  content: "\e30a";
  opacity: 0.2;
}
.ph-duotone.ph-lock-simple-open:after {
  content: "\e30b";
  margin-left: -1em;
}
.ph-duotone.ph-lockers:before {
  content: "\ecb8";
  opacity: 0.2;
}
.ph-duotone.ph-lockers:after {
  content: "\ecb9";
  margin-left: -1em;
}
.ph-duotone.ph-log:before {
  content: "\ed82";
  opacity: 0.2;
}
.ph-duotone.ph-log:after {
  content: "\ed83";
  margin-left: -1em;
}
.ph-duotone.ph-magic-wand:before {
  content: "\e6b6";
  opacity: 0.2;
}
.ph-duotone.ph-magic-wand:after {
  content: "\e6b7";
  margin-left: -1em;
}
.ph-duotone.ph-magnet:before {
  content: "\e680";
  opacity: 0.2;
}
.ph-duotone.ph-magnet:after {
  content: "\e681";
  margin-left: -1em;
}
.ph-duotone.ph-magnet-straight:before {
  content: "\e682";
  opacity: 0.2;
}
.ph-duotone.ph-magnet-straight:after {
  content: "\e683";
  margin-left: -1em;
}
.ph-duotone.ph-magnifying-glass:before {
  content: "\e30c";
  opacity: 0.2;
}
.ph-duotone.ph-magnifying-glass:after {
  content: "\e30d";
  margin-left: -1em;
}
.ph-duotone.ph-magnifying-glass-minus:before {
  content: "\e30e";
  opacity: 0.2;
}
.ph-duotone.ph-magnifying-glass-minus:after {
  content: "\e30f";
  margin-left: -1em;
}
.ph-duotone.ph-magnifying-glass-plus:before {
  content: "\e310";
  opacity: 0.2;
}
.ph-duotone.ph-magnifying-glass-plus:after {
  content: "\e311";
  margin-left: -1em;
}
.ph-duotone.ph-mailbox:before {
  content: "\ec1e";
  opacity: 0.2;
}
.ph-duotone.ph-mailbox:after {
  content: "\ec1f";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin:before {
  content: "\e316";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin:after {
  content: "\e317";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin-area:before {
  content: "\ee3a";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin-area:after {
  content: "\ee3b";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin-line:before {
  content: "\e318";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin-line:after {
  content: "\e319";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin-plus:before {
  content: "\e314";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin-plus:after {
  content: "\e315";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin-simple:before {
  content: "\ee3e";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin-simple:after {
  content: "\ee3f";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin-simple-area:before {
  content: "\ee3c";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin-simple-area:after {
  content: "\ee3d";
  margin-left: -1em;
}
.ph-duotone.ph-map-pin-simple-line:before {
  content: "\ee38";
  opacity: 0.2;
}
.ph-duotone.ph-map-pin-simple-line:after {
  content: "\ee39";
  margin-left: -1em;
}
.ph-duotone.ph-map-trifold:before {
  content: "\e31a";
  opacity: 0.2;
}
.ph-duotone.ph-map-trifold:after {
  content: "\e31b";
  margin-left: -1em;
}
.ph-duotone.ph-markdown-logo:before {
  content: "\e508";
  opacity: 0.2;
}
.ph-duotone.ph-markdown-logo:after {
  content: "\e509";
  margin-left: -1em;
}
.ph-duotone.ph-marker-circle:before {
  content: "\e640";
  opacity: 0.2;
}
.ph-duotone.ph-marker-circle:after {
  content: "\e641";
  margin-left: -1em;
}
.ph-duotone.ph-martini:before {
  content: "\e31c";
  opacity: 0.2;
}
.ph-duotone.ph-martini:after {
  content: "\e31d";
  margin-left: -1em;
}
.ph-duotone.ph-mask-happy:before {
  content: "\e9f4";
  opacity: 0.2;
}
.ph-duotone.ph-mask-happy:after {
  content: "\e9f5";
  margin-left: -1em;
}
.ph-duotone.ph-mask-sad:before {
  content: "\eb9e";
  opacity: 0.2;
}
.ph-duotone.ph-mask-sad:after {
  content: "\eb9f";
  margin-left: -1em;
}
.ph-duotone.ph-mastodon-logo:before {
  content: "\ed68";
  opacity: 0.2;
}
.ph-duotone.ph-mastodon-logo:after {
  content: "\ed69";
  margin-left: -1em;
}
.ph-duotone.ph-math-operations:before {
  content: "\e31e";
  opacity: 0.2;
}
.ph-duotone.ph-math-operations:after {
  content: "\e31f";
  margin-left: -1em;
}
.ph-duotone.ph-matrix-logo:before {
  content: "\ed64";
  opacity: 0.2;
}
.ph-duotone.ph-matrix-logo:after {
  content: "\ed65";
  margin-left: -1em;
}
.ph-duotone.ph-medal:before {
  content: "\e320";
  opacity: 0.2;
}
.ph-duotone.ph-medal:after {
  content: "\e321";
  margin-left: -1em;
}
.ph-duotone.ph-medal-military:before {
  content: "\ecfc";
  opacity: 0.2;
}
.ph-duotone.ph-medal-military:after {
  content: "\ecfd";
  margin-left: -1em;
}
.ph-duotone.ph-medium-logo:before {
  content: "\e322";
  opacity: 0.2;
}
.ph-duotone.ph-medium-logo:after {
  content: "\e323";
  margin-left: -1em;
}
.ph-duotone.ph-megaphone:before {
  content: "\e324";
  opacity: 0.2;
}
.ph-duotone.ph-megaphone:after {
  content: "\e325";
  margin-left: -1em;
}
.ph-duotone.ph-megaphone-simple:before {
  content: "\e642";
  opacity: 0.2;
}
.ph-duotone.ph-megaphone-simple:after {
  content: "\e643";
  margin-left: -1em;
}
.ph-duotone.ph-member-of:before {
  content: "\edc2";
  opacity: 0.2;
}
.ph-duotone.ph-member-of:after {
  content: "\edc3";
  margin-left: -1em;
}
.ph-duotone.ph-memory:before {
  content: "\e9c4";
  opacity: 0.2;
}
.ph-duotone.ph-memory:after {
  content: "\e9c5";
  margin-left: -1em;
}
.ph-duotone.ph-messenger-logo:before {
  content: "\e6d8";
  opacity: 0.2;
}
.ph-duotone.ph-messenger-logo:after {
  content: "\e6d9";
  margin-left: -1em;
}
.ph-duotone.ph-meta-logo:before {
  content: "\ed02";
  opacity: 0.2;
}
.ph-duotone.ph-meta-logo:after {
  content: "\ed03";
  margin-left: -1em;
}
.ph-duotone.ph-meteor:before {
  content: "\e9ba";
  opacity: 0.2;
}
.ph-duotone.ph-meteor:after {
  content: "\e9bb";
  margin-left: -1em;
}
.ph-duotone.ph-metronome:before {
  content: "\ec8e";
  opacity: 0.2;
}
.ph-duotone.ph-metronome:after {
  content: "\ec8f";
  margin-left: -1em;
}
.ph-duotone.ph-microphone:before {
  content: "\e326";
  opacity: 0.2;
}
.ph-duotone.ph-microphone:after {
  content: "\e327";
  margin-left: -1em;
}
.ph-duotone.ph-microphone-slash:before {
  content: "\e328";
  opacity: 0.2;
}
.ph-duotone.ph-microphone-slash:after {
  content: "\e329";
  margin-left: -1em;
}
.ph-duotone.ph-microphone-stage:before {
  content: "\e75c";
  opacity: 0.2;
}
.ph-duotone.ph-microphone-stage:after {
  content: "\e75d";
  margin-left: -1em;
}
.ph-duotone.ph-microscope:before {
  content: "\ec7a";
  opacity: 0.2;
}
.ph-duotone.ph-microscope:after {
  content: "\ec7b";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-excel-logo:before {
  content: "\eb6c";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-excel-logo:after {
  content: "\eb6d";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-outlook-logo:before {
  content: "\eb70";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-outlook-logo:after {
  content: "\eb71";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-powerpoint-logo:before {
  content: "\eace";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-powerpoint-logo:after {
  content: "\eacf";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-teams-logo:before {
  content: "\eb66";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-teams-logo:after {
  content: "\eb67";
  margin-left: -1em;
}
.ph-duotone.ph-microsoft-word-logo:before {
  content: "\eb6a";
  opacity: 0.2;
}
.ph-duotone.ph-microsoft-word-logo:after {
  content: "\eb6b";
  margin-left: -1em;
}
.ph-duotone.ph-minus:before {
  content: "\e32a";
  opacity: 0.2;
}
.ph-duotone.ph-minus:after {
  content: "\e32b";
  margin-left: -1em;
}
.ph-duotone.ph-minus-circle:before {
  content: "\e32c";
  opacity: 0.2;
}
.ph-duotone.ph-minus-circle:after {
  content: "\e32d";
  margin-left: -1em;
}
.ph-duotone.ph-minus-square:before {
  content: "\ed4c";
  opacity: 0.2;
}
.ph-duotone.ph-minus-square:after {
  content: "\ed53";
  margin-left: -1em;
}
.ph-duotone.ph-money:before {
  content: "\e588";
  opacity: 0.2;
}
.ph-duotone.ph-money:after {
  content: "\e589";
  margin-left: -1em;
}
.ph-duotone.ph-money-wavy:before {
  content: "\ee68";
  opacity: 0.2;
}
.ph-duotone.ph-money-wavy:after {
  content: "\ee69";
  margin-left: -1em;
}
.ph-duotone.ph-monitor:before {
  content: "\e32e";
  opacity: 0.2;
}
.ph-duotone.ph-monitor:after {
  content: "\e32f";
  margin-left: -1em;
}
.ph-duotone.ph-monitor-arrow-up:before {
  content: "\e58a";
  opacity: 0.2;
}
.ph-duotone.ph-monitor-arrow-up:after {
  content: "\e58b";
  margin-left: -1em;
}
.ph-duotone.ph-monitor-play:before {
  content: "\e58c";
  opacity: 0.2;
}
.ph-duotone.ph-monitor-play:after {
  content: "\e58d";
  margin-left: -1em;
}
.ph-duotone.ph-moon:before {
  content: "\e330";
  opacity: 0.2;
}
.ph-duotone.ph-moon:after {
  content: "\e331";
  margin-left: -1em;
}
.ph-duotone.ph-moon-stars:before {
  content: "\e58e";
  opacity: 0.2;
}
.ph-duotone.ph-moon-stars:after {
  content: "\e58f";
  margin-left: -1em;
}
.ph-duotone.ph-moped:before {
  content: "\e824";
  opacity: 0.2;
}
.ph-duotone.ph-moped:after {
  content: "\e825";
  margin-left: -1em;
}
.ph-duotone.ph-moped-front:before {
  content: "\e822";
  opacity: 0.2;
}
.ph-duotone.ph-moped-front:after {
  content: "\e823";
  margin-left: -1em;
}
.ph-duotone.ph-mosque:before {
  content: "\ecee";
  opacity: 0.2;
}
.ph-duotone.ph-mosque:after {
  content: "\ecef";
  margin-left: -1em;
}
.ph-duotone.ph-motorcycle:before {
  content: "\e80a";
  opacity: 0.2;
}
.ph-duotone.ph-motorcycle:after {
  content: "\e80b";
  margin-left: -1em;
}
.ph-duotone.ph-mountains:before {
  content: "\e7ae";
  opacity: 0.2;
}
.ph-duotone.ph-mountains:after {
  content: "\e7af";
  margin-left: -1em;
}
.ph-duotone.ph-mouse:before {
  content: "\e33a";
  opacity: 0.2;
}
.ph-duotone.ph-mouse:after {
  content: "\e33b";
  margin-left: -1em;
}
.ph-duotone.ph-mouse-left-click:before {
  content: "\e334";
  opacity: 0.2;
}
.ph-duotone.ph-mouse-left-click:after {
  content: "\e335";
  margin-left: -1em;
}
.ph-duotone.ph-mouse-middle-click:before {
  content: "\e338";
  opacity: 0.2;
}
.ph-duotone.ph-mouse-middle-click:after {
  content: "\e339";
  margin-left: -1em;
}
.ph-duotone.ph-mouse-right-click:before {
  content: "\e336";
  opacity: 0.2;
}
.ph-duotone.ph-mouse-right-click:after {
  content: "\e337";
  margin-left: -1em;
}
.ph-duotone.ph-mouse-scroll:before {
  content: "\e332";
  opacity: 0.2;
}
.ph-duotone.ph-mouse-scroll:after {
  content: "\e333";
  margin-left: -1em;
}
.ph-duotone.ph-mouse-simple:before {
  content: "\e644";
  opacity: 0.2;
}
.ph-duotone.ph-mouse-simple:after {
  content: "\e645";
  margin-left: -1em;
}
.ph-duotone.ph-music-note:before {
  content: "\e33c";
  opacity: 0.2;
}
.ph-duotone.ph-music-note:after {
  content: "\e33d";
  margin-left: -1em;
}
.ph-duotone.ph-music-note-simple:before {
  content: "\e33e";
  opacity: 0.2;
}
.ph-duotone.ph-music-note-simple:after {
  content: "\e33f";
  margin-left: -1em;
}
.ph-duotone.ph-music-notes:before {
  content: "\e340";
  opacity: 0.2;
}
.ph-duotone.ph-music-notes:after {
  content: "\e341";
  margin-left: -1em;
}
.ph-duotone.ph-music-notes-minus:before {
  content: "\ee0c";
  opacity: 0.2;
}
.ph-duotone.ph-music-notes-minus:after {
  content: "\ee0d";
  margin-left: -1em;
}
.ph-duotone.ph-music-notes-plus:before {
  content: "\eb7c";
  opacity: 0.2;
}
.ph-duotone.ph-music-notes-plus:after {
  content: "\eb7d";
  margin-left: -1em;
}
.ph-duotone.ph-music-notes-simple:before {
  content: "\e342";
  opacity: 0.2;
}
.ph-duotone.ph-music-notes-simple:after {
  content: "\e343";
  margin-left: -1em;
}
.ph-duotone.ph-navigation-arrow:before {
  content: "\eade";
  opacity: 0.2;
}
.ph-duotone.ph-navigation-arrow:after {
  content: "\eadf";
  margin-left: -1em;
}
.ph-duotone.ph-needle:before {
  content: "\e82e";
  opacity: 0.2;
}
.ph-duotone.ph-needle:after {
  content: "\e82f";
  margin-left: -1em;
}
.ph-duotone.ph-network:before {
  content: "\edde";
  opacity: 0.2;
}
.ph-duotone.ph-network:after {
  content: "\eddf";
  margin-left: -1em;
}
.ph-duotone.ph-network-slash:before {
  content: "\eddc";
  opacity: 0.2;
}
.ph-duotone.ph-network-slash:after {
  content: "\eddd";
  margin-left: -1em;
}
.ph-duotone.ph-network-x:before {
  content: "\edda";
  opacity: 0.2;
}
.ph-duotone.ph-network-x:after {
  content: "\eddb";
  margin-left: -1em;
}
.ph-duotone.ph-newspaper:before {
  content: "\e344";
  opacity: 0.2;
}
.ph-duotone.ph-newspaper:after {
  content: "\e345";
  margin-left: -1em;
}
.ph-duotone.ph-newspaper-clipping:before {
  content: "\e346";
  opacity: 0.2;
}
.ph-duotone.ph-newspaper-clipping:after {
  content: "\e347";
  margin-left: -1em;
}
.ph-duotone.ph-not-equals:before {
  content: "\eda6";
  opacity: 0.2;
}
.ph-duotone.ph-not-equals:after {
  content: "\eda7";
  margin-left: -1em;
}
.ph-duotone.ph-not-member-of:before {
  content: "\edae";
  opacity: 0.2;
}
.ph-duotone.ph-not-member-of:after {
  content: "\edaf";
  margin-left: -1em;
}
.ph-duotone.ph-not-subset-of:before {
  content: "\edb0";
  opacity: 0.2;
}
.ph-duotone.ph-not-subset-of:after {
  content: "\edb1";
  margin-left: -1em;
}
.ph-duotone.ph-not-superset-of:before {
  content: "\edb2";
  opacity: 0.2;
}
.ph-duotone.ph-not-superset-of:after {
  content: "\edb3";
  margin-left: -1em;
}
.ph-duotone.ph-notches:before {
  content: "\ed3a";
  opacity: 0.2;
}
.ph-duotone.ph-notches:after {
  content: "\ed3b";
  margin-left: -1em;
}
.ph-duotone.ph-note:before {
  content: "\e348";
  opacity: 0.2;
}
.ph-duotone.ph-note:after {
  content: "\e349";
  margin-left: -1em;
}
.ph-duotone.ph-note-blank:before {
  content: "\e34a";
  opacity: 0.2;
}
.ph-duotone.ph-note-blank:after {
  content: "\e34b";
  margin-left: -1em;
}
.ph-duotone.ph-note-pencil:before {
  content: "\e34c";
  opacity: 0.2;
}
.ph-duotone.ph-note-pencil:after {
  content: "\e34d";
  margin-left: -1em;
}
.ph-duotone.ph-notebook:before {
  content: "\e34e";
  opacity: 0.2;
}
.ph-duotone.ph-notebook:after {
  content: "\e34f";
  margin-left: -1em;
}
.ph-duotone.ph-notepad:before {
  content: "\e63e";
  opacity: 0.2;
}
.ph-duotone.ph-notepad:after {
  content: "\e63f";
  margin-left: -1em;
}
.ph-duotone.ph-notification:before {
  content: "\e6fa";
  opacity: 0.2;
}
.ph-duotone.ph-notification:after {
  content: "\e6fb";
  margin-left: -1em;
}
.ph-duotone.ph-notion-logo:before {
  content: "\e9a0";
  opacity: 0.2;
}
.ph-duotone.ph-notion-logo:after {
  content: "\e9a1";
  margin-left: -1em;
}
.ph-duotone.ph-nuclear-plant:before {
  content: "\ed7c";
  opacity: 0.2;
}
.ph-duotone.ph-nuclear-plant:after {
  content: "\ed7d";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-eight:before {
  content: "\e352";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-eight:after {
  content: "\e353";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-five:before {
  content: "\e358";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-five:after {
  content: "\e359";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-four:before {
  content: "\e35e";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-four:after {
  content: "\e35f";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-nine:before {
  content: "\e364";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-nine:after {
  content: "\e365";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-one:before {
  content: "\e36a";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-one:after {
  content: "\e36b";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-seven:before {
  content: "\e370";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-seven:after {
  content: "\e371";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-six:before {
  content: "\e376";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-six:after {
  content: "\e377";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-three:before {
  content: "\e37c";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-three:after {
  content: "\e37d";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-two:before {
  content: "\e382";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-two:after {
  content: "\e383";
  margin-left: -1em;
}
.ph-duotone.ph-number-circle-zero:before {
  content: "\e388";
  opacity: 0.2;
}
.ph-duotone.ph-number-circle-zero:after {
  content: "\e389";
  margin-left: -1em;
}
.ph-duotone.ph-number-eight:before {
  content: "\e350";
  opacity: 0.2;
}
.ph-duotone.ph-number-eight:after {
  content: "\e351";
  margin-left: -1em;
}
.ph-duotone.ph-number-five:before {
  content: "\e356";
  opacity: 0.2;
}
.ph-duotone.ph-number-five:after {
  content: "\e357";
  margin-left: -1em;
}
.ph-duotone.ph-number-four:before {
  content: "\e35c";
  opacity: 0.2;
}
.ph-duotone.ph-number-four:after {
  content: "\e35d";
  margin-left: -1em;
}
.ph-duotone.ph-number-nine:before {
  content: "\e362";
  opacity: 0.2;
}
.ph-duotone.ph-number-nine:after {
  content: "\e363";
  margin-left: -1em;
}
.ph-duotone.ph-number-one:before {
  content: "\e368";
  opacity: 0.2;
}
.ph-duotone.ph-number-one:after {
  content: "\e369";
  margin-left: -1em;
}
.ph-duotone.ph-number-seven:before {
  content: "\e36e";
  opacity: 0.2;
}
.ph-duotone.ph-number-seven:after {
  content: "\e36f";
  margin-left: -1em;
}
.ph-duotone.ph-number-six:before {
  content: "\e374";
  opacity: 0.2;
}
.ph-duotone.ph-number-six:after {
  content: "\e375";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-eight:before {
  content: "\e354";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-eight:after {
  content: "\e355";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-five:before {
  content: "\e35a";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-five:after {
  content: "\e35b";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-four:before {
  content: "\e360";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-four:after {
  content: "\e361";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-nine:before {
  content: "\e366";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-nine:after {
  content: "\e367";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-one:before {
  content: "\e36c";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-one:after {
  content: "\e36d";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-seven:before {
  content: "\e372";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-seven:after {
  content: "\e373";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-six:before {
  content: "\e378";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-six:after {
  content: "\e379";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-three:before {
  content: "\e37e";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-three:after {
  content: "\e37f";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-two:before {
  content: "\e384";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-two:after {
  content: "\e385";
  margin-left: -1em;
}
.ph-duotone.ph-number-square-zero:before {
  content: "\e38a";
  opacity: 0.2;
}
.ph-duotone.ph-number-square-zero:after {
  content: "\e38b";
  margin-left: -1em;
}
.ph-duotone.ph-number-three:before {
  content: "\e37a";
  opacity: 0.2;
}
.ph-duotone.ph-number-three:after {
  content: "\e37b";
  margin-left: -1em;
}
.ph-duotone.ph-number-two:before {
  content: "\e380";
  opacity: 0.2;
}
.ph-duotone.ph-number-two:after {
  content: "\e381";
  margin-left: -1em;
}
.ph-duotone.ph-number-zero:before {
  content: "\e386";
  opacity: 0.2;
}
.ph-duotone.ph-number-zero:after {
  content: "\e387";
  margin-left: -1em;
}
.ph-duotone.ph-numpad:before {
  content: "\e3c8";
  opacity: 0.2;
}
.ph-duotone.ph-numpad:after {
  content: "\e3c9";
  margin-left: -1em;
}
.ph-duotone.ph-nut:before {
  content: "\e38c";
  opacity: 0.2;
}
.ph-duotone.ph-nut:after {
  content: "\e38d";
  margin-left: -1em;
}
.ph-duotone.ph-ny-times-logo:before {
  content: "\e646";
  opacity: 0.2;
}
.ph-duotone.ph-ny-times-logo:after {
  content: "\e647";
  margin-left: -1em;
}
.ph-duotone.ph-octagon:before {
  content: "\e38e";
  opacity: 0.2;
}
.ph-duotone.ph-octagon:after {
  content: "\e38f";
  margin-left: -1em;
}
.ph-duotone.ph-office-chair:before {
  content: "\ea46";
  opacity: 0.2;
}
.ph-duotone.ph-office-chair:after {
  content: "\ea47";
  margin-left: -1em;
}
.ph-duotone.ph-onigiri:before {
  content: "\ee2c";
  opacity: 0.2;
}
.ph-duotone.ph-onigiri:after {
  content: "\ee2d";
  margin-left: -1em;
}
.ph-duotone.ph-open-ai-logo:before {
  content: "\e7d2";
  opacity: 0.2;
}
.ph-duotone.ph-open-ai-logo:after {
  content: "\e7d3";
  margin-left: -1em;
}
.ph-duotone.ph-option:before {
  content: "\e8a8";
  opacity: 0.2;
}
.ph-duotone.ph-option:after {
  content: "\e8a9";
  margin-left: -1em;
}
.ph-duotone.ph-orange:before {
  content: "\ee40";
  opacity: 0.2;
}
.ph-duotone.ph-orange:after {
  content: "\ee41";
  margin-left: -1em;
}
.ph-duotone.ph-orange-slice:before {
  content: "\ed36";
  opacity: 0.2;
}
.ph-duotone.ph-orange-slice:after {
  content: "\ed37";
  margin-left: -1em;
}
.ph-duotone.ph-oven:before {
  content: "\ed8c";
  opacity: 0.2;
}
.ph-duotone.ph-oven:after {
  content: "\ed8d";
  margin-left: -1em;
}
.ph-duotone.ph-package:before {
  content: "\e390";
  opacity: 0.2;
}
.ph-duotone.ph-package:after {
  content: "\e391";
  margin-left: -1em;
}
.ph-duotone.ph-paint-brush:before {
  content: "\e6f0";
  opacity: 0.2;
}
.ph-duotone.ph-paint-brush:after {
  content: "\e6f1";
  margin-left: -1em;
}
.ph-duotone.ph-paint-brush-broad:before {
  content: "\e590";
  opacity: 0.2;
}
.ph-duotone.ph-paint-brush-broad:after {
  content: "\e591";
  margin-left: -1em;
}
.ph-duotone.ph-paint-brush-household:before {
  content: "\e6f2";
  opacity: 0.2;
}
.ph-duotone.ph-paint-brush-household:after {
  content: "\e6f3";
  margin-left: -1em;
}
.ph-duotone.ph-paint-bucket:before {
  content: "\e392";
  opacity: 0.2;
}
.ph-duotone.ph-paint-bucket:after {
  content: "\e393";
  margin-left: -1em;
}
.ph-duotone.ph-paint-roller:before {
  content: "\e6f4";
  opacity: 0.2;
}
.ph-duotone.ph-paint-roller:after {
  content: "\e6f5";
  margin-left: -1em;
}
.ph-duotone.ph-palette:before {
  content: "\e6c8";
  opacity: 0.2;
}
.ph-duotone.ph-palette:after {
  content: "\e6c9";
  margin-left: -1em;
}
.ph-duotone.ph-panorama:before {
  content: "\eaa2";
  opacity: 0.2;
}
.ph-duotone.ph-panorama:after {
  content: "\eaa3";
  margin-left: -1em;
}
.ph-duotone.ph-pants:before {
  content: "\ec88";
  opacity: 0.2;
}
.ph-duotone.ph-pants:after {
  content: "\ec89";
  margin-left: -1em;
}
.ph-duotone.ph-paper-plane:before {
  content: "\e394";
  opacity: 0.2;
}
.ph-duotone.ph-paper-plane:after {
  content: "\e395";
  margin-left: -1em;
}
.ph-duotone.ph-paper-plane-right:before {
  content: "\e396";
  opacity: 0.2;
}
.ph-duotone.ph-paper-plane-right:after {
  content: "\e397";
  margin-left: -1em;
}
.ph-duotone.ph-paper-plane-tilt:before {
  content: "\e398";
  opacity: 0.2;
}
.ph-duotone.ph-paper-plane-tilt:after {
  content: "\e399";
  margin-left: -1em;
}
.ph-duotone.ph-paperclip:before {
  content: "\e39a";
  opacity: 0.2;
}
.ph-duotone.ph-paperclip:after {
  content: "\e39b";
  margin-left: -1em;
}
.ph-duotone.ph-paperclip-horizontal:before {
  content: "\e592";
  opacity: 0.2;
}
.ph-duotone.ph-paperclip-horizontal:after {
  content: "\e593";
  margin-left: -1em;
}
.ph-duotone.ph-parachute:before {
  content: "\ea7c";
  opacity: 0.2;
}
.ph-duotone.ph-parachute:after {
  content: "\ea7d";
  margin-left: -1em;
}
.ph-duotone.ph-paragraph:before {
  content: "\e960";
  opacity: 0.2;
}
.ph-duotone.ph-paragraph:after {
  content: "\e961";
  margin-left: -1em;
}
.ph-duotone.ph-parallelogram:before {
  content: "\ecc6";
  opacity: 0.2;
}
.ph-duotone.ph-parallelogram:after {
  content: "\ecc7";
  margin-left: -1em;
}
.ph-duotone.ph-park:before {
  content: "\ecb2";
  opacity: 0.2;
}
.ph-duotone.ph-park:after {
  content: "\ecb3";
  margin-left: -1em;
}
.ph-duotone.ph-password:before {
  content: "\e752";
  opacity: 0.2;
}
.ph-duotone.ph-password:after {
  content: "\e753";
  margin-left: -1em;
}
.ph-duotone.ph-path:before {
  content: "\e39c";
  opacity: 0.2;
}
.ph-duotone.ph-path:after {
  content: "\e39d";
  margin-left: -1em;
}
.ph-duotone.ph-patreon-logo:before {
  content: "\e98a";
  opacity: 0.2;
}
.ph-duotone.ph-patreon-logo:after {
  content: "\e98b";
  margin-left: -1em;
}
.ph-duotone.ph-pause:before {
  content: "\e39e";
  opacity: 0.2;
}
.ph-duotone.ph-pause:after {
  content: "\e39f";
  margin-left: -1em;
}
.ph-duotone.ph-pause-circle:before {
  content: "\e3a0";
  opacity: 0.2;
}
.ph-duotone.ph-pause-circle:after {
  content: "\e3a1";
  margin-left: -1em;
}
.ph-duotone.ph-paw-print:before {
  content: "\e648";
  opacity: 0.2;
}
.ph-duotone.ph-paw-print:after {
  content: "\e649";
  margin-left: -1em;
}
.ph-duotone.ph-paypal-logo:before {
  content: "\e98c";
  opacity: 0.2;
}
.ph-duotone.ph-paypal-logo:after {
  content: "\e98d";
  margin-left: -1em;
}
.ph-duotone.ph-peace:before {
  content: "\e3a2";
  opacity: 0.2;
}
.ph-duotone.ph-peace:after {
  content: "\e3a3";
  margin-left: -1em;
}
.ph-duotone.ph-pen:before {
  content: "\e3aa";
  opacity: 0.2;
}
.ph-duotone.ph-pen:after {
  content: "\e3ab";
  margin-left: -1em;
}
.ph-duotone.ph-pen-nib:before {
  content: "\e3ac";
  opacity: 0.2;
}
.ph-duotone.ph-pen-nib:after {
  content: "\e3ad";
  margin-left: -1em;
}
.ph-duotone.ph-pen-nib-straight:before {
  content: "\e64a";
  opacity: 0.2;
}
.ph-duotone.ph-pen-nib-straight:after {
  content: "\e64b";
  margin-left: -1em;
}
.ph-duotone.ph-pencil:before {
  content: "\e3ae";
  opacity: 0.2;
}
.ph-duotone.ph-pencil:after {
  content: "\e3af";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-circle:before {
  content: "\e3b0";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-circle:after {
  content: "\e3b1";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-line:before {
  content: "\e3b2";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-line:after {
  content: "\e3b3";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-ruler:before {
  content: "\e906";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-ruler:after {
  content: "\e907";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-simple:before {
  content: "\e3b4";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-simple:after {
  content: "\e3b5";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-simple-line:before {
  content: "\ebc6";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-simple-line:after {
  content: "\ebc7";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-simple-slash:before {
  content: "\ecf6";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-simple-slash:after {
  content: "\ecf7";
  margin-left: -1em;
}
.ph-duotone.ph-pencil-slash:before {
  content: "\ecf8";
  opacity: 0.2;
}
.ph-duotone.ph-pencil-slash:after {
  content: "\ecf9";
  margin-left: -1em;
}
.ph-duotone.ph-pentagon:before {
  content: "\ec7e";
  opacity: 0.2;
}
.ph-duotone.ph-pentagon:after {
  content: "\ec7f";
  margin-left: -1em;
}
.ph-duotone.ph-pentagram:before {
  content: "\ec5c";
  opacity: 0.2;
}
.ph-duotone.ph-pentagram:after {
  content: "\ec5d";
  margin-left: -1em;
}
.ph-duotone.ph-pepper:before {
  content: "\e94a";
  opacity: 0.2;
}
.ph-duotone.ph-pepper:after {
  content: "\e94b";
  margin-left: -1em;
}
.ph-duotone.ph-percent:before {
  content: "\e3b6";
  opacity: 0.2;
}
.ph-duotone.ph-percent:after {
  content: "\e3b7";
  margin-left: -1em;
}
.ph-duotone.ph-person:before {
  content: "\e3a8";
  opacity: 0.2;
}
.ph-duotone.ph-person:after {
  content: "\e3a9";
  margin-left: -1em;
}
.ph-duotone.ph-person-arms-spread:before {
  content: "\ecfe";
  opacity: 0.2;
}
.ph-duotone.ph-person-arms-spread:after {
  content: "\ecff";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple:before {
  content: "\e72e";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple:after {
  content: "\e72f";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-bike:before {
  content: "\e734";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-bike:after {
  content: "\e735";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-circle:before {
  content: "\ee58";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-circle:after {
  content: "\ee59";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-hike:before {
  content: "\ed54";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-hike:after {
  content: "\ed55";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-run:before {
  content: "\e730";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-run:after {
  content: "\e731";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-ski:before {
  content: "\e71c";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-ski:after {
  content: "\e71d";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-snowboard:before {
  content: "\e71e";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-snowboard:after {
  content: "\e71f";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-swim:before {
  content: "\e736";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-swim:after {
  content: "\e737";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-tai-chi:before {
  content: "\ed5c";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-tai-chi:after {
  content: "\ed5d";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-throw:before {
  content: "\e732";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-throw:after {
  content: "\e733";
  margin-left: -1em;
}
.ph-duotone.ph-person-simple-walk:before {
  content: "\e73a";
  opacity: 0.2;
}
.ph-duotone.ph-person-simple-walk:after {
  content: "\e73b";
  margin-left: -1em;
}
.ph-duotone.ph-perspective:before {
  content: "\ebe6";
  opacity: 0.2;
}
.ph-duotone.ph-perspective:after {
  content: "\ebe7";
  margin-left: -1em;
}
.ph-duotone.ph-phone:before {
  content: "\e3b8";
  opacity: 0.2;
}
.ph-duotone.ph-phone:after {
  content: "\e3b9";
  margin-left: -1em;
}
.ph-duotone.ph-phone-call:before {
  content: "\e3ba";
  opacity: 0.2;
}
.ph-duotone.ph-phone-call:after {
  content: "\e3bb";
  margin-left: -1em;
}
.ph-duotone.ph-phone-disconnect:before {
  content: "\e3bc";
  opacity: 0.2;
}
.ph-duotone.ph-phone-disconnect:after {
  content: "\e3bd";
  margin-left: -1em;
}
.ph-duotone.ph-phone-incoming:before {
  content: "\e3be";
  opacity: 0.2;
}
.ph-duotone.ph-phone-incoming:after {
  content: "\e3bf";
  margin-left: -1em;
}
.ph-duotone.ph-phone-list:before {
  content: "\e3cc";
  opacity: 0.2;
}
.ph-duotone.ph-phone-list:after {
  content: "\e3cd";
  margin-left: -1em;
}
.ph-duotone.ph-phone-outgoing:before {
  content: "\e3c0";
  opacity: 0.2;
}
.ph-duotone.ph-phone-outgoing:after {
  content: "\e3c1";
  margin-left: -1em;
}
.ph-duotone.ph-phone-pause:before {
  content: "\e3ca";
  opacity: 0.2;
}
.ph-duotone.ph-phone-pause:after {
  content: "\e3cb";
  margin-left: -1em;
}
.ph-duotone.ph-phone-plus:before {
  content: "\ec56";
  opacity: 0.2;
}
.ph-duotone.ph-phone-plus:after {
  content: "\ec57";
  margin-left: -1em;
}
.ph-duotone.ph-phone-slash:before {
  content: "\e3c2";
  opacity: 0.2;
}
.ph-duotone.ph-phone-slash:after {
  content: "\e3c3";
  margin-left: -1em;
}
.ph-duotone.ph-phone-transfer:before {
  content: "\e3c6";
  opacity: 0.2;
}
.ph-duotone.ph-phone-transfer:after {
  content: "\e3c7";
  margin-left: -1em;
}
.ph-duotone.ph-phone-x:before {
  content: "\e3c4";
  opacity: 0.2;
}
.ph-duotone.ph-phone-x:after {
  content: "\e3c5";
  margin-left: -1em;
}
.ph-duotone.ph-phosphor-logo:before {
  content: "\e3ce";
  opacity: 0.2;
}
.ph-duotone.ph-phosphor-logo:after {
  content: "\e3cf";
  margin-left: -1em;
}
.ph-duotone.ph-pi:before {
  content: "\ec80";
  opacity: 0.2;
}
.ph-duotone.ph-pi:after {
  content: "\ec81";
  margin-left: -1em;
}
.ph-duotone.ph-piano-keys:before {
  content: "\e9c8";
  opacity: 0.2;
}
.ph-duotone.ph-piano-keys:after {
  content: "\e9c9";
  margin-left: -1em;
}
.ph-duotone.ph-picnic-table:before {
  content: "\ee26";
  opacity: 0.2;
}
.ph-duotone.ph-picnic-table:after {
  content: "\ee27";
  margin-left: -1em;
}
.ph-duotone.ph-picture-in-picture:before {
  content: "\e64c";
  opacity: 0.2;
}
.ph-duotone.ph-picture-in-picture:after {
  content: "\e64d";
  margin-left: -1em;
}
.ph-duotone.ph-piggy-bank:before {
  content: "\ea04";
  opacity: 0.2;
}
.ph-duotone.ph-piggy-bank:after {
  content: "\ea05";
  margin-left: -1em;
}
.ph-duotone.ph-pill:before {
  content: "\e700";
  opacity: 0.2;
}
.ph-duotone.ph-pill:after {
  content: "\e701";
  margin-left: -1em;
}
.ph-duotone.ph-ping-pong:before {
  content: "\ea42";
  opacity: 0.2;
}
.ph-duotone.ph-ping-pong:after {
  content: "\ea43";
  margin-left: -1em;
}
.ph-duotone.ph-pint-glass:before {
  content: "\edd0";
  opacity: 0.2;
}
.ph-duotone.ph-pint-glass:after {
  content: "\edd1";
  margin-left: -1em;
}
.ph-duotone.ph-pinterest-logo:before {
  content: "\e64e";
  opacity: 0.2;
}
.ph-duotone.ph-pinterest-logo:after {
  content: "\e64f";
  margin-left: -1em;
}
.ph-duotone.ph-pinwheel:before {
  content: "\eb9c";
  opacity: 0.2;
}
.ph-duotone.ph-pinwheel:after {
  content: "\eb9d";
  margin-left: -1em;
}
.ph-duotone.ph-pipe:before {
  content: "\ed86";
  opacity: 0.2;
}
.ph-duotone.ph-pipe:after {
  content: "\ed87";
  margin-left: -1em;
}
.ph-duotone.ph-pipe-wrench:before {
  content: "\ed88";
  opacity: 0.2;
}
.ph-duotone.ph-pipe-wrench:after {
  content: "\ed89";
  margin-left: -1em;
}
.ph-duotone.ph-pix-logo:before {
  content: "\ecc2";
  opacity: 0.2;
}
.ph-duotone.ph-pix-logo:after {
  content: "\ecc3";
  margin-left: -1em;
}
.ph-duotone.ph-pizza:before {
  content: "\e796";
  opacity: 0.2;
}
.ph-duotone.ph-pizza:after {
  content: "\e797";
  margin-left: -1em;
}
.ph-duotone.ph-placeholder:before {
  content: "\e650";
  opacity: 0.2;
}
.ph-duotone.ph-placeholder:after {
  content: "\e651";
  margin-left: -1em;
}
.ph-duotone.ph-planet:before {
  content: "\e652";
  opacity: 0.2;
}
.ph-duotone.ph-planet:after {
  content: "\e653";
  margin-left: -1em;
}
.ph-duotone.ph-plant:before {
  content: "\ebae";
  opacity: 0.2;
}
.ph-duotone.ph-plant:after {
  content: "\ebaf";
  margin-left: -1em;
}
.ph-duotone.ph-play:before {
  content: "\e3d0";
  opacity: 0.2;
}
.ph-duotone.ph-play:after {
  content: "\e3d1";
  margin-left: -1em;
}
.ph-duotone.ph-play-circle:before {
  content: "\e3d2";
  opacity: 0.2;
}
.ph-duotone.ph-play-circle:after {
  content: "\e3d3";
  margin-left: -1em;
}
.ph-duotone.ph-play-pause:before {
  content: "\e8be";
  opacity: 0.2;
}
.ph-duotone.ph-play-pause:after {
  content: "\e8bf";
  margin-left: -1em;
}
.ph-duotone.ph-playlist:before {
  content: "\e6aa";
  opacity: 0.2;
}
.ph-duotone.ph-playlist:after {
  content: "\e6ab";
  margin-left: -1em;
}
.ph-duotone.ph-plug:before {
  content: "\e946";
  opacity: 0.2;
}
.ph-duotone.ph-plug:after {
  content: "\e947";
  margin-left: -1em;
}
.ph-duotone.ph-plug-charging:before {
  content: "\eb5c";
  opacity: 0.2;
}
.ph-duotone.ph-plug-charging:after {
  content: "\eb5d";
  margin-left: -1em;
}
.ph-duotone.ph-plugs:before {
  content: "\eb56";
  opacity: 0.2;
}
.ph-duotone.ph-plugs:after {
  content: "\eb57";
  margin-left: -1em;
}
.ph-duotone.ph-plugs-connected:before {
  content: "\eb5a";
  opacity: 0.2;
}
.ph-duotone.ph-plugs-connected:after {
  content: "\eb5b";
  margin-left: -1em;
}
.ph-duotone.ph-plus:before {
  content: "\e3d4";
  opacity: 0.2;
}
.ph-duotone.ph-plus:after {
  content: "\e3d5";
  margin-left: -1em;
}
.ph-duotone.ph-plus-circle:before {
  content: "\e3d6";
  opacity: 0.2;
}
.ph-duotone.ph-plus-circle:after {
  content: "\e3d7";
  margin-left: -1em;
}
.ph-duotone.ph-plus-minus:before {
  content: "\e3d8";
  opacity: 0.2;
}
.ph-duotone.ph-plus-minus:after {
  content: "\e3d9";
  margin-left: -1em;
}
.ph-duotone.ph-plus-square:before {
  content: "\ed4a";
  opacity: 0.2;
}
.ph-duotone.ph-plus-square:after {
  content: "\ed56";
  margin-left: -1em;
}
.ph-duotone.ph-poker-chip:before {
  content: "\e594";
  opacity: 0.2;
}
.ph-duotone.ph-poker-chip:after {
  content: "\e595";
  margin-left: -1em;
}
.ph-duotone.ph-police-car:before {
  content: "\ec4a";
  opacity: 0.2;
}
.ph-duotone.ph-police-car:after {
  content: "\ec4b";
  margin-left: -1em;
}
.ph-duotone.ph-polygon:before {
  content: "\e6d0";
  opacity: 0.2;
}
.ph-duotone.ph-polygon:after {
  content: "\e6d1";
  margin-left: -1em;
}
.ph-duotone.ph-popcorn:before {
  content: "\eb4e";
  opacity: 0.2;
}
.ph-duotone.ph-popcorn:after {
  content: "\eb4f";
  margin-left: -1em;
}
.ph-duotone.ph-popsicle:before {
  content: "\ebbe";
  opacity: 0.2;
}
.ph-duotone.ph-popsicle:after {
  content: "\ebbf";
  margin-left: -1em;
}
.ph-duotone.ph-potted-plant:before {
  content: "\ec22";
  opacity: 0.2;
}
.ph-duotone.ph-potted-plant:after {
  content: "\ec23";
  margin-left: -1em;
}
.ph-duotone.ph-power:before {
  content: "\e3da";
  opacity: 0.2;
}
.ph-duotone.ph-power:after {
  content: "\e3db";
  margin-left: -1em;
}
.ph-duotone.ph-prescription:before {
  content: "\e7a2";
  opacity: 0.2;
}
.ph-duotone.ph-prescription:after {
  content: "\e7a3";
  margin-left: -1em;
}
.ph-duotone.ph-presentation:before {
  content: "\e654";
  opacity: 0.2;
}
.ph-duotone.ph-presentation:after {
  content: "\e655";
  margin-left: -1em;
}
.ph-duotone.ph-presentation-chart:before {
  content: "\e656";
  opacity: 0.2;
}
.ph-duotone.ph-presentation-chart:after {
  content: "\e657";
  margin-left: -1em;
}
.ph-duotone.ph-printer:before {
  content: "\e3dc";
  opacity: 0.2;
}
.ph-duotone.ph-printer:after {
  content: "\e3dd";
  margin-left: -1em;
}
.ph-duotone.ph-prohibit:before {
  content: "\e3de";
  opacity: 0.2;
}
.ph-duotone.ph-prohibit:after {
  content: "\e3df";
  margin-left: -1em;
}
.ph-duotone.ph-prohibit-inset:before {
  content: "\e3e0";
  opacity: 0.2;
}
.ph-duotone.ph-prohibit-inset:after {
  content: "\e3e1";
  margin-left: -1em;
}
.ph-duotone.ph-projector-screen:before {
  content: "\e658";
  opacity: 0.2;
}
.ph-duotone.ph-projector-screen:after {
  content: "\e659";
  margin-left: -1em;
}
.ph-duotone.ph-projector-screen-chart:before {
  content: "\e65a";
  opacity: 0.2;
}
.ph-duotone.ph-projector-screen-chart:after {
  content: "\e65b";
  margin-left: -1em;
}
.ph-duotone.ph-pulse:before {
  content: "\e000";
  opacity: 0.2;
}
.ph-duotone.ph-pulse:after {
  content: "\e001";
  margin-left: -1em;
}
.ph-duotone.ph-push-pin:before {
  content: "\e3e2";
  opacity: 0.2;
}
.ph-duotone.ph-push-pin:after {
  content: "\e3e3";
  margin-left: -1em;
}
.ph-duotone.ph-push-pin-simple:before {
  content: "\e65c";
  opacity: 0.2;
}
.ph-duotone.ph-push-pin-simple:after {
  content: "\e65d";
  margin-left: -1em;
}
.ph-duotone.ph-push-pin-simple-slash:before {
  content: "\e65e";
  opacity: 0.2;
}
.ph-duotone.ph-push-pin-simple-slash:after {
  content: "\e65f";
  margin-left: -1em;
}
.ph-duotone.ph-push-pin-slash:before {
  content: "\e3e4";
  opacity: 0.2;
}
.ph-duotone.ph-push-pin-slash:after {
  content: "\e3e5";
  margin-left: -1em;
}
.ph-duotone.ph-puzzle-piece:before {
  content: "\e596";
  opacity: 0.2;
}
.ph-duotone.ph-puzzle-piece:after {
  content: "\e597";
  margin-left: -1em;
}
.ph-duotone.ph-qr-code:before {
  content: "\e3e6";
  opacity: 0.2;
}
.ph-duotone.ph-qr-code:after {
  content: "\e3e7";
  margin-left: -1em;
}
.ph-duotone.ph-question:before {
  content: "\e3e8";
  opacity: 0.2;
}
.ph-duotone.ph-question:after {
  content: "\e3eb";
  margin-left: -1em;
}
.ph-duotone.ph-question-mark:before {
  content: "\e3e9";
  opacity: 0.2;
}
.ph-duotone.ph-question-mark:after {
  content: "\e3ed";
  margin-left: -1em;
}
.ph-duotone.ph-queue:before {
  content: "\e6ac";
  opacity: 0.2;
}
.ph-duotone.ph-queue:after {
  content: "\e6ad";
  margin-left: -1em;
}
.ph-duotone.ph-quotes:before {
  content: "\e660";
  opacity: 0.2;
}
.ph-duotone.ph-quotes:after {
  content: "\e661";
  margin-left: -1em;
}
.ph-duotone.ph-rabbit:before {
  content: "\eac2";
  opacity: 0.2;
}
.ph-duotone.ph-rabbit:after {
  content: "\eac3";
  margin-left: -1em;
}
.ph-duotone.ph-racquet:before {
  content: "\ee02";
  opacity: 0.2;
}
.ph-duotone.ph-racquet:after {
  content: "\ee03";
  margin-left: -1em;
}
.ph-duotone.ph-radical:before {
  content: "\e3ea";
  opacity: 0.2;
}
.ph-duotone.ph-radical:after {
  content: "\e3ef";
  margin-left: -1em;
}
.ph-duotone.ph-radio:before {
  content: "\e77e";
  opacity: 0.2;
}
.ph-duotone.ph-radio:after {
  content: "\e77f";
  margin-left: -1em;
}
.ph-duotone.ph-radio-button:before {
  content: "\eb08";
  opacity: 0.2;
}
.ph-duotone.ph-radio-button:after {
  content: "\eb09";
  margin-left: -1em;
}
.ph-duotone.ph-radioactive:before {
  content: "\e9dc";
  opacity: 0.2;
}
.ph-duotone.ph-radioactive:after {
  content: "\e9dd";
  margin-left: -1em;
}
.ph-duotone.ph-rainbow:before {
  content: "\e598";
  opacity: 0.2;
}
.ph-duotone.ph-rainbow:after {
  content: "\e599";
  margin-left: -1em;
}
.ph-duotone.ph-rainbow-cloud:before {
  content: "\e59a";
  opacity: 0.2;
}
.ph-duotone.ph-rainbow-cloud:after {
  content: "\e59b";
  margin-left: -1em;
}
.ph-duotone.ph-ranking:before {
  content: "\ed62";
  opacity: 0.2;
}
.ph-duotone.ph-ranking:after {
  content: "\ed63";
  margin-left: -1em;
}
.ph-duotone.ph-read-cv-logo:before {
  content: "\ed0c";
  opacity: 0.2;
}
.ph-duotone.ph-read-cv-logo:after {
  content: "\ed0d";
  margin-left: -1em;
}
.ph-duotone.ph-receipt:before {
  content: "\e3ec";
  opacity: 0.2;
}
.ph-duotone.ph-receipt:after {
  content: "\e3f1";
  margin-left: -1em;
}
.ph-duotone.ph-receipt-x:before {
  content: "\ed40";
  opacity: 0.2;
}
.ph-duotone.ph-receipt-x:after {
  content: "\ed41";
  margin-left: -1em;
}
.ph-duotone.ph-record:before {
  content: "\e3ee";
  opacity: 0.2;
}
.ph-duotone.ph-record:after {
  content: "\e3f3";
  margin-left: -1em;
}
.ph-duotone.ph-rectangle:before {
  content: "\e3f0";
  opacity: 0.2;
}
.ph-duotone.ph-rectangle:after {
  content: "\e3f5";
  margin-left: -1em;
}
.ph-duotone.ph-rectangle-dashed:before {
  content: "\e3f2";
  opacity: 0.2;
}
.ph-duotone.ph-rectangle-dashed:after {
  content: "\e3f7";
  margin-left: -1em;
}
.ph-duotone.ph-recycle:before {
  content: "\e75a";
  opacity: 0.2;
}
.ph-duotone.ph-recycle:after {
  content: "\e75b";
  margin-left: -1em;
}
.ph-duotone.ph-reddit-logo:before {
  content: "\e59c";
  opacity: 0.2;
}
.ph-duotone.ph-reddit-logo:after {
  content: "\e59d";
  margin-left: -1em;
}
.ph-duotone.ph-repeat:before {
  content: "\e3f6";
  opacity: 0.2;
}
.ph-duotone.ph-repeat:after {
  content: "\e3f9";
  margin-left: -1em;
}
.ph-duotone.ph-repeat-once:before {
  content: "\e3f8";
  opacity: 0.2;
}
.ph-duotone.ph-repeat-once:after {
  content: "\e3fb";
  margin-left: -1em;
}
.ph-duotone.ph-replit-logo:before {
  content: "\eb8a";
  opacity: 0.2;
}
.ph-duotone.ph-replit-logo:after {
  content: "\eb8b";
  margin-left: -1em;
}
.ph-duotone.ph-resize:before {
  content: "\ed6e";
  opacity: 0.2;
}
.ph-duotone.ph-resize:after {
  content: "\ed6f";
  margin-left: -1em;
}
.ph-duotone.ph-rewind:before {
  content: "\e6a8";
  opacity: 0.2;
}
.ph-duotone.ph-rewind:after {
  content: "\e6a9";
  margin-left: -1em;
}
.ph-duotone.ph-rewind-circle:before {
  content: "\e3fa";
  opacity: 0.2;
}
.ph-duotone.ph-rewind-circle:after {
  content: "\e3fd";
  margin-left: -1em;
}
.ph-duotone.ph-road-horizon:before {
  content: "\e838";
  opacity: 0.2;
}
.ph-duotone.ph-road-horizon:after {
  content: "\e839";
  margin-left: -1em;
}
.ph-duotone.ph-robot:before {
  content: "\e762";
  opacity: 0.2;
}
.ph-duotone.ph-robot:after {
  content: "\e763";
  margin-left: -1em;
}
.ph-duotone.ph-rocket:before {
  content: "\e3fc";
  opacity: 0.2;
}
.ph-duotone.ph-rocket:after {
  content: "\e3ff";
  margin-left: -1em;
}
.ph-duotone.ph-rocket-launch:before {
  content: "\e3fe";
  opacity: 0.2;
}
.ph-duotone.ph-rocket-launch:after {
  content: "\e401";
  margin-left: -1em;
}
.ph-duotone.ph-rows:before {
  content: "\e5a2";
  opacity: 0.2;
}
.ph-duotone.ph-rows:after {
  content: "\e5a3";
  margin-left: -1em;
}
.ph-duotone.ph-rows-plus-bottom:before {
  content: "\e59e";
  opacity: 0.2;
}
.ph-duotone.ph-rows-plus-bottom:after {
  content: "\e59f";
  margin-left: -1em;
}
.ph-duotone.ph-rows-plus-top:before {
  content: "\e5a0";
  opacity: 0.2;
}
.ph-duotone.ph-rows-plus-top:after {
  content: "\e5a1";
  margin-left: -1em;
}
.ph-duotone.ph-rss:before {
  content: "\e400";
  opacity: 0.2;
}
.ph-duotone.ph-rss:after {
  content: "\e403";
  margin-left: -1em;
}
.ph-duotone.ph-rss-simple:before {
  content: "\e402";
  opacity: 0.2;
}
.ph-duotone.ph-rss-simple:after {
  content: "\e405";
  margin-left: -1em;
}
.ph-duotone.ph-rug:before {
  content: "\ea1a";
  opacity: 0.2;
}
.ph-duotone.ph-rug:after {
  content: "\ea1b";
  margin-left: -1em;
}
.ph-duotone.ph-ruler:before {
  content: "\e6b8";
  opacity: 0.2;
}
.ph-duotone.ph-ruler:after {
  content: "\e6b9";
  margin-left: -1em;
}
.ph-duotone.ph-sailboat:before {
  content: "\e78a";
  opacity: 0.2;
}
.ph-duotone.ph-sailboat:after {
  content: "\e78b";
  margin-left: -1em;
}
.ph-duotone.ph-scales:before {
  content: "\e750";
  opacity: 0.2;
}
.ph-duotone.ph-scales:after {
  content: "\e751";
  margin-left: -1em;
}
.ph-duotone.ph-scan:before {
  content: "\ebb6";
  opacity: 0.2;
}
.ph-duotone.ph-scan:after {
  content: "\ebb7";
  margin-left: -1em;
}
.ph-duotone.ph-scan-smiley:before {
  content: "\ebb4";
  opacity: 0.2;
}
.ph-duotone.ph-scan-smiley:after {
  content: "\ebb5";
  margin-left: -1em;
}
.ph-duotone.ph-scissors:before {
  content: "\eae0";
  opacity: 0.2;
}
.ph-duotone.ph-scissors:after {
  content: "\eae1";
  margin-left: -1em;
}
.ph-duotone.ph-scooter:before {
  content: "\e820";
  opacity: 0.2;
}
.ph-duotone.ph-scooter:after {
  content: "\e821";
  margin-left: -1em;
}
.ph-duotone.ph-screencast:before {
  content: "\e404";
  opacity: 0.2;
}
.ph-duotone.ph-screencast:after {
  content: "\e407";
  margin-left: -1em;
}
.ph-duotone.ph-screwdriver:before {
  content: "\e86e";
  opacity: 0.2;
}
.ph-duotone.ph-screwdriver:after {
  content: "\e86f";
  margin-left: -1em;
}
.ph-duotone.ph-scribble:before {
  content: "\e806";
  opacity: 0.2;
}
.ph-duotone.ph-scribble:after {
  content: "\e807";
  margin-left: -1em;
}
.ph-duotone.ph-scribble-loop:before {
  content: "\e662";
  opacity: 0.2;
}
.ph-duotone.ph-scribble-loop:after {
  content: "\e663";
  margin-left: -1em;
}
.ph-duotone.ph-scroll:before {
  content: "\eb7a";
  opacity: 0.2;
}
.ph-duotone.ph-scroll:after {
  content: "\eb7b";
  margin-left: -1em;
}
.ph-duotone.ph-seal:before {
  content: "\e604";
  opacity: 0.2;
}
.ph-duotone.ph-seal:after {
  content: "\e605";
  margin-left: -1em;
}
.ph-duotone.ph-seal-check:before {
  content: "\e606";
  opacity: 0.2;
}
.ph-duotone.ph-seal-check:after {
  content: "\e607";
  margin-left: -1em;
}
.ph-duotone.ph-seal-percent:before {
  content: "\e60a";
  opacity: 0.2;
}
.ph-duotone.ph-seal-percent:after {
  content: "\e60b";
  margin-left: -1em;
}
.ph-duotone.ph-seal-question:before {
  content: "\e608";
  opacity: 0.2;
}
.ph-duotone.ph-seal-question:after {
  content: "\e609";
  margin-left: -1em;
}
.ph-duotone.ph-seal-warning:before {
  content: "\e60c";
  opacity: 0.2;
}
.ph-duotone.ph-seal-warning:after {
  content: "\e60d";
  margin-left: -1em;
}
.ph-duotone.ph-seat:before {
  content: "\eb8e";
  opacity: 0.2;
}
.ph-duotone.ph-seat:after {
  content: "\eb8f";
  margin-left: -1em;
}
.ph-duotone.ph-seatbelt:before {
  content: "\edfe";
  opacity: 0.2;
}
.ph-duotone.ph-seatbelt:after {
  content: "\edff";
  margin-left: -1em;
}
.ph-duotone.ph-security-camera:before {
  content: "\eca4";
  opacity: 0.2;
}
.ph-duotone.ph-security-camera:after {
  content: "\eca5";
  margin-left: -1em;
}
.ph-duotone.ph-selection:before {
  content: "\e69a";
  opacity: 0.2;
}
.ph-duotone.ph-selection:after {
  content: "\e69b";
  margin-left: -1em;
}
.ph-duotone.ph-selection-all:before {
  content: "\e746";
  opacity: 0.2;
}
.ph-duotone.ph-selection-all:after {
  content: "\e747";
  margin-left: -1em;
}
.ph-duotone.ph-selection-background:before {
  content: "\eaf8";
  opacity: 0.2;
}
.ph-duotone.ph-selection-background:after {
  content: "\eaf9";
  margin-left: -1em;
}
.ph-duotone.ph-selection-foreground:before {
  content: "\eaf6";
  opacity: 0.2;
}
.ph-duotone.ph-selection-foreground:after {
  content: "\eaf7";
  margin-left: -1em;
}
.ph-duotone.ph-selection-inverse:before {
  content: "\e744";
  opacity: 0.2;
}
.ph-duotone.ph-selection-inverse:after {
  content: "\e745";
  margin-left: -1em;
}
.ph-duotone.ph-selection-plus:before {
  content: "\e69c";
  opacity: 0.2;
}
.ph-duotone.ph-selection-plus:after {
  content: "\e69d";
  margin-left: -1em;
}
.ph-duotone.ph-selection-slash:before {
  content: "\e69e";
  opacity: 0.2;
}
.ph-duotone.ph-selection-slash:after {
  content: "\e69f";
  margin-left: -1em;
}
.ph-duotone.ph-shapes:before {
  content: "\ec5e";
  opacity: 0.2;
}
.ph-duotone.ph-shapes:after {
  content: "\ec5f";
  margin-left: -1em;
}
.ph-duotone.ph-share:before {
  content: "\e406";
  opacity: 0.2;
}
.ph-duotone.ph-share:after {
  content: "\e409";
  margin-left: -1em;
}
.ph-duotone.ph-share-fat:before {
  content: "\ed52";
  opacity: 0.2;
}
.ph-duotone.ph-share-fat:after {
  content: "\ed57";
  margin-left: -1em;
}
.ph-duotone.ph-share-network:before {
  content: "\e408";
  opacity: 0.2;
}
.ph-duotone.ph-share-network:after {
  content: "\e40b";
  margin-left: -1em;
}
.ph-duotone.ph-shield:before {
  content: "\e40a";
  opacity: 0.2;
}
.ph-duotone.ph-shield:after {
  content: "\e40d";
  margin-left: -1em;
}
.ph-duotone.ph-shield-check:before {
  content: "\e40c";
  opacity: 0.2;
}
.ph-duotone.ph-shield-check:after {
  content: "\e40f";
  margin-left: -1em;
}
.ph-duotone.ph-shield-checkered:before {
  content: "\e708";
  opacity: 0.2;
}
.ph-duotone.ph-shield-checkered:after {
  content: "\e709";
  margin-left: -1em;
}
.ph-duotone.ph-shield-chevron:before {
  content: "\e40e";
  opacity: 0.2;
}
.ph-duotone.ph-shield-chevron:after {
  content: "\e411";
  margin-left: -1em;
}
.ph-duotone.ph-shield-plus:before {
  content: "\e706";
  opacity: 0.2;
}
.ph-duotone.ph-shield-plus:after {
  content: "\e707";
  margin-left: -1em;
}
.ph-duotone.ph-shield-slash:before {
  content: "\e410";
  opacity: 0.2;
}
.ph-duotone.ph-shield-slash:after {
  content: "\e413";
  margin-left: -1em;
}
.ph-duotone.ph-shield-star:before {
  content: "\ec34";
  opacity: 0.2;
}
.ph-duotone.ph-shield-star:after {
  content: "\ec35";
  margin-left: -1em;
}
.ph-duotone.ph-shield-warning:before {
  content: "\e412";
  opacity: 0.2;
}
.ph-duotone.ph-shield-warning:after {
  content: "\e414";
  margin-left: -1em;
}
.ph-duotone.ph-shipping-container:before {
  content: "\e78c";
  opacity: 0.2;
}
.ph-duotone.ph-shipping-container:after {
  content: "\e78d";
  margin-left: -1em;
}
.ph-duotone.ph-shirt-folded:before {
  content: "\ea92";
  opacity: 0.2;
}
.ph-duotone.ph-shirt-folded:after {
  content: "\ea93";
  margin-left: -1em;
}
.ph-duotone.ph-shooting-star:before {
  content: "\ecfa";
  opacity: 0.2;
}
.ph-duotone.ph-shooting-star:after {
  content: "\ecfb";
  margin-left: -1em;
}
.ph-duotone.ph-shopping-bag:before {
  content: "\e416";
  opacity: 0.2;
}
.ph-duotone.ph-shopping-bag:after {
  content: "\e417";
  margin-left: -1em;
}
.ph-duotone.ph-shopping-bag-open:before {
  content: "\e418";
  opacity: 0.2;
}
.ph-duotone.ph-shopping-bag-open:after {
  content: "\e419";
  margin-left: -1em;
}
.ph-duotone.ph-shopping-cart:before {
  content: "\e41e";
  opacity: 0.2;
}
.ph-duotone.ph-shopping-cart:after {
  content: "\e41f";
  margin-left: -1em;
}
.ph-duotone.ph-shopping-cart-simple:before {
  content: "\e420";
  opacity: 0.2;
}
.ph-duotone.ph-shopping-cart-simple:after {
  content: "\e421";
  margin-left: -1em;
}
.ph-duotone.ph-shovel:before {
  content: "\e9e6";
  opacity: 0.2;
}
.ph-duotone.ph-shovel:after {
  content: "\e9e7";
  margin-left: -1em;
}
.ph-duotone.ph-shower:before {
  content: "\e776";
  opacity: 0.2;
}
.ph-duotone.ph-shower:after {
  content: "\e777";
  margin-left: -1em;
}
.ph-duotone.ph-shrimp:before {
  content: "\eab4";
  opacity: 0.2;
}
.ph-duotone.ph-shrimp:after {
  content: "\eab5";
  margin-left: -1em;
}
.ph-duotone.ph-shuffle:before {
  content: "\e422";
  opacity: 0.2;
}
.ph-duotone.ph-shuffle:after {
  content: "\e423";
  margin-left: -1em;
}
.ph-duotone.ph-shuffle-angular:before {
  content: "\e424";
  opacity: 0.2;
}
.ph-duotone.ph-shuffle-angular:after {
  content: "\e425";
  margin-left: -1em;
}
.ph-duotone.ph-shuffle-simple:before {
  content: "\e426";
  opacity: 0.2;
}
.ph-duotone.ph-shuffle-simple:after {
  content: "\e427";
  margin-left: -1em;
}
.ph-duotone.ph-sidebar:before {
  content: "\eab6";
  opacity: 0.2;
}
.ph-duotone.ph-sidebar:after {
  content: "\eab7";
  margin-left: -1em;
}
.ph-duotone.ph-sidebar-simple:before {
  content: "\ec24";
  opacity: 0.2;
}
.ph-duotone.ph-sidebar-simple:after {
  content: "\ec25";
  margin-left: -1em;
}
.ph-duotone.ph-sigma:before {
  content: "\eab8";
  opacity: 0.2;
}
.ph-duotone.ph-sigma:after {
  content: "\eab9";
  margin-left: -1em;
}
.ph-duotone.ph-sign-in:before {
  content: "\e428";
  opacity: 0.2;
}
.ph-duotone.ph-sign-in:after {
  content: "\e429";
  margin-left: -1em;
}
.ph-duotone.ph-sign-out:before {
  content: "\e42a";
  opacity: 0.2;
}
.ph-duotone.ph-sign-out:after {
  content: "\e42b";
  margin-left: -1em;
}
.ph-duotone.ph-signature:before {
  content: "\ebac";
  opacity: 0.2;
}
.ph-duotone.ph-signature:after {
  content: "\ebad";
  margin-left: -1em;
}
.ph-duotone.ph-signpost:before {
  content: "\e89c";
  opacity: 0.2;
}
.ph-duotone.ph-signpost:after {
  content: "\e89d";
  margin-left: -1em;
}
.ph-duotone.ph-sim-card:before {
  content: "\e664";
  opacity: 0.2;
}
.ph-duotone.ph-sim-card:after {
  content: "\e665";
  margin-left: -1em;
}
.ph-duotone.ph-siren:before {
  content: "\e9b8";
  opacity: 0.2;
}
.ph-duotone.ph-siren:after {
  content: "\e9b9";
  margin-left: -1em;
}
.ph-duotone.ph-sketch-logo:before {
  content: "\e42c";
  opacity: 0.2;
}
.ph-duotone.ph-sketch-logo:after {
  content: "\e42d";
  margin-left: -1em;
}
.ph-duotone.ph-skip-back:before {
  content: "\e5a4";
  opacity: 0.2;
}
.ph-duotone.ph-skip-back:after {
  content: "\e5a5";
  margin-left: -1em;
}
.ph-duotone.ph-skip-back-circle:before {
  content: "\e42e";
  opacity: 0.2;
}
.ph-duotone.ph-skip-back-circle:after {
  content: "\e42f";
  margin-left: -1em;
}
.ph-duotone.ph-skip-forward:before {
  content: "\e5a6";
  opacity: 0.2;
}
.ph-duotone.ph-skip-forward:after {
  content: "\e5a7";
  margin-left: -1em;
}
.ph-duotone.ph-skip-forward-circle:before {
  content: "\e430";
  opacity: 0.2;
}
.ph-duotone.ph-skip-forward-circle:after {
  content: "\e431";
  margin-left: -1em;
}
.ph-duotone.ph-skull:before {
  content: "\e916";
  opacity: 0.2;
}
.ph-duotone.ph-skull:after {
  content: "\e917";
  margin-left: -1em;
}
.ph-duotone.ph-skype-logo:before {
  content: "\e8dc";
  opacity: 0.2;
}
.ph-duotone.ph-skype-logo:after {
  content: "\e8dd";
  margin-left: -1em;
}
.ph-duotone.ph-slack-logo:before {
  content: "\e5a8";
  opacity: 0.2;
}
.ph-duotone.ph-slack-logo:after {
  content: "\e5a9";
  margin-left: -1em;
}
.ph-duotone.ph-sliders:before {
  content: "\e432";
  opacity: 0.2;
}
.ph-duotone.ph-sliders:after {
  content: "\e433";
  margin-left: -1em;
}
.ph-duotone.ph-sliders-horizontal:before {
  content: "\e434";
  opacity: 0.2;
}
.ph-duotone.ph-sliders-horizontal:after {
  content: "\e435";
  margin-left: -1em;
}
.ph-duotone.ph-slideshow:before {
  content: "\ed32";
  opacity: 0.2;
}
.ph-duotone.ph-slideshow:after {
  content: "\ed33";
  margin-left: -1em;
}
.ph-duotone.ph-smiley:before {
  content: "\e436";
  opacity: 0.2;
}
.ph-duotone.ph-smiley:after {
  content: "\e437";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-angry:before {
  content: "\ec62";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-angry:after {
  content: "\ec63";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-blank:before {
  content: "\e438";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-blank:after {
  content: "\e439";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-meh:before {
  content: "\e43a";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-meh:after {
  content: "\e43b";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-melting:before {
  content: "\ee56";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-melting:after {
  content: "\ee57";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-nervous:before {
  content: "\e43c";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-nervous:after {
  content: "\e43d";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-sad:before {
  content: "\e43e";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-sad:after {
  content: "\e43f";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-sticker:before {
  content: "\e440";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-sticker:after {
  content: "\e441";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-wink:before {
  content: "\e666";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-wink:after {
  content: "\e667";
  margin-left: -1em;
}
.ph-duotone.ph-smiley-x-eyes:before {
  content: "\e442";
  opacity: 0.2;
}
.ph-duotone.ph-smiley-x-eyes:after {
  content: "\e443";
  margin-left: -1em;
}
.ph-duotone.ph-snapchat-logo:before {
  content: "\e668";
  opacity: 0.2;
}
.ph-duotone.ph-snapchat-logo:after {
  content: "\e669";
  margin-left: -1em;
}
.ph-duotone.ph-sneaker:before {
  content: "\e80c";
  opacity: 0.2;
}
.ph-duotone.ph-sneaker:after {
  content: "\e80d";
  margin-left: -1em;
}
.ph-duotone.ph-sneaker-move:before {
  content: "\ed60";
  opacity: 0.2;
}
.ph-duotone.ph-sneaker-move:after {
  content: "\ed61";
  margin-left: -1em;
}
.ph-duotone.ph-snowflake:before {
  content: "\e5aa";
  opacity: 0.2;
}
.ph-duotone.ph-snowflake:after {
  content: "\e5ab";
  margin-left: -1em;
}
.ph-duotone.ph-soccer-ball:before {
  content: "\e716";
  opacity: 0.2;
}
.ph-duotone.ph-soccer-ball:after {
  content: "\e717";
  margin-left: -1em;
}
.ph-duotone.ph-sock:before {
  content: "\ecce";
  opacity: 0.2;
}
.ph-duotone.ph-sock:after {
  content: "\eccf";
  margin-left: -1em;
}
.ph-duotone.ph-solar-panel:before {
  content: "\ed7a";
  opacity: 0.2;
}
.ph-duotone.ph-solar-panel:after {
  content: "\ed7e";
  margin-left: -1em;
}
.ph-duotone.ph-solar-roof:before {
  content: "\ed7b";
  opacity: 0.2;
}
.ph-duotone.ph-solar-roof:after {
  content: "\ed7f";
  margin-left: -1em;
}
.ph-duotone.ph-sort-ascending:before {
  content: "\e444";
  opacity: 0.2;
}
.ph-duotone.ph-sort-ascending:after {
  content: "\e445";
  margin-left: -1em;
}
.ph-duotone.ph-sort-descending:before {
  content: "\e446";
  opacity: 0.2;
}
.ph-duotone.ph-sort-descending:after {
  content: "\e447";
  margin-left: -1em;
}
.ph-duotone.ph-soundcloud-logo:before {
  content: "\e8de";
  opacity: 0.2;
}
.ph-duotone.ph-soundcloud-logo:after {
  content: "\e8df";
  margin-left: -1em;
}
.ph-duotone.ph-spade:before {
  content: "\e448";
  opacity: 0.2;
}
.ph-duotone.ph-spade:after {
  content: "\e449";
  margin-left: -1em;
}
.ph-duotone.ph-sparkle:before {
  content: "\e6a2";
  opacity: 0.2;
}
.ph-duotone.ph-sparkle:after {
  content: "\e6a3";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-hifi:before {
  content: "\ea08";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-hifi:after {
  content: "\ea09";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-high:before {
  content: "\e44a";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-high:after {
  content: "\e44b";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-low:before {
  content: "\e44c";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-low:after {
  content: "\e44d";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-none:before {
  content: "\e44e";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-none:after {
  content: "\e44f";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-high:before {
  content: "\e450";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-high:after {
  content: "\e451";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-low:before {
  content: "\e452";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-low:after {
  content: "\e453";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-none:before {
  content: "\e454";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-none:after {
  content: "\e455";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-slash:before {
  content: "\e456";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-slash:after {
  content: "\e457";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-simple-x:before {
  content: "\e458";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-simple-x:after {
  content: "\e459";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-slash:before {
  content: "\e45a";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-slash:after {
  content: "\e45b";
  margin-left: -1em;
}
.ph-duotone.ph-speaker-x:before {
  content: "\e45c";
  opacity: 0.2;
}
.ph-duotone.ph-speaker-x:after {
  content: "\e45d";
  margin-left: -1em;
}
.ph-duotone.ph-speedometer:before {
  content: "\ee74";
  opacity: 0.2;
}
.ph-duotone.ph-speedometer:after {
  content: "\ee75";
  margin-left: -1em;
}
.ph-duotone.ph-sphere:before {
  content: "\ee66";
  opacity: 0.2;
}
.ph-duotone.ph-sphere:after {
  content: "\ee67";
  margin-left: -1em;
}
.ph-duotone.ph-spinner:before {
  content: "\e66a";
  opacity: 0.2;
}
.ph-duotone.ph-spinner:after {
  content: "\e66b";
  margin-left: -1em;
}
.ph-duotone.ph-spinner-ball:before {
  content: "\ee28";
  opacity: 0.2;
}
.ph-duotone.ph-spinner-ball:after {
  content: "\ee29";
  margin-left: -1em;
}
.ph-duotone.ph-spinner-gap:before {
  content: "\e66c";
  opacity: 0.2;
}
.ph-duotone.ph-spinner-gap:after {
  content: "\e66d";
  margin-left: -1em;
}
.ph-duotone.ph-spiral:before {
  content: "\e9fa";
  opacity: 0.2;
}
.ph-duotone.ph-spiral:after {
  content: "\e9fb";
  margin-left: -1em;
}
.ph-duotone.ph-split-horizontal:before {
  content: "\e872";
  opacity: 0.2;
}
.ph-duotone.ph-split-horizontal:after {
  content: "\e873";
  margin-left: -1em;
}
.ph-duotone.ph-split-vertical:before {
  content: "\e876";
  opacity: 0.2;
}
.ph-duotone.ph-split-vertical:after {
  content: "\e877";
  margin-left: -1em;
}
.ph-duotone.ph-spotify-logo:before {
  content: "\e66e";
  opacity: 0.2;
}
.ph-duotone.ph-spotify-logo:after {
  content: "\e66f";
  margin-left: -1em;
}
.ph-duotone.ph-spray-bottle:before {
  content: "\e7e4";
  opacity: 0.2;
}
.ph-duotone.ph-spray-bottle:after {
  content: "\e7e8";
  margin-left: -1em;
}
.ph-duotone.ph-square:before {
  content: "\e45e";
  opacity: 0.2;
}
.ph-duotone.ph-square:after {
  content: "\e45f";
  margin-left: -1em;
}
.ph-duotone.ph-square-half:before {
  content: "\e462";
  opacity: 0.2;
}
.ph-duotone.ph-square-half:after {
  content: "\e463";
  margin-left: -1em;
}
.ph-duotone.ph-square-half-bottom:before {
  content: "\eb16";
  opacity: 0.2;
}
.ph-duotone.ph-square-half-bottom:after {
  content: "\eb17";
  margin-left: -1em;
}
.ph-duotone.ph-square-logo:before {
  content: "\e690";
  opacity: 0.2;
}
.ph-duotone.ph-square-logo:after {
  content: "\e691";
  margin-left: -1em;
}
.ph-duotone.ph-square-split-horizontal:before {
  content: "\e870";
  opacity: 0.2;
}
.ph-duotone.ph-square-split-horizontal:after {
  content: "\e871";
  margin-left: -1em;
}
.ph-duotone.ph-square-split-vertical:before {
  content: "\e874";
  opacity: 0.2;
}
.ph-duotone.ph-square-split-vertical:after {
  content: "\e875";
  margin-left: -1em;
}
.ph-duotone.ph-squares-four:before {
  content: "\e464";
  opacity: 0.2;
}
.ph-duotone.ph-squares-four:after {
  content: "\e465";
  margin-left: -1em;
}
.ph-duotone.ph-stack:before {
  content: "\e466";
  opacity: 0.2;
}
.ph-duotone.ph-stack:after {
  content: "\e467";
  margin-left: -1em;
}
.ph-duotone.ph-stack-minus:before {
  content: "\edf4";
  opacity: 0.2;
}
.ph-duotone.ph-stack-minus:after {
  content: "\edf5";
  margin-left: -1em;
}
.ph-duotone.ph-stack-overflow-logo:before {
  content: "\eb78";
  opacity: 0.2;
}
.ph-duotone.ph-stack-overflow-logo:after {
  content: "\eb79";
  margin-left: -1em;
}
.ph-duotone.ph-stack-plus:before {
  content: "\edf6";
  opacity: 0.2;
}
.ph-duotone.ph-stack-plus:after {
  content: "\edf7";
  margin-left: -1em;
}
.ph-duotone.ph-stack-simple:before {
  content: "\e468";
  opacity: 0.2;
}
.ph-duotone.ph-stack-simple:after {
  content: "\e469";
  margin-left: -1em;
}
.ph-duotone.ph-stairs:before {
  content: "\e8ec";
  opacity: 0.2;
}
.ph-duotone.ph-stairs:after {
  content: "\e8ed";
  margin-left: -1em;
}
.ph-duotone.ph-stamp:before {
  content: "\ea48";
  opacity: 0.2;
}
.ph-duotone.ph-stamp:after {
  content: "\ea49";
  margin-left: -1em;
}
.ph-duotone.ph-standard-definition:before {
  content: "\ea90";
  opacity: 0.2;
}
.ph-duotone.ph-standard-definition:after {
  content: "\ea91";
  margin-left: -1em;
}
.ph-duotone.ph-star:before {
  content: "\e46a";
  opacity: 0.2;
}
.ph-duotone.ph-star:after {
  content: "\e46b";
  margin-left: -1em;
}
.ph-duotone.ph-star-and-crescent:before {
  content: "\ecf4";
  opacity: 0.2;
}
.ph-duotone.ph-star-and-crescent:after {
  content: "\ecf5";
  margin-left: -1em;
}
.ph-duotone.ph-star-four:before {
  content: "\e6a4";
  opacity: 0.2;
}
.ph-duotone.ph-star-four:after {
  content: "\e6a5";
  margin-left: -1em;
}
.ph-duotone.ph-star-half:before {
  content: "\e70a";
  opacity: 0.2;
}
.ph-duotone.ph-star-half:after {
  content: "\e70b";
  margin-left: -1em;
}
.ph-duotone.ph-star-of-david:before {
  content: "\e89e";
  opacity: 0.2;
}
.ph-duotone.ph-star-of-david:after {
  content: "\e89f";
  margin-left: -1em;
}
.ph-duotone.ph-steam-logo:before {
  content: "\ead4";
  opacity: 0.2;
}
.ph-duotone.ph-steam-logo:after {
  content: "\ead5";
  margin-left: -1em;
}
.ph-duotone.ph-steering-wheel:before {
  content: "\e9ac";
  opacity: 0.2;
}
.ph-duotone.ph-steering-wheel:after {
  content: "\e9ad";
  margin-left: -1em;
}
.ph-duotone.ph-steps:before {
  content: "\ecbe";
  opacity: 0.2;
}
.ph-duotone.ph-steps:after {
  content: "\ecbf";
  margin-left: -1em;
}
.ph-duotone.ph-stethoscope:before {
  content: "\e7ea";
  opacity: 0.2;
}
.ph-duotone.ph-stethoscope:after {
  content: "\e7eb";
  margin-left: -1em;
}
.ph-duotone.ph-sticker:before {
  content: "\e5ac";
  opacity: 0.2;
}
.ph-duotone.ph-sticker:after {
  content: "\e5ad";
  margin-left: -1em;
}
.ph-duotone.ph-stool:before {
  content: "\ea44";
  opacity: 0.2;
}
.ph-duotone.ph-stool:after {
  content: "\ea45";
  margin-left: -1em;
}
.ph-duotone.ph-stop:before {
  content: "\e46c";
  opacity: 0.2;
}
.ph-duotone.ph-stop:after {
  content: "\e46d";
  margin-left: -1em;
}
.ph-duotone.ph-stop-circle:before {
  content: "\e46e";
  opacity: 0.2;
}
.ph-duotone.ph-stop-circle:after {
  content: "\e46f";
  margin-left: -1em;
}
.ph-duotone.ph-storefront:before {
  content: "\e470";
  opacity: 0.2;
}
.ph-duotone.ph-storefront:after {
  content: "\e471";
  margin-left: -1em;
}
.ph-duotone.ph-strategy:before {
  content: "\ea3a";
  opacity: 0.2;
}
.ph-duotone.ph-strategy:after {
  content: "\ea3b";
  margin-left: -1em;
}
.ph-duotone.ph-stripe-logo:before {
  content: "\e698";
  opacity: 0.2;
}
.ph-duotone.ph-stripe-logo:after {
  content: "\e699";
  margin-left: -1em;
}
.ph-duotone.ph-student:before {
  content: "\e73e";
  opacity: 0.2;
}
.ph-duotone.ph-student:after {
  content: "\e73f";
  margin-left: -1em;
}
.ph-duotone.ph-subset-of:before {
  content: "\edc0";
  opacity: 0.2;
}
.ph-duotone.ph-subset-of:after {
  content: "\edc1";
  margin-left: -1em;
}
.ph-duotone.ph-subset-proper-of:before {
  content: "\edb6";
  opacity: 0.2;
}
.ph-duotone.ph-subset-proper-of:after {
  content: "\edb7";
  margin-left: -1em;
}
.ph-duotone.ph-subtitles:before {
  content: "\e1a8";
  opacity: 0.2;
}
.ph-duotone.ph-subtitles:after {
  content: "\e1a9";
  margin-left: -1em;
}
.ph-duotone.ph-subtitles-slash:before {
  content: "\e1a6";
  opacity: 0.2;
}
.ph-duotone.ph-subtitles-slash:after {
  content: "\e1a7";
  margin-left: -1em;
}
.ph-duotone.ph-subtract:before {
  content: "\ebd6";
  opacity: 0.2;
}
.ph-duotone.ph-subtract:after {
  content: "\ebd7";
  margin-left: -1em;
}
.ph-duotone.ph-subtract-square:before {
  content: "\ebd4";
  opacity: 0.2;
}
.ph-duotone.ph-subtract-square:after {
  content: "\ebd5";
  margin-left: -1em;
}
.ph-duotone.ph-subway:before {
  content: "\e498";
  opacity: 0.2;
}
.ph-duotone.ph-subway:after {
  content: "\e499";
  margin-left: -1em;
}
.ph-duotone.ph-suitcase:before {
  content: "\e5ae";
  opacity: 0.2;
}
.ph-duotone.ph-suitcase:after {
  content: "\e5af";
  margin-left: -1em;
}
.ph-duotone.ph-suitcase-rolling:before {
  content: "\e9b0";
  opacity: 0.2;
}
.ph-duotone.ph-suitcase-rolling:after {
  content: "\e9b1";
  margin-left: -1em;
}
.ph-duotone.ph-suitcase-simple:before {
  content: "\e5b0";
  opacity: 0.2;
}
.ph-duotone.ph-suitcase-simple:after {
  content: "\e5b1";
  margin-left: -1em;
}
.ph-duotone.ph-sun:before {
  content: "\e472";
  opacity: 0.2;
}
.ph-duotone.ph-sun:after {
  content: "\e473";
  margin-left: -1em;
}
.ph-duotone.ph-sun-dim:before {
  content: "\e474";
  opacity: 0.2;
}
.ph-duotone.ph-sun-dim:after {
  content: "\e475";
  margin-left: -1em;
}
.ph-duotone.ph-sun-horizon:before {
  content: "\e5b6";
  opacity: 0.2;
}
.ph-duotone.ph-sun-horizon:after {
  content: "\e5b7";
  margin-left: -1em;
}
.ph-duotone.ph-sunglasses:before {
  content: "\e816";
  opacity: 0.2;
}
.ph-duotone.ph-sunglasses:after {
  content: "\e817";
  margin-left: -1em;
}
.ph-duotone.ph-superset-of:before {
  content: "\edb8";
  opacity: 0.2;
}
.ph-duotone.ph-superset-of:after {
  content: "\edb9";
  margin-left: -1em;
}
.ph-duotone.ph-superset-proper-of:before {
  content: "\edb4";
  opacity: 0.2;
}
.ph-duotone.ph-superset-proper-of:after {
  content: "\edb5";
  margin-left: -1em;
}
.ph-duotone.ph-swap:before {
  content: "\e83c";
  opacity: 0.2;
}
.ph-duotone.ph-swap:after {
  content: "\e83d";
  margin-left: -1em;
}
.ph-duotone.ph-swatches:before {
  content: "\e5b8";
  opacity: 0.2;
}
.ph-duotone.ph-swatches:after {
  content: "\e5b9";
  margin-left: -1em;
}
.ph-duotone.ph-swimming-pool:before {
  content: "\ecb6";
  opacity: 0.2;
}
.ph-duotone.ph-swimming-pool:after {
  content: "\ecb7";
  margin-left: -1em;
}
.ph-duotone.ph-sword:before {
  content: "\e5ba";
  opacity: 0.2;
}
.ph-duotone.ph-sword:after {
  content: "\e5bb";
  margin-left: -1em;
}
.ph-duotone.ph-synagogue:before {
  content: "\ecec";
  opacity: 0.2;
}
.ph-duotone.ph-synagogue:after {
  content: "\eced";
  margin-left: -1em;
}
.ph-duotone.ph-syringe:before {
  content: "\e968";
  opacity: 0.2;
}
.ph-duotone.ph-syringe:after {
  content: "\e969";
  margin-left: -1em;
}
.ph-duotone.ph-t-shirt:before {
  content: "\e670";
  opacity: 0.2;
}
.ph-duotone.ph-t-shirt:after {
  content: "\e671";
  margin-left: -1em;
}
.ph-duotone.ph-table:before {
  content: "\e476";
  opacity: 0.2;
}
.ph-duotone.ph-table:after {
  content: "\e477";
  margin-left: -1em;
}
.ph-duotone.ph-tabs:before {
  content: "\e778";
  opacity: 0.2;
}
.ph-duotone.ph-tabs:after {
  content: "\e779";
  margin-left: -1em;
}
.ph-duotone.ph-tag:before {
  content: "\e478";
  opacity: 0.2;
}
.ph-duotone.ph-tag:after {
  content: "\e479";
  margin-left: -1em;
}
.ph-duotone.ph-tag-chevron:before {
  content: "\e672";
  opacity: 0.2;
}
.ph-duotone.ph-tag-chevron:after {
  content: "\e673";
  margin-left: -1em;
}
.ph-duotone.ph-tag-simple:before {
  content: "\e47a";
  opacity: 0.2;
}
.ph-duotone.ph-tag-simple:after {
  content: "\e47b";
  margin-left: -1em;
}
.ph-duotone.ph-target:before {
  content: "\e47c";
  opacity: 0.2;
}
.ph-duotone.ph-target:after {
  content: "\e47d";
  margin-left: -1em;
}
.ph-duotone.ph-taxi:before {
  content: "\e902";
  opacity: 0.2;
}
.ph-duotone.ph-taxi:after {
  content: "\e903";
  margin-left: -1em;
}
.ph-duotone.ph-tea-bag:before {
  content: "\e8e6";
  opacity: 0.2;
}
.ph-duotone.ph-tea-bag:after {
  content: "\e8e7";
  margin-left: -1em;
}
.ph-duotone.ph-telegram-logo:before {
  content: "\e5bc";
  opacity: 0.2;
}
.ph-duotone.ph-telegram-logo:after {
  content: "\e5bd";
  margin-left: -1em;
}
.ph-duotone.ph-television:before {
  content: "\e754";
  opacity: 0.2;
}
.ph-duotone.ph-television:after {
  content: "\e755";
  margin-left: -1em;
}
.ph-duotone.ph-television-simple:before {
  content: "\eae6";
  opacity: 0.2;
}
.ph-duotone.ph-television-simple:after {
  content: "\eae7";
  margin-left: -1em;
}
.ph-duotone.ph-tennis-ball:before {
  content: "\e720";
  opacity: 0.2;
}
.ph-duotone.ph-tennis-ball:after {
  content: "\e721";
  margin-left: -1em;
}
.ph-duotone.ph-tent:before {
  content: "\e8ba";
  opacity: 0.2;
}
.ph-duotone.ph-tent:after {
  content: "\e8bb";
  margin-left: -1em;
}
.ph-duotone.ph-terminal:before {
  content: "\e47e";
  opacity: 0.2;
}
.ph-duotone.ph-terminal:after {
  content: "\e47f";
  margin-left: -1em;
}
.ph-duotone.ph-terminal-window:before {
  content: "\eae8";
  opacity: 0.2;
}
.ph-duotone.ph-terminal-window:after {
  content: "\eae9";
  margin-left: -1em;
}
.ph-duotone.ph-test-tube:before {
  content: "\e7a0";
  opacity: 0.2;
}
.ph-duotone.ph-test-tube:after {
  content: "\e7a1";
  margin-left: -1em;
}
.ph-duotone.ph-text-a-underline:before {
  content: "\ed34";
  opacity: 0.2;
}
.ph-duotone.ph-text-a-underline:after {
  content: "\ed35";
  margin-left: -1em;
}
.ph-duotone.ph-text-aa:before {
  content: "\e6ee";
  opacity: 0.2;
}
.ph-duotone.ph-text-aa:after {
  content: "\e6ef";
  margin-left: -1em;
}
.ph-duotone.ph-text-align-center:before {
  content: "\e480";
  opacity: 0.2;
}
.ph-duotone.ph-text-align-center:after {
  content: "\e481";
  margin-left: -1em;
}
.ph-duotone.ph-text-align-justify:before {
  content: "\e482";
  opacity: 0.2;
}
.ph-duotone.ph-text-align-justify:after {
  content: "\e483";
  margin-left: -1em;
}
.ph-duotone.ph-text-align-left:before {
  content: "\e484";
  opacity: 0.2;
}
.ph-duotone.ph-text-align-left:after {
  content: "\e485";
  margin-left: -1em;
}
.ph-duotone.ph-text-align-right:before {
  content: "\e486";
  opacity: 0.2;
}
.ph-duotone.ph-text-align-right:after {
  content: "\e487";
  margin-left: -1em;
}
.ph-duotone.ph-text-b:before {
  content: "\e5be";
  opacity: 0.2;
}
.ph-duotone.ph-text-b:after {
  content: "\e5bf";
  margin-left: -1em;
}
.ph-duotone.ph-text-columns:before {
  content: "\ec96";
  opacity: 0.2;
}
.ph-duotone.ph-text-columns:after {
  content: "\ec97";
  margin-left: -1em;
}
.ph-duotone.ph-text-h:before {
  content: "\e6ba";
  opacity: 0.2;
}
.ph-duotone.ph-text-h:after {
  content: "\e6bb";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-five:before {
  content: "\e6c4";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-five:after {
  content: "\e6c5";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-four:before {
  content: "\e6c2";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-four:after {
  content: "\e6c3";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-one:before {
  content: "\e6bc";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-one:after {
  content: "\e6bd";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-six:before {
  content: "\e6c6";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-six:after {
  content: "\e6c7";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-three:before {
  content: "\e6c0";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-three:after {
  content: "\e6c1";
  margin-left: -1em;
}
.ph-duotone.ph-text-h-two:before {
  content: "\e6be";
  opacity: 0.2;
}
.ph-duotone.ph-text-h-two:after {
  content: "\e6bf";
  margin-left: -1em;
}
.ph-duotone.ph-text-indent:before {
  content: "\ea1e";
  opacity: 0.2;
}
.ph-duotone.ph-text-indent:after {
  content: "\ea1f";
  margin-left: -1em;
}
.ph-duotone.ph-text-italic:before {
  content: "\e5c0";
  opacity: 0.2;
}
.ph-duotone.ph-text-italic:after {
  content: "\e5c1";
  margin-left: -1em;
}
.ph-duotone.ph-text-outdent:before {
  content: "\ea1c";
  opacity: 0.2;
}
.ph-duotone.ph-text-outdent:after {
  content: "\ea1d";
  margin-left: -1em;
}
.ph-duotone.ph-text-strikethrough:before {
  content: "\e5c2";
  opacity: 0.2;
}
.ph-duotone.ph-text-strikethrough:after {
  content: "\e5c3";
  margin-left: -1em;
}
.ph-duotone.ph-text-subscript:before {
  content: "\ec98";
  opacity: 0.2;
}
.ph-duotone.ph-text-subscript:after {
  content: "\ec99";
  margin-left: -1em;
}
.ph-duotone.ph-text-superscript:before {
  content: "\ec9a";
  opacity: 0.2;
}
.ph-duotone.ph-text-superscript:after {
  content: "\ec9b";
  margin-left: -1em;
}
.ph-duotone.ph-text-t:before {
  content: "\e48a";
  opacity: 0.2;
}
.ph-duotone.ph-text-t:after {
  content: "\e48b";
  margin-left: -1em;
}
.ph-duotone.ph-text-t-slash:before {
  content: "\e488";
  opacity: 0.2;
}
.ph-duotone.ph-text-t-slash:after {
  content: "\e489";
  margin-left: -1em;
}
.ph-duotone.ph-text-underline:before {
  content: "\e5c4";
  opacity: 0.2;
}
.ph-duotone.ph-text-underline:after {
  content: "\e5c5";
  margin-left: -1em;
}
.ph-duotone.ph-textbox:before {
  content: "\eb0a";
  opacity: 0.2;
}
.ph-duotone.ph-textbox:after {
  content: "\eb0b";
  margin-left: -1em;
}
.ph-duotone.ph-thermometer:before {
  content: "\e5c6";
  opacity: 0.2;
}
.ph-duotone.ph-thermometer:after {
  content: "\e5c7";
  margin-left: -1em;
}
.ph-duotone.ph-thermometer-cold:before {
  content: "\e5c8";
  opacity: 0.2;
}
.ph-duotone.ph-thermometer-cold:after {
  content: "\e5c9";
  margin-left: -1em;
}
.ph-duotone.ph-thermometer-hot:before {
  content: "\e5ca";
  opacity: 0.2;
}
.ph-duotone.ph-thermometer-hot:after {
  content: "\e5cb";
  margin-left: -1em;
}
.ph-duotone.ph-thermometer-simple:before {
  content: "\e5cc";
  opacity: 0.2;
}
.ph-duotone.ph-thermometer-simple:after {
  content: "\e5cd";
  margin-left: -1em;
}
.ph-duotone.ph-threads-logo:before {
  content: "\ed9e";
  opacity: 0.2;
}
.ph-duotone.ph-threads-logo:after {
  content: "\ed9f";
  margin-left: -1em;
}
.ph-duotone.ph-three-d:before {
  content: "\ea5a";
  opacity: 0.2;
}
.ph-duotone.ph-three-d:after {
  content: "\ea5b";
  margin-left: -1em;
}
.ph-duotone.ph-thumbs-down:before {
  content: "\e48c";
  opacity: 0.2;
}
.ph-duotone.ph-thumbs-down:after {
  content: "\e48d";
  margin-left: -1em;
}
.ph-duotone.ph-thumbs-up:before {
  content: "\e48e";
  opacity: 0.2;
}
.ph-duotone.ph-thumbs-up:after {
  content: "\e48f";
  margin-left: -1em;
}
.ph-duotone.ph-ticket:before {
  content: "\e490";
  opacity: 0.2;
}
.ph-duotone.ph-ticket:after {
  content: "\e491";
  margin-left: -1em;
}
.ph-duotone.ph-tidal-logo:before {
  content: "\ed1c";
  opacity: 0.2;
}
.ph-duotone.ph-tidal-logo:after {
  content: "\ed1d";
  margin-left: -1em;
}
.ph-duotone.ph-tiktok-logo:before {
  content: "\eaf2";
  opacity: 0.2;
}
.ph-duotone.ph-tiktok-logo:after {
  content: "\eaf3";
  margin-left: -1em;
}
.ph-duotone.ph-tilde:before {
  content: "\eda8";
  opacity: 0.2;
}
.ph-duotone.ph-tilde:after {
  content: "\eda9";
  margin-left: -1em;
}
.ph-duotone.ph-timer:before {
  content: "\e492";
  opacity: 0.2;
}
.ph-duotone.ph-timer:after {
  content: "\e493";
  margin-left: -1em;
}
.ph-duotone.ph-tip-jar:before {
  content: "\e7e2";
  opacity: 0.2;
}
.ph-duotone.ph-tip-jar:after {
  content: "\e7e9";
  margin-left: -1em;
}
.ph-duotone.ph-tipi:before {
  content: "\ed30";
  opacity: 0.2;
}
.ph-duotone.ph-tipi:after {
  content: "\ed31";
  margin-left: -1em;
}
.ph-duotone.ph-tire:before {
  content: "\edd2";
  opacity: 0.2;
}
.ph-duotone.ph-tire:after {
  content: "\edd3";
  margin-left: -1em;
}
.ph-duotone.ph-toggle-left:before {
  content: "\e674";
  opacity: 0.2;
}
.ph-duotone.ph-toggle-left:after {
  content: "\e675";
  margin-left: -1em;
}
.ph-duotone.ph-toggle-right:before {
  content: "\e676";
  opacity: 0.2;
}
.ph-duotone.ph-toggle-right:after {
  content: "\e677";
  margin-left: -1em;
}
.ph-duotone.ph-toilet:before {
  content: "\e79a";
  opacity: 0.2;
}
.ph-duotone.ph-toilet:after {
  content: "\e79b";
  margin-left: -1em;
}
.ph-duotone.ph-toilet-paper:before {
  content: "\e79c";
  opacity: 0.2;
}
.ph-duotone.ph-toilet-paper:after {
  content: "\e79d";
  margin-left: -1em;
}
.ph-duotone.ph-toolbox:before {
  content: "\eca0";
  opacity: 0.2;
}
.ph-duotone.ph-toolbox:after {
  content: "\eca1";
  margin-left: -1em;
}
.ph-duotone.ph-tooth:before {
  content: "\e9cc";
  opacity: 0.2;
}
.ph-duotone.ph-tooth:after {
  content: "\e9cd";
  margin-left: -1em;
}
.ph-duotone.ph-tornado:before {
  content: "\e88c";
  opacity: 0.2;
}
.ph-duotone.ph-tornado:after {
  content: "\e88d";
  margin-left: -1em;
}
.ph-duotone.ph-tote:before {
  content: "\e494";
  opacity: 0.2;
}
.ph-duotone.ph-tote:after {
  content: "\e495";
  margin-left: -1em;
}
.ph-duotone.ph-tote-simple:before {
  content: "\e678";
  opacity: 0.2;
}
.ph-duotone.ph-tote-simple:after {
  content: "\e679";
  margin-left: -1em;
}
.ph-duotone.ph-towel:before {
  content: "\ede6";
  opacity: 0.2;
}
.ph-duotone.ph-towel:after {
  content: "\ede7";
  margin-left: -1em;
}
.ph-duotone.ph-tractor:before {
  content: "\ec6e";
  opacity: 0.2;
}
.ph-duotone.ph-tractor:after {
  content: "\ec6f";
  margin-left: -1em;
}
.ph-duotone.ph-trademark:before {
  content: "\e9f0";
  opacity: 0.2;
}
.ph-duotone.ph-trademark:after {
  content: "\e9f1";
  margin-left: -1em;
}
.ph-duotone.ph-trademark-registered:before {
  content: "\e3f4";
  opacity: 0.2;
}
.ph-duotone.ph-trademark-registered:after {
  content: "\e415";
  margin-left: -1em;
}
.ph-duotone.ph-traffic-cone:before {
  content: "\e9a8";
  opacity: 0.2;
}
.ph-duotone.ph-traffic-cone:after {
  content: "\e9a9";
  margin-left: -1em;
}
.ph-duotone.ph-traffic-sign:before {
  content: "\e67a";
  opacity: 0.2;
}
.ph-duotone.ph-traffic-sign:after {
  content: "\e67b";
  margin-left: -1em;
}
.ph-duotone.ph-traffic-signal:before {
  content: "\e9aa";
  opacity: 0.2;
}
.ph-duotone.ph-traffic-signal:after {
  content: "\e9ab";
  margin-left: -1em;
}
.ph-duotone.ph-train:before {
  content: "\e496";
  opacity: 0.2;
}
.ph-duotone.ph-train:after {
  content: "\e497";
  margin-left: -1em;
}
.ph-duotone.ph-train-regional:before {
  content: "\e49e";
  opacity: 0.2;
}
.ph-duotone.ph-train-regional:after {
  content: "\e49f";
  margin-left: -1em;
}
.ph-duotone.ph-train-simple:before {
  content: "\e4a0";
  opacity: 0.2;
}
.ph-duotone.ph-train-simple:after {
  content: "\e4a1";
  margin-left: -1em;
}
.ph-duotone.ph-tram:before {
  content: "\e9ec";
  opacity: 0.2;
}
.ph-duotone.ph-tram:after {
  content: "\e9ed";
  margin-left: -1em;
}
.ph-duotone.ph-translate:before {
  content: "\e4a2";
  opacity: 0.2;
}
.ph-duotone.ph-translate:after {
  content: "\e4a3";
  margin-left: -1em;
}
.ph-duotone.ph-trash:before {
  content: "\e4a6";
  opacity: 0.2;
}
.ph-duotone.ph-trash:after {
  content: "\e4a7";
  margin-left: -1em;
}
.ph-duotone.ph-trash-simple:before {
  content: "\e4a8";
  opacity: 0.2;
}
.ph-duotone.ph-trash-simple:after {
  content: "\e4a9";
  margin-left: -1em;
}
.ph-duotone.ph-tray:before {
  content: "\e4aa";
  opacity: 0.2;
}
.ph-duotone.ph-tray:after {
  content: "\e4ab";
  margin-left: -1em;
}
.ph-duotone.ph-tray-arrow-down:before {
  content: "\e010";
  opacity: 0.2;
}
.ph-duotone.ph-tray-arrow-down:after {
  content: "\e011";
  margin-left: -1em;
}
.ph-duotone.ph-tray-arrow-up:before {
  content: "\ee52";
  opacity: 0.2;
}
.ph-duotone.ph-tray-arrow-up:after {
  content: "\ee53";
  margin-left: -1em;
}
.ph-duotone.ph-treasure-chest:before {
  content: "\ede2";
  opacity: 0.2;
}
.ph-duotone.ph-treasure-chest:after {
  content: "\ede3";
  margin-left: -1em;
}
.ph-duotone.ph-tree:before {
  content: "\e6da";
  opacity: 0.2;
}
.ph-duotone.ph-tree:after {
  content: "\e6db";
  margin-left: -1em;
}
.ph-duotone.ph-tree-evergreen:before {
  content: "\e6dc";
  opacity: 0.2;
}
.ph-duotone.ph-tree-evergreen:after {
  content: "\e6dd";
  margin-left: -1em;
}
.ph-duotone.ph-tree-palm:before {
  content: "\e91a";
  opacity: 0.2;
}
.ph-duotone.ph-tree-palm:after {
  content: "\e91b";
  margin-left: -1em;
}
.ph-duotone.ph-tree-structure:before {
  content: "\e67c";
  opacity: 0.2;
}
.ph-duotone.ph-tree-structure:after {
  content: "\e67d";
  margin-left: -1em;
}
.ph-duotone.ph-tree-view:before {
  content: "\ee48";
  opacity: 0.2;
}
.ph-duotone.ph-tree-view:after {
  content: "\ee49";
  margin-left: -1em;
}
.ph-duotone.ph-trend-down:before {
  content: "\e4ac";
  opacity: 0.2;
}
.ph-duotone.ph-trend-down:after {
  content: "\e4ad";
  margin-left: -1em;
}
.ph-duotone.ph-trend-up:before {
  content: "\e4ae";
  opacity: 0.2;
}
.ph-duotone.ph-trend-up:after {
  content: "\e4af";
  margin-left: -1em;
}
.ph-duotone.ph-triangle:before {
  content: "\e4b0";
  opacity: 0.2;
}
.ph-duotone.ph-triangle:after {
  content: "\e4b1";
  margin-left: -1em;
}
.ph-duotone.ph-triangle-dashed:before {
  content: "\e4b2";
  opacity: 0.2;
}
.ph-duotone.ph-triangle-dashed:after {
  content: "\e4b3";
  margin-left: -1em;
}
.ph-duotone.ph-trolley:before {
  content: "\e5b2";
  opacity: 0.2;
}
.ph-duotone.ph-trolley:after {
  content: "\e5b3";
  margin-left: -1em;
}
.ph-duotone.ph-trolley-suitcase:before {
  content: "\e5b4";
  opacity: 0.2;
}
.ph-duotone.ph-trolley-suitcase:after {
  content: "\e5b5";
  margin-left: -1em;
}
.ph-duotone.ph-trophy:before {
  content: "\e67e";
  opacity: 0.2;
}
.ph-duotone.ph-trophy:after {
  content: "\e67f";
  margin-left: -1em;
}
.ph-duotone.ph-truck:before {
  content: "\e4b4";
  opacity: 0.2;
}
.ph-duotone.ph-truck:after {
  content: "\e4b5";
  margin-left: -1em;
}
.ph-duotone.ph-truck-trailer:before {
  content: "\e4b6";
  opacity: 0.2;
}
.ph-duotone.ph-truck-trailer:after {
  content: "\e4b7";
  margin-left: -1em;
}
.ph-duotone.ph-tumblr-logo:before {
  content: "\e8d4";
  opacity: 0.2;
}
.ph-duotone.ph-tumblr-logo:after {
  content: "\e8d5";
  margin-left: -1em;
}
.ph-duotone.ph-twitch-logo:before {
  content: "\e5ce";
  opacity: 0.2;
}
.ph-duotone.ph-twitch-logo:after {
  content: "\e5cf";
  margin-left: -1em;
}
.ph-duotone.ph-twitter-logo:before {
  content: "\e4ba";
  opacity: 0.2;
}
.ph-duotone.ph-twitter-logo:after {
  content: "\e4bb";
  margin-left: -1em;
}
.ph-duotone.ph-umbrella:before {
  content: "\e684";
  opacity: 0.2;
}
.ph-duotone.ph-umbrella:after {
  content: "\e685";
  margin-left: -1em;
}
.ph-duotone.ph-umbrella-simple:before {
  content: "\e686";
  opacity: 0.2;
}
.ph-duotone.ph-umbrella-simple:after {
  content: "\e687";
  margin-left: -1em;
}
.ph-duotone.ph-union:before {
  content: "\edbe";
  opacity: 0.2;
}
.ph-duotone.ph-union:after {
  content: "\edbf";
  margin-left: -1em;
}
.ph-duotone.ph-unite:before {
  content: "\e87e";
  opacity: 0.2;
}
.ph-duotone.ph-unite:after {
  content: "\e87f";
  margin-left: -1em;
}
.ph-duotone.ph-unite-square:before {
  content: "\e878";
  opacity: 0.2;
}
.ph-duotone.ph-unite-square:after {
  content: "\e879";
  margin-left: -1em;
}
.ph-duotone.ph-upload:before {
  content: "\e4be";
  opacity: 0.2;
}
.ph-duotone.ph-upload:after {
  content: "\e4bf";
  margin-left: -1em;
}
.ph-duotone.ph-upload-simple:before {
  content: "\e4c0";
  opacity: 0.2;
}
.ph-duotone.ph-upload-simple:after {
  content: "\e4c1";
  margin-left: -1em;
}
.ph-duotone.ph-usb:before {
  content: "\e956";
  opacity: 0.2;
}
.ph-duotone.ph-usb:after {
  content: "\e957";
  margin-left: -1em;
}
.ph-duotone.ph-user:before {
  content: "\e4c2";
  opacity: 0.2;
}
.ph-duotone.ph-user:after {
  content: "\e4c3";
  margin-left: -1em;
}
.ph-duotone.ph-user-check:before {
  content: "\eafa";
  opacity: 0.2;
}
.ph-duotone.ph-user-check:after {
  content: "\eafb";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle:before {
  content: "\e4c4";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle:after {
  content: "\e4c5";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle-check:before {
  content: "\ec38";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle-check:after {
  content: "\ec39";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle-dashed:before {
  content: "\ec36";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle-dashed:after {
  content: "\ec37";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle-gear:before {
  content: "\e4c6";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle-gear:after {
  content: "\e4c7";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle-minus:before {
  content: "\e4c8";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle-minus:after {
  content: "\e4c9";
  margin-left: -1em;
}
.ph-duotone.ph-user-circle-plus:before {
  content: "\e4ca";
  opacity: 0.2;
}
.ph-duotone.ph-user-circle-plus:after {
  content: "\e4cb";
  margin-left: -1em;
}
.ph-duotone.ph-user-focus:before {
  content: "\e6fc";
  opacity: 0.2;
}
.ph-duotone.ph-user-focus:after {
  content: "\e6fd";
  margin-left: -1em;
}
.ph-duotone.ph-user-gear:before {
  content: "\e4cc";
  opacity: 0.2;
}
.ph-duotone.ph-user-gear:after {
  content: "\e4cd";
  margin-left: -1em;
}
.ph-duotone.ph-user-list:before {
  content: "\e73c";
  opacity: 0.2;
}
.ph-duotone.ph-user-list:after {
  content: "\e73d";
  margin-left: -1em;
}
.ph-duotone.ph-user-minus:before {
  content: "\e4ce";
  opacity: 0.2;
}
.ph-duotone.ph-user-minus:after {
  content: "\e4cf";
  margin-left: -1em;
}
.ph-duotone.ph-user-plus:before {
  content: "\e4d0";
  opacity: 0.2;
}
.ph-duotone.ph-user-plus:after {
  content: "\e4d1";
  margin-left: -1em;
}
.ph-duotone.ph-user-rectangle:before {
  content: "\e4d2";
  opacity: 0.2;
}
.ph-duotone.ph-user-rectangle:after {
  content: "\e4d3";
  margin-left: -1em;
}
.ph-duotone.ph-user-sound:before {
  content: "\eca8";
  opacity: 0.2;
}
.ph-duotone.ph-user-sound:after {
  content: "\eca9";
  margin-left: -1em;
}
.ph-duotone.ph-user-square:before {
  content: "\e4d4";
  opacity: 0.2;
}
.ph-duotone.ph-user-square:after {
  content: "\e4d5";
  margin-left: -1em;
}
.ph-duotone.ph-user-switch:before {
  content: "\e756";
  opacity: 0.2;
}
.ph-duotone.ph-user-switch:after {
  content: "\e757";
  margin-left: -1em;
}
.ph-duotone.ph-users:before {
  content: "\e4d6";
  opacity: 0.2;
}
.ph-duotone.ph-users:after {
  content: "\e4d7";
  margin-left: -1em;
}
.ph-duotone.ph-users-four:before {
  content: "\e68c";
  opacity: 0.2;
}
.ph-duotone.ph-users-four:after {
  content: "\e68d";
  margin-left: -1em;
}
.ph-duotone.ph-users-three:before {
  content: "\e68e";
  opacity: 0.2;
}
.ph-duotone.ph-users-three:after {
  content: "\e68f";
  margin-left: -1em;
}
.ph-duotone.ph-van:before {
  content: "\e826";
  opacity: 0.2;
}
.ph-duotone.ph-van:after {
  content: "\e827";
  margin-left: -1em;
}
.ph-duotone.ph-vault:before {
  content: "\e76e";
  opacity: 0.2;
}
.ph-duotone.ph-vault:after {
  content: "\e76f";
  margin-left: -1em;
}
.ph-duotone.ph-vector-three:before {
  content: "\ee62";
  opacity: 0.2;
}
.ph-duotone.ph-vector-three:after {
  content: "\ee63";
  margin-left: -1em;
}
.ph-duotone.ph-vector-two:before {
  content: "\ee64";
  opacity: 0.2;
}
.ph-duotone.ph-vector-two:after {
  content: "\ee65";
  margin-left: -1em;
}
.ph-duotone.ph-vibrate:before {
  content: "\e4d8";
  opacity: 0.2;
}
.ph-duotone.ph-vibrate:after {
  content: "\e4d9";
  margin-left: -1em;
}
.ph-duotone.ph-video:before {
  content: "\e740";
  opacity: 0.2;
}
.ph-duotone.ph-video:after {
  content: "\e741";
  margin-left: -1em;
}
.ph-duotone.ph-video-camera:before {
  content: "\e4da";
  opacity: 0.2;
}
.ph-duotone.ph-video-camera:after {
  content: "\e4db";
  margin-left: -1em;
}
.ph-duotone.ph-video-camera-slash:before {
  content: "\e4dc";
  opacity: 0.2;
}
.ph-duotone.ph-video-camera-slash:after {
  content: "\e4dd";
  margin-left: -1em;
}
.ph-duotone.ph-video-conference:before {
  content: "\edce";
  opacity: 0.2;
}
.ph-duotone.ph-video-conference:after {
  content: "\edcf";
  margin-left: -1em;
}
.ph-duotone.ph-vignette:before {
  content: "\eba2";
  opacity: 0.2;
}
.ph-duotone.ph-vignette:after {
  content: "\eba3";
  margin-left: -1em;
}
.ph-duotone.ph-vinyl-record:before {
  content: "\ecac";
  opacity: 0.2;
}
.ph-duotone.ph-vinyl-record:after {
  content: "\ecad";
  margin-left: -1em;
}
.ph-duotone.ph-virtual-reality:before {
  content: "\e7b8";
  opacity: 0.2;
}
.ph-duotone.ph-virtual-reality:after {
  content: "\e7b9";
  margin-left: -1em;
}
.ph-duotone.ph-virus:before {
  content: "\e7d6";
  opacity: 0.2;
}
.ph-duotone.ph-virus:after {
  content: "\e7d7";
  margin-left: -1em;
}
.ph-duotone.ph-visor:before {
  content: "\ee2a";
  opacity: 0.2;
}
.ph-duotone.ph-visor:after {
  content: "\ee2b";
  margin-left: -1em;
}
.ph-duotone.ph-voicemail:before {
  content: "\e4de";
  opacity: 0.2;
}
.ph-duotone.ph-voicemail:after {
  content: "\e4df";
  margin-left: -1em;
}
.ph-duotone.ph-volleyball:before {
  content: "\e726";
  opacity: 0.2;
}
.ph-duotone.ph-volleyball:after {
  content: "\e727";
  margin-left: -1em;
}
.ph-duotone.ph-wall:before {
  content: "\e688";
  opacity: 0.2;
}
.ph-duotone.ph-wall:after {
  content: "\e689";
  margin-left: -1em;
}
.ph-duotone.ph-wallet:before {
  content: "\e68a";
  opacity: 0.2;
}
.ph-duotone.ph-wallet:after {
  content: "\e68b";
  margin-left: -1em;
}
.ph-duotone.ph-warehouse:before {
  content: "\ecd4";
  opacity: 0.2;
}
.ph-duotone.ph-warehouse:after {
  content: "\ecd5";
  margin-left: -1em;
}
.ph-duotone.ph-warning:before {
  content: "\e4e0";
  opacity: 0.2;
}
.ph-duotone.ph-warning:after {
  content: "\e4e1";
  margin-left: -1em;
}
.ph-duotone.ph-warning-circle:before {
  content: "\e4e2";
  opacity: 0.2;
}
.ph-duotone.ph-warning-circle:after {
  content: "\e4e3";
  margin-left: -1em;
}
.ph-duotone.ph-warning-diamond:before {
  content: "\e7fc";
  opacity: 0.2;
}
.ph-duotone.ph-warning-diamond:after {
  content: "\e7fd";
  margin-left: -1em;
}
.ph-duotone.ph-warning-octagon:before {
  content: "\e4e4";
  opacity: 0.2;
}
.ph-duotone.ph-warning-octagon:after {
  content: "\e4e5";
  margin-left: -1em;
}
.ph-duotone.ph-washing-machine:before {
  content: "\ede8";
  opacity: 0.2;
}
.ph-duotone.ph-washing-machine:after {
  content: "\ede9";
  margin-left: -1em;
}
.ph-duotone.ph-watch:before {
  content: "\e4e6";
  opacity: 0.2;
}
.ph-duotone.ph-watch:after {
  content: "\e4e7";
  margin-left: -1em;
}
.ph-duotone.ph-wave-sawtooth:before {
  content: "\ea9c";
  opacity: 0.2;
}
.ph-duotone.ph-wave-sawtooth:after {
  content: "\ea9d";
  margin-left: -1em;
}
.ph-duotone.ph-wave-sine:before {
  content: "\ea9a";
  opacity: 0.2;
}
.ph-duotone.ph-wave-sine:after {
  content: "\ea9b";
  margin-left: -1em;
}
.ph-duotone.ph-wave-square:before {
  content: "\ea9e";
  opacity: 0.2;
}
.ph-duotone.ph-wave-square:after {
  content: "\ea9f";
  margin-left: -1em;
}
.ph-duotone.ph-wave-triangle:before {
  content: "\eaa0";
  opacity: 0.2;
}
.ph-duotone.ph-wave-triangle:after {
  content: "\eaa1";
  margin-left: -1em;
}
.ph-duotone.ph-waveform:before {
  content: "\e802";
  opacity: 0.2;
}
.ph-duotone.ph-waveform:after {
  content: "\e803";
  margin-left: -1em;
}
.ph-duotone.ph-waveform-slash:before {
  content: "\e800";
  opacity: 0.2;
}
.ph-duotone.ph-waveform-slash:after {
  content: "\e801";
  margin-left: -1em;
}
.ph-duotone.ph-waves:before {
  content: "\e6de";
  opacity: 0.2;
}
.ph-duotone.ph-waves:after {
  content: "\e6df";
  margin-left: -1em;
}
.ph-duotone.ph-webcam:before {
  content: "\e9b2";
  opacity: 0.2;
}
.ph-duotone.ph-webcam:after {
  content: "\e9b3";
  margin-left: -1em;
}
.ph-duotone.ph-webcam-slash:before {
  content: "\ecdc";
  opacity: 0.2;
}
.ph-duotone.ph-webcam-slash:after {
  content: "\ecdd";
  margin-left: -1em;
}
.ph-duotone.ph-webhooks-logo:before {
  content: "\ecae";
  opacity: 0.2;
}
.ph-duotone.ph-webhooks-logo:after {
  content: "\ecaf";
  margin-left: -1em;
}
.ph-duotone.ph-wechat-logo:before {
  content: "\e8d2";
  opacity: 0.2;
}
.ph-duotone.ph-wechat-logo:after {
  content: "\e8d3";
  margin-left: -1em;
}
.ph-duotone.ph-whatsapp-logo:before {
  content: "\e5d0";
  opacity: 0.2;
}
.ph-duotone.ph-whatsapp-logo:after {
  content: "\e5d1";
  margin-left: -1em;
}
.ph-duotone.ph-wheelchair:before {
  content: "\e4e8";
  opacity: 0.2;
}
.ph-duotone.ph-wheelchair:after {
  content: "\e4e9";
  margin-left: -1em;
}
.ph-duotone.ph-wheelchair-motion:before {
  content: "\e89a";
  opacity: 0.2;
}
.ph-duotone.ph-wheelchair-motion:after {
  content: "\e89b";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-high:before {
  content: "\e4ea";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-high:after {
  content: "\e4eb";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-low:before {
  content: "\e4ec";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-low:after {
  content: "\e4ed";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-medium:before {
  content: "\e4ee";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-medium:after {
  content: "\e4ef";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-none:before {
  content: "\e4f0";
  color: #444;
}
.ph-duotone.ph-wifi-slash:before {
  content: "\e4f2";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-slash:after {
  content: "\e4f3";
  margin-left: -1em;
}
.ph-duotone.ph-wifi-x:before {
  content: "\e4f4";
  opacity: 0.2;
}
.ph-duotone.ph-wifi-x:after {
  content: "\e4f5";
  margin-left: -1em;
}
.ph-duotone.ph-wind:before {
  content: "\e5d2";
  opacity: 0.2;
}
.ph-duotone.ph-wind:after {
  content: "\e5d3";
  margin-left: -1em;
}
.ph-duotone.ph-windmill:before {
  content: "\e9f8";
  opacity: 0.2;
}
.ph-duotone.ph-windmill:after {
  content: "\e9f9";
  margin-left: -1em;
}
.ph-duotone.ph-windows-logo:before {
  content: "\e692";
  opacity: 0.2;
}
.ph-duotone.ph-windows-logo:after {
  content: "\e693";
  margin-left: -1em;
}
.ph-duotone.ph-wine:before {
  content: "\e6b2";
  opacity: 0.2;
}
.ph-duotone.ph-wine:after {
  content: "\e6b3";
  margin-left: -1em;
}
.ph-duotone.ph-wrench:before {
  content: "\e5d4";
  opacity: 0.2;
}
.ph-duotone.ph-wrench:after {
  content: "\e5d5";
  margin-left: -1em;
}
.ph-duotone.ph-x:before {
  content: "\e4f6";
  opacity: 0.2;
}
.ph-duotone.ph-x:after {
  content: "\e4f7";
  margin-left: -1em;
}
.ph-duotone.ph-x-circle:before {
  content: "\e4f8";
  opacity: 0.2;
}
.ph-duotone.ph-x-circle:after {
  content: "\e4f9";
  margin-left: -1em;
}
.ph-duotone.ph-x-logo:before {
  content: "\e4bc";
  opacity: 0.2;
}
.ph-duotone.ph-x-logo:after {
  content: "\e4bd";
  margin-left: -1em;
}
.ph-duotone.ph-x-square:before {
  content: "\e4fa";
  opacity: 0.2;
}
.ph-duotone.ph-x-square:after {
  content: "\e4fb";
  margin-left: -1em;
}
.ph-duotone.ph-yarn:before {
  content: "\ed9a";
  opacity: 0.2;
}
.ph-duotone.ph-yarn:after {
  content: "\ed9b";
  margin-left: -1em;
}
.ph-duotone.ph-yin-yang:before {
  content: "\e92a";
  opacity: 0.2;
}
.ph-duotone.ph-yin-yang:after {
  content: "\e92b";
  margin-left: -1em;
}
.ph-duotone.ph-youtube-logo:before {
  content: "\e4fc";
  opacity: 0.2;
}
.ph-duotone.ph-youtube-logo:after {
  content: "\e4fd";
  margin-left: -1em;
}
